if (!daz) { var daz = {}; }
daz.helper = {
	bodyScroll: {
	},
	stopBodyScroll: function() {
		daz.helper.bodyScroll.scrollTop = $(window).scrollTop()+0;
		daz.helper.bodyScroll.scrollLeft = $(window).scrollLeft()+0;
		daz.helper.bodyScroll.origTop = $('body').css('top')+'';
		daz.helper.bodyScroll.origLeft = $('body').css('left')+'';

		$('body').addClass('modal_body_noscroll');
//			.css('top', -daz.helper.bodyScroll.scrollTop)
//			.css('left', -daz.helper.bodyScroll.scrollLeft);
	},
	startBodyScroll: function() {
		$('body').removeClass('modal_body_noscroll')
			.css('top', daz.helper.bodyScroll.origTop)
			.css('left', daz.helper.bodyScroll.origLeft);

		$(window).scrollTop(daz.helper.bodyScroll.scrollTop);
		$(window).scrollLeft(daz.helper.bodyScroll.scrollLeft);

		daz.helper.bodyScroll = {};
	},
    toggleLoggedIn: function() {
		if (daz.api && daz.api.isLoggedIn()) {
            $('#account_gem').addClass('logged_in');
        } else {
            $('#account_gem').removeClass('logged_in');
        }
    },
	formatPennies: function(pennyAmount) {
		var priceStr = ""+parseInt(pennyAmount);
		return "$"+priceStr.slice(0,-2)+"."+priceStr.slice(-2);
	},
	setLocation: function(url) {
		window.location.href = url;
	},
	subNavSlide: function(parent) {
		if(parent !== null) {
			$('.'+parent + '-sub, .nav-back').show();
		}
		
		$('#top_nav_links').toggleClass('sub-nav-open');
    },
	showIconTitle: function($this) {
		var h = $this.parent().height();
		if(h%32 > 0) {
			h = Math.ceil(h/32)*32;
		}
		$this.parent()
		.siblings('.icon-info')
		.text($this.data('title'))
		.addClass('shown').css({"top": -h+"px"});
	},
	hideIconTitle: function($this) {
		$this.parent()
		.siblings('.icon-info')
		.text('')
		.removeClass('shown').css({"top":"0px"});
	}
};

$(window).on('beforeDazApi', function () {
    daz.api.addCall('User/info', {callbackClass: daz.helper, callbackFunc: daz.helper.toggleLoggedIn});
});

$(window).on('ab:show', function (e, split, group) {
	$(`[data-ab="${split}"]`).each((i, elem) => {
		const $el = $(elem);
		$el.find(`[data-abgroup]`).hide();
		$el.find(`[data-abgroup="${group}"]`).show();
	})
});

$(document).ready(function() {
    daz.helper.toggleLoggedIn();
});

