function DazSticky( element ) {
    this.elem = $(element);
    this.cloneElem = this.elem.clone();
    if (this.cloneElem.attr('id')) {
        this.cloneElem.attr('id',this.cloneElem.attr('id') + '_clone');
    }
    this.cloneElem.removeClass('daz-sticky');
    this.cloneElem.addClass('daz-fixed');
    if (this.elem.attr('data-daz-sticky-class')) {
        this.cloneElem.addClass(this.elem.attr('data-daz-sticky-class'));
    }
    this.cloneElem.css('display','block');
    this.cloneElem.css('visibility', 'none');

    $(document.body).append(this.cloneElem);
    this.elem.trigger('clone', this.cloneElem);

    if (this.elem.attr('data-daz-sticky-side') == 'bottom') {
        $(window).scroll($.proxy(this.checkBottom,this));
        this.checkBottom();
    } else {
        $(window).scroll($.proxy(this.checkTop,this));
        this.checkTop();
    }
}

DazSticky.prototype.checkBottom = function() {
    var windowBottom = $(window).height() + $(window).scrollTop();
    var elemBottom = this.elem.offset().top + this.elem.height();
    var opacity = parseFloat(this.cloneElem.css('opacity'));

    if (elemBottom < windowBottom) {
        if (opacity > 0.99) {
            this.cloneElem.fadeTo(100, 0);
            this.cloneElem.css('pointer-events', 'none');
        }
    } else if (opacity < 0.01){
        this.cloneElem.fadeTo(100, 1);
        this.cloneElem.css('pointer-events', '');
    }
};

DazSticky.prototype.checkTop = function() {
    var windowTop = $(window).scrollTop();
    var elemTop = this.elem.offset().top+this.elem.height()-this.cloneElem.height();
    var opacity = parseFloat(this.cloneElem.css('opacity'));

    if (windowTop < elemTop ) {
        if (opacity > 0.99) {
            if (this.elem.attr('data-hide-orig') == true) {
                this.elem.fadeTo(100, 1);
            }
            this.cloneElem.fadeTo(100, 0);
            this.cloneElem.css('pointer-events', 'none');
        }
    } else if (opacity < 0.01) {
        if (this.elem.attr('data-hide-orig') == true) {
            this.elem.fadeTo(100, 0);
        }
        this.cloneElem.fadeTo(100, 1);
        this.cloneElem.css('pointer-events','');
    }
};

$(document).ready(function() {
    daz.stickyElems = [];
    $('.daz-sticky').each(function(idx, elem) {
        daz.stickyElems.push(new DazSticky(elem));
    });
});
