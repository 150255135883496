class UserInputsHandling {
    constructor($inputsContainer = null) {
        this.$inputsContainer = $inputsContainer;
        this.inputsData = {};
        this.validateWaitMs = 1000;
        this.validateTimer;
        this.opsPending = false;
        this.init();
    }

    init() {
        if (!this.$inputsContainer) {
            console.error('Inputs container not found :(');
            return;
        }

        const $inputs = this.$inputsContainer.find('[data-validation-type]');
        
        if (!$inputs.length) {
            console.error('No inputs found in the container :(');
            return;
        }

        const that = this;
        $inputs.each(function() {
            that.initInputsData($(this));
            
            // event listeners
            if ($(this).is('select')) {
                // dropdowns handling
                $(this).on('change', function() {
                    this.opsPending = true;
                    that.handleErrorChecking($(this));
                });
            } else {               
                $(this).on('keyup keydown', function(event) {
                    const eventType = event.type;
                    
                    if (eventType === 'keyup') {
                        this.opsPending = true;
                        that.validateTimer = setTimeout(() => {
                            that.handleErrorChecking($(this));
                        }, that.validateWaitMs);
                    } else if (eventType === 'keydown') {
                        clearTimeout(that.validateTimer);
                    }
                    
                    that.handleFormatting($(this), event);
                })
            }

            $(this).on('blur', () => {
                clearTimeout(that.validateTimer);
                that.handleErrorChecking($(this));
            });
        });
    }

    initInputsData($inputEl) {
        const elId = $inputEl.attr('id');
        const $errMsgEl = this.$inputsContainer.find(`#${elId}-errors`) || null;

        this.inputsData[elId] = {
            $el: $inputEl,
            $errMsgEl,
            isValid: false,
            fieldErrors: []
        };
    }

    handleErrorChecking($inputEl) {
        const inputId = $inputEl.attr('id');
        const fieldVal = $inputEl.val();
        const errMsgs = [];

        if (!fieldVal.trim()) {
            errMsgs.push('This field is required');
        } else {
            const validationType = $inputEl.attr('data-validation-type');
    
            if (validationType === 'cc-number') {
                const isAmex = fieldVal.startsWith('34') || fieldVal.startsWith('37');
                const cardLength = isAmex ? 17 : 19;
    
                if (fieldVal.length < cardLength) {
                    errMsgs.push('Please enter a valid credit card number');
                }
            } else if (validationType === 'cc-cvv') {
                if (
                    fieldVal.length < 3
                    || fieldVal.length > 5
                ) {
                    errMsgs.push('Please enter a valid CVV');
                }
            } else if (validationType === 'cc-expiry') {
                const splitDate = fieldVal.split('/');
                const inputtedMonth = parseInt(splitDate[0]);
                const inputtedYear = parseInt(splitDate[1]);
                const currentYear = parseInt((new Date().getFullYear()).toString().substring(2));
    
                if (fieldVal.length < 5) {
                    errMsgs.push('Date should be in MM/YY format')
                } else if (inputtedMonth < 1 || inputtedMonth > 12 || inputtedYear < currentYear) {
                    errMsgs.push('Please enter a valid month/year (MM/YY)')
                }
            }
        }

        this.clearErrors(inputId);

        this.inputsData[inputId].fieldErrors = errMsgs;
        this.inputsData[inputId].isValid = errMsgs.length === 0;
        this.displayErrors(inputId);

        return this.inputsData[inputId].isValid;
    }

    clearErrors(inputId) {
        let { fieldErrors, $errMsgEl } = this.inputsData[inputId];

        fieldErrors = [];
        if ($errMsgEl) $errMsgEl.empty();
    }

    displayErrors(inputId) {
        const { $errMsgEl, fieldErrors } = this.inputsData[inputId];
        if (!$errMsgEl) return;


        if (fieldErrors.length) {
            $errMsgEl.html(`${fieldErrors.map(err => `<li class="smaller bs5-fw-bold" style="color: #dd8b8b;">${err}</li>`).join('')}`);
        }

        this.opsPending = false;
    }

    validateFields(inputIdsStrArr) {
        let validationPassed = true;

        for (let i = 0; i < inputIdsStrArr.length; i++) {
            const inputId = inputIdsStrArr[i];

            if (!this.inputsData[inputId]) {
                console.error('Required input not found:', `#${inputId}`);
                continue;
            }

            const $inputEl = this.inputsData[inputId].$el;
            const isValid = this.handleErrorChecking($inputEl);
            if (!isValid) validationPassed = false;
        }

        return validationPassed ? true : false;
    }

    handleFormatting($inputEl, event) {
        const validationType = $inputEl.attr('data-validation-type');

        switch (validationType) {
            case 'cc-number':
                this.formatCCNumber($inputEl);
                break;
            case 'cc-expiry':
                this.formatCCExpiryDate($inputEl, event);
                break;
            case 'cc-cvv':
                this.formatCCSecCode($inputEl);
                break;
            default:
                break;
        }
    }

    formatCCNumber($inputEl) {
        const fieldVal = $inputEl.val();
        const isAmex = fieldVal.startsWith('34') || fieldVal.startsWith('37');
        const onlyNumbers = fieldVal.replace(/[^\d]/g, '');

        let regex;
        let formattedVal;
        if (isAmex) {
            $inputEl.attr('maxlength', '17');
            regex = /^(\d{0,4})(\d{0,6})(\d{0,5})$/g;
            formattedVal = onlyNumbers.replace(regex, (regex, $1, $2, $3) => [$1, $2, $3].filter(group => !!group).join(' '));
        } else {
            $inputEl.attr('maxlength', '19');
            regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
            formattedVal = onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) => [$1, $2, $3, $4].filter(group => !!group).join(' '));
        }

        $inputEl.val(formattedVal);
    }

    formatCCExpiryDate($inputEl, event) {
        if (event.type === 'keyup') {
            let data  = $inputEl.val();
            if (event.which === 8 && data.length === 4) {
                $inputEl.val(data.substr(0, 2));
            }
            if ((event.which > 57 || event.which < 48)) {
                event.preventDefault();
                return false;
            }
            if (data.length === 2) {
                $inputEl.val(data + '/');
            }
            return true;
        } else if (event.type === 'keydown') {
            if (event.which === 8 || event.which === 9) {
                return true;
            }
            let chars = "0123456789";
            if (chars.indexOf(event.key) === -1) {
                event.preventDefault();
                return false;
            }
            let data = $inputEl.val();
            if (data.length === 2) {
                $inputEl.val(data + '/');
            }
            return true;
        }
    }

    formatCCSecCode($inputEl) {
        const fieldVal = $inputEl.val() || '';
        let maxLength = $inputEl.attr('maxlength') || false;

        let onlyNumbers = fieldVal.replace(/[^\d]/g, '');
        if (maxLength) onlyNumbers = onlyNumbers.slice(0, maxLength);

        $inputEl.val(onlyNumbers);
    }
}