function DazProductList(api, rawCatalogData) {
    // This is a direct list of all product id's
    this.productList = [];
    this.productCount = -1;
    // used when filtering categories
    this.originalProductList = [];
    this.selectedFilters = [];

    // toggle layouts until old is completely removed
    this.layout = 'new'

    // The ordered list of product id's in the category
    // this is manipulated by the filter/order functions
    this.ordered = [];
    // The offset to start on in the ordered list
    this.orderedStart = 0;
    // Used for determining the "new flag"
    this.now = Math.floor(new Date().getTime()/1000);

    // The index in the "ordered" list to what element is in the top left
    this.topLeftIndex = null;

    // A cache of the product positions
    this.positionCache = {};

    // An element to store the scrollTimeout timeout to make sure there
    // is some delay before processing scroll events
    this.scrollTimeout = null;

    // Which filters the user has applied
    this.filterSettings = {owned: true};
    // Bonus data to show which filters are open/closed
    // And which ones need to be loaded
    this.filterData = {};

    // The sort order settings
    this.sortOrderSettings = ['date',1];

    // If we need to scroll to an element, we have to wait for the element to load
    this.scrollToCallback = null;

    // If we are doing infinite scrolling
    this.infiniteScroll = false;

    // How many items per page
    this.pageSize = 60;

    // Which page are we currently on
    this.currPage = 0;

    // Are we the reason the hash changed?
    this.changingHash = false;

    // Templates for various actions
    this.slabholder = Templates.slabholder;
    this.slab = Templates.slab;

    // For when pagination has an input field to enter go-to-page number
    this.$paginationContainer = $('.pagination_container');
    this.$controlsContainer = this.$paginationContainer.find('.controls_container');
    this.$goToPageContainer = this.$paginationContainer.find('.go_to_page_container');
    this.$goToPageInput = this.$goToPageContainer.find('input');
    this.lastPagenum = 1;
    this.paginationTransitionSpd = 200;
    this.goToPageTimeout = null;
}

/*** INIT FUNCTIONS ***/
DazProductList.prototype.init = function(listElem, rawCatalogData) {
    this.listElem = listElem;

    if (typeof(rawCatalogData) == 'object' ) {
        // Load in the passed in data
        for (var i = 0; i < rawCatalogData.length; i++) {
            var productId = rawCatalogData[i];
            this.productList.push(productId);
        }

        this.categoryId = null;
    } else {
        this.categoryId = rawCatalogData;
    }

    var pageSizeSetting = daz.api.storageGet('catalog_pageSize');
    if (pageSizeSetting != null) {
        this.pageSize = pageSizeSetting;
        this.infiniteScroll = pageSizeSetting == -1;
    }

    $('.pagesize').val(this.pageSize);
    $('.pagesize').on('change', $.proxy(this.changePageSize, this));
    $('.pagination').on('click', $.proxy(this.handlePaginationClick, this));
    $('.pagination_container .prev').on('click', $.proxy(this.handlePaginationPrev, this));
    $('.pagination_container .next').on('click', $.proxy(this.handlePaginationNext, this));

    if (this.$controlsContainer.length) {
        $('.pagination').on('click', '.pagination_blank', $.proxy(this.handlePaginationBlankClick, this));
        this.$controlsContainer.find('.back_to_top').on('click', () => $("html, body").animate({ scrollTop: $('#top-header').offset().top - 120 }));
        
        window.addEventListener('scroll', this.handleShowHideBackToTop.bind(this))
    }
    
    if (this.$goToPageContainer.length) {
        $('.pagination_container .go_to_page_input').on('keyup change', $.proxy(this.handlePaginationGoToPageInput, this));
        this.$goToPageContainer.find('.back_to_pagination').on('click', $.proxy(this.handleBackToPagination, this));
    }
    
    this.listElem.on('click',$.proxy(this.handleClick, this));

    $(document).on('debounced-resize', $.proxy(this.handleResize,this));
    window.addEventListener("hashchange", $.proxy(this.handleHashChange, this), false);

    this.reinit();
};

DazProductList.prototype.reinit = function() {
    this.urlHash = $.url(window.location.hash);

    this.showLoading();
    daz.filter.onLoad(this, this.showCatalog);
};

DazProductList.prototype.handleHashChange = function() {
    if (this.changingHash) {
        return;
    }

    this.reinit();
};

DazProductList.prototype.reset = function() {
    this.listElem[0].innerHTML = '';
    this.orderedStart = 0;
    this.positionCache = {};
    this.topLeftIndex = null;
    this.pageNum = 0;
};

DazProductList.prototype.showLoading = function() {
    if (this.showingLoading) {
        return;
    }
    this.showingLoading = true;
    $('#catalog-loading').fadeIn(150);
};

DazProductList.prototype.hideLoading = function() {
    if (!this.showingLoading) {
        return;
    }
    this.showingLoading = false;
    $('#catalog-loading').fadeOut(150);
};

/*** Catalog Filter Functions ***/
DazProductList.prototype.sortFilter = function(filterType) {
    let sortedFilter = [];
    for (let filterOpt in daz.filter.filters[filterType]) {
        var filterArgs = {owned: this.filterSettings.owned};
        filterArgs[filterType] = [filterOpt];
        var testList = daz.filter.filterProducts(this.productList, filterArgs);
        if (testList.length > 0) {
            sortedFilter.push([filterOpt,testList.length]);
        }
    }

    if (!daz.filter.filterSorts[filterType]) {
        sortedFilter.sort(function(a,b){ if (a[1]< b[1]) {return 1;} else if (a[1]>b[1]){ return -1; } return 0; });
    } else {
        // Create a sort "lookup"
        let filterSort = {};
        for (let i = 0; i < daz.filter.filterSorts[filterType].length; i++) {
            filterSort[daz.filter.filterSorts[filterType][i]] = i;
        }
        sortedFilter.sort(function(a,b) {
            let aSort = -1;
            let bSort = -1;
            if (filterSort[a[0]]) {
                aSort = filterSort[a[0]];
            }
            if (filterSort[b[0]]) {
                bSort = filterSort[b[0]];
            }
            if (aSort < bSort) {
                return -1;
            } else if ( bSort < aSort) {
                return 1;
            } else {
                if (a[0] < b[0]) {
                    return -1;
                } else {
                    return 1;
                }
            }
        });
    }

    let view = this.layout

    if (view === 'old') {
        let elem = this.filterData[filterType].elem.find('ul');
        elem.empty();

        if (sortedFilter.length > 15) {
            var label = $('div.'+filterType+'_container a label')[0].textContent;
            var newElem = $('<input maxlength=255 class=filter_search size=32 data-filtertype="'+filterType+'" type=text placeholder="Enter '+label+' name...">');

            newElem.on('keyup', $.proxy(this.handleFilterSearch,this));

            elem.append(newElem);
        }

        for (let i in sortedFilter) {
            var idx = sortedFilter[i][0];
            var len = sortedFilter[i][1];

            if (len == 0) {
                continue;
            }
            
            var extraHtml = '';
            if (i > 15) {
                extraHtml = 'style="display: none;"';
            } else {
                extraHtml = 'class="filter_visible"';
            }

            var largeElem = $('<li id="'+this.filterGetId('large',filterType,idx)+'" data-filterval="'+idx+'"   '+extraHtml+'><input id="'+this.filterGetId('large',filterType,idx)+'_check" type=checkbox data-filtertype="'+filterType+'" value="'+idx+'">'+idx+' (<span id="'+this.filterGetId('large',filterType,idx)+'_count" class="filter_count">'+len+'</span>)</li>');

            largeElem.on('click',$.proxy(this.handleFilterClick,this));
            elem.append(largeElem);
        }

        if (this.filterSettings[filterType]) {
            for (let i = 0; i < this.filterSettings[filterType].length; i++) {
                var elemId = this.filterGetId('large', filterType, this.filterSettings[filterType][i]);
                $('#'+elemId+'_check').prop('checked',true);
                if ($('#'+elemId).css('display') == 'none') {
                    // If it is selected, don't let it be auto-hidden
                    $('#'+elemId).css('display','').addClass('filter_visible');
                }

            }
        }
    } else if (view === 'new') {
        if (filterType === 'shopCategories') {
            let listUl = this.filterData[filterType].elemNew.find('ul.outer-ul');
            let templateLiOuter = listUl.find('li.li-template-outer').clone().removeClass('li-template-outer').removeAttr('style');
            let templateLiInner = listUl.find('li.li-template-inner').clone().removeClass('li-template-inner').removeAttr('style');
            listUl.empty();
        
            const { shopCategories } = daz.filter.filters;

            function handleShopCategoryClick(evt) {
                evt.stopPropagation();

                // bold selected categories to maintain consistensy with other filter selections, check the input if span clicked, update selected category count
                const selectedCatCountSpan = document.querySelector('#selected-cat-count');
                const currentSelectedCount = parseInt(selectedCatCountSpan.innerText.slice(1, selectedCatCountSpan.innerText.length - 1), 10);

                const activeLi = evt.target.closest('li');
                const liTextSpan = activeLi.querySelector('span.item-label');
                let checked;

                if (evt.target.type === 'checkbox') {
                    checked = evt.target.checked;
                } else {
                    if (!activeLi.querySelector('input').checked) {
                        activeLi.querySelector('input').checked = true;
                    } else {
                        activeLi.querySelector('input').checked = false;
                    }
                    checked = activeLi.querySelector('input').checked;
                }
                if (checked) {
                    liTextSpan.classList.add('bs5-fw-bold');

                    if (!currentSelectedCount) {
                        selectedCatCountSpan.innerText = '(1)';
                    } else {
                        selectedCatCountSpan.innerText = `(${currentSelectedCount + 1})`;
                    }
                } else {
                    liTextSpan.classList.remove('bs5-fw-bold');

                    if (currentSelectedCount === 1) {
                        selectedCatCountSpan.innerText = '';
                    } else {
                        selectedCatCountSpan.innerText = `(${currentSelectedCount - 1})`;
                    }
                }

                // need to store original products for when user deselects all categories
                if (this.originalProductList.length === 0) {
                    this.originalProductList = [ ...this.productList ];
                }
                                
                // add or remove selected category & its products
                const catId = $(evt.target).closest('li').attr('id');
                const existingProp = daz.filter.filters.shopCategoriesWhitelist.hasOwnProperty(catId)
                if (!existingProp) {
                    daz.filter.filters.shopCategoriesWhitelist[catId] = this.recursiveProducts(catId);
                } else {
                    delete daz.filter.filters.shopCategoriesWhitelist[catId]; 
                }

                // category filters selected? filter current products
                if (daz.filter.filters.hasOwnProperty('shopCategoriesWhitelist') && Object.keys(daz.filter.filters.shopCategoriesWhitelist).length > 0) {
                    const whitelistedProds = [];
                    for (let key in daz.filter.filters.shopCategoriesWhitelist) {
                        whitelistedProds.push(...daz.filter.filters.shopCategoriesWhitelist[key])
                    }
                    this.productList = [ ...new Set(whitelistedProds) ];
                } else {
                    if (this.originalProductList.length > 0) {
                        this.productList = [ ...this.originalProductList ]
                    }
                }

                // update other filters to repaint view with applicable selected (or deselected) category choices data
                const otherFilters = ['vendor', 'genre', 'compat_figures', 'compat_software'];
                for (let i = 0; i < otherFilters.length; i++) {
                    this.sortFilter(otherFilters[i])
                }

                this.applyFilters(true);

                // after other categories updated, update selected other filter options with new numbers 
                const allSelectedUls = document.querySelectorAll('ul.selected-list');
                const allSelectedTypes = [];
                
                for (let i = 0; i < allSelectedUls.length; i++) { 
                    allSelectedTypes.push(allSelectedUls[i].getAttribute('data-list-type'))
                }

                for (let i = 0; i < allSelectedTypes.length; i++) {
                    const baseType = allSelectedTypes[i];
                    const selectedList = document.querySelectorAll(`[data-list-type=${baseType}] li`);
                    const listedList = document.querySelectorAll(`[data-list-type=${baseType + "-listed"}] li`);

                    for (let j = 0; j < selectedList.length; j++) {
                        const selectedLi = selectedList[j];
                        const dupLiIndex = Array.prototype.findIndex.call(listedList, liEl => liEl.getAttribute('id') === selectedLi.getAttribute('id'));

                        if (dupLiIndex !== -1) {
                            // if product selected and available in list, replace selected li element with dup (updated) element
                            const dupLi = listedList[dupLiIndex];
                            dupLi.querySelector('input').checked = true;
                            selectedLi.replaceWith(dupLi);
                        } else {
                            // if product selected and not available, update selected li with 0 count
                            selectedLi.querySelector('span.filter_count').textContent = '0';
                        }
                    }
                }
            }
            
            for (let catName in shopCategories) {
                // main level of categories setup
                let templateLiOuterClone = templateLiOuter.clone();
                const outerId = this.filterGetId('large', filterType, catName);

                templateLiOuterClone.attr({
                    'id': shopCategories[catName].id,
                    'data-filterval': catName
                });
                templateLiOuterClone.find('span.idx').text(catName)
                templateLiOuterClone.find('input').attr({
                    'id': outerId + '_check',
                    'value': catName
                })
                
                // subcategories level setup
                if (shopCategories[catName].hasOwnProperty('childrenInfo')) {
                    for (let i = 0; i < shopCategories[catName].childrenInfo.length; i++) {
                        let templateLiInnerClone = templateLiInner.clone();
                        const innerId = this.filterGetId('large', filterType, shopCategories[catName].childrenInfo[i].name);
    
                        templateLiInnerClone.attr({
                            'id': shopCategories[catName].childrenInfo[i].id,
                            'data-filterval': shopCategories[catName].childrenInfo[i].name
                        });
        
                        templateLiInnerClone.find('span.idx').text(shopCategories[catName].childrenInfo[i].name)
                        templateLiInnerClone.find('span.filter_count').attr('id', innerId + '_count').text(shopCategories[catName].childrenInfo[i].products)
                        templateLiInnerClone.find('input').attr({
                            'id': innerId + '_check',
                            'value': shopCategories[catName].childrenInfo[i].name
                        })
                        templateLiInnerClone.on('click', handleShopCategoryClick.bind(this));
                        templateLiInnerClone.appendTo(templateLiOuterClone);
                    }
                }

                templateLiOuterClone.on('click', handleShopCategoryClick.bind(this));
                templateLiOuterClone.appendTo(listUl)
            }
        } else {
            let searchInput = this.filterData[filterType].elemNew.find('input.filter_search');

            if (sortedFilter.length < 15) {
                searchInput.hide();
            }
            else {
                searchInput.show();
                searchInput.on('keyup', $.proxy(this.handleNewFilterSearch,this));
            }

            let listUl = this.filterData[filterType].elemNew.find('ul.products-list');
            listUl.attr('data-list-type', `${filterType}-listed`);

            let selectedUl = this.filterData[filterType].elemNew.find('ul.selected-list');
            selectedUl.attr('data-list-type', `${filterType}`);
            
            let templateLi = listUl.find('li.li-template').first().clone().removeAttr('style');

            listUl.empty();

            for (let i in sortedFilter) {
                let tempClone = templateLi.clone();
    
                let idx = sortedFilter[i][0];
                let id = this.filterGetId('large',filterType,idx);
                let len = sortedFilter[i][1];
    
                tempClone.attr({
                    'id': id,
                    'data-filterval': idx
                });
    
                tempClone.find('input').attr({
                    'id': id+'_check',
                    'data-filtertype': filterType,
                    'value': idx
                });
    
                tempClone.find('span.idx').text(idx);
                tempClone.find('span.filter_count').attr('id', id+'_count').text(len);
                tempClone.find('span.item-label')
                    .on('click', () => {
                        // add or remove selection from selected filters array
                        let action;
                        const tgtIndex = this.selectedFilters.findIndex(filterId => filterId == id)
                        if (tgtIndex === -1) {
                            this.selectedFilters.push([id]);
                            action = 'selected';
                        } else {
                            this.selectedFilters.splice(tgtIndex, 1);
                            action = 'deselected';
                        }

                        // move element from or to selected filters ul
                        const sectionListAll = document.querySelector(`#${filterType + 'List'} ul.products-list`);
                        const sectionListSelected = document.querySelector(`#${filterType + 'List'} ul.selected-list`);
                        const targetLi = document.querySelector(`#${id}`);

                        if (action === 'selected') {
                            sectionListSelected.appendChild(targetLi);
                        } else if (action === 'deselected') {
                            sectionListAll.prepend(targetLi);
                        }
                    })
                    .on('click',$.proxy(this.handleFilterClick,this));

                    tempClone.appendTo(listUl);
            }
        }
    }

    this.filterData[filterType].sorted = true;

    this.filterUpdateCounts();
};

DazProductList.prototype.filterExpand = function (filterType) {
    this.filterData[filterType].visible = true

    if (!this.filterData[filterType].sorted) {
        let self = this
        window.setTimeout(function () {
            self.sortFilter(filterType)
        }, 150)
    }
}

DazProductList.prototype.filterCollapse = function(filterType) {
    this.filterData[filterType].visible = false;
};

DazProductList.prototype.filterDropdownShow = function (filterType) {
    this.filterData[filterType].visible = true

    if (!this.filterData[filterType].sorted) {
        let elem = this.filterData[filterType].elem.find('ul')
        elem.html('<b>Loading...</b>')
        let self = this
        window.setTimeout(function () {
            self.sortFilter(filterType)
        }, 150)
    }
}

DazProductList.prototype.filterDropdownHide = function(filterType) {
    this.filterData[filterType].visible = false;
};

DazProductList.prototype.setupFilters = function() {
    if (this.hasSetupFilters) {
        return;
    }
    this.hasSetupFilters = true;

    $('#filter_container').on('daztoggle-hide', $.proxy(this.filterModalClose,this));
    $('a.modal-filter-apply').on('click', $.proxy(this.filterModalApply, this));
    $('a.modal-filter-clear').on('click', $.proxy(this.filterModalClear, this));

    $('div.sort_container').on('click',$.proxy(this.handleOrderClick, this));
    $('select[name="sort-by"]').on('change',$.proxy(this.handleSortBy, this));
    $('#large_owned input[type="checkbox"]').on('change',$.proxy(this.ownedHandleClick,this));
    $('#large_platClub input[type="checkbox"]').on('change',$.proxy(this.platClubHandleClick,this));
    $('#large_bundle input[type="checkbox"]').on('change',$.proxy(this.bundleHandleClick,this));
    $('#filter_clear_container').find('.filter-clear').on('click', $.proxy(this.filterClear, this));
    let self = this;

    // Note: showCallback and hideCallback are functions for the older style dropdown filters
    let showCallback = function(type) {
        return function() {
            self.layout = 'old'
            self.filterDropdownShow.call(self,type);
        }
    };
    let hideCallback = function(type) {
        return function() {
            self.filterDropdownHide.call(self,type);
        }
    };

    // Note: expandCallback and collapseCallback are functions for the newer style scrollable filters
    let expandCallback = function(type) {
        return function() {
            self.layout = 'new'
            self.filterExpand.call(self,type);
        }
    };
    let collapseCallback = function(type) {
        return function() {
            self.filterCollapse.call(self,type);
        }
    };

    // BEGIN: initialize shopCategories filter to filters (also whitelisted in filter.js)
    const { categoryId } = this;
    const allCategories = { ...daz.filter.rawCategories };
    
    daz.filter.filters.shopCategories = {};
    daz.filter.filters.shopCategoriesWhitelist = {};

    // temp array used to build up the shop's categories
    const shopCategories = [];
    
    // does this category page have categories to display for filtering?
    for (let key in allCategories) {
        if (allCategories[key].parent === categoryId && allCategories[key].visible === true) {
            const newCatObj = {id: +key, name: allCategories[key].name}

            if (allCategories[key].hasOwnProperty('children')) {
                newCatObj.children = [ ...allCategories[key].children ]
                newCatObj.childrenInfo = [];
            }

            shopCategories.push(newCatObj);
        }
    }

    if (shopCategories.length > 0) {
        for (let i = 0; i < shopCategories.length; i++) {
            // does the main category have subcategories?
            if (shopCategories[i].hasOwnProperty('children')) {
                for (let j = 0; j < shopCategories[i].children.length; j++) {
                    if (allCategories[shopCategories[i].children[j]] !== undefined) {
                        const childInfo = {
                            id: shopCategories[i].children[j],
                            name: allCategories[shopCategories[i].children[j]].name
                        }
    
                        if (allCategories[shopCategories[i].children[j]].hasOwnProperty('products')) {
                            let products = this.recursiveProducts(shopCategories[i].children[j])
                            childInfo.products = products? products.length : 0
                        }
        
                        shopCategories[i].childrenInfo.push(childInfo)
                    }
                }
                // sort the child categories by name alphabetically a-z
                shopCategories[i].childrenInfo.sort((a, b) => a.name.localeCompare(b.name))
            }
        }

        // sort main categories alphabetically a-z, then add to daz filters
        shopCategories.sort((a, b) => a.name.localeCompare(b.name))
        for (let i = 0; i < shopCategories.length; i++) {
            daz.filter.filters.shopCategories[shopCategories[i].name] = { ...shopCategories[i] };

            // remove name property from main category (redundant since it's the key)
            delete daz.filter.filters.shopCategories[shopCategories[i].name].name;
        }
    } else {
        // if no categories to display, remove the Category filter page element
        if (document.querySelector('#shopCategoriesContainer') != null) {
            document.querySelector('#shopCategoriesContainer').remove();
        }
    }
    // END add shopCategories filter to filters    

    for (var filterType in daz.filter.filters) {
        if (filterType == 'owned'
            || filterType == 'category'
            || filterType == 'platClub'
            || filterType == 'inCart'
            || filterType == 'new'
            || filterType == 'bundle') {
            continue;
        }

        var elem = $('#'+filterType+'_drop');
        var elemNew = $('#'+filterType+'List');

        if (!elem[0] && !elemNew[0]) {
            continue;
        }

        elem.on('daztoggle-show', showCallback(filterType));
        elem.on('daztoggle-hide', hideCallback(filterType));

        elemNew.on('daztoggle-show', expandCallback(filterType));
        elemNew.on('daztoggle-hide', collapseCallback(filterType));

        this.filterData[filterType] = {elem: elem, elemNew: elemNew, sorted: false, visible: false};
    }
};

DazProductList.prototype.handleFilterSearch = function(event) {
    var filterType = $(event.target).attr('data-filtertype');
    var searchTerm = event.target.value.toLowerCase();
    var i = 0;
    this.filterData[filterType].elem.find('li').each(function(idx, elem) {
        var $elem = $(elem);
        if (i > 15 && !$elem.find('input').prop('checked')) {
            $elem.css('display','none');
            $elem.removeClass('filter_visible');
            return;
        }

        if (searchTerm != '' && elem.textContent.toLowerCase().indexOf(searchTerm) == -1) {
            // Didn't find it
            $elem.css('display','none');
            $elem.removeClass('filter_visible');
        } else {
            i++;
            $elem.css('display','block');
            $elem.addClass('filter_visible');
        }
    });

    this.filterUpdateCounts();
};

DazProductList.prototype.handleNewFilterSearch = function(event) {
    let filterType = $(event.target).attr('data-filtertype');
    let searchTerm = event.target.value.toLowerCase();

    this.filterData[filterType].elemNew.find('li').each(function(idx, liElem) {
        let $liElem = $(liElem);

        // On empty field, restore option and skip iteration
        if (searchTerm === '') {
            $liElem.show();
            $liElem.addClass('filter_visible');
            return;
        }

        // Don't hide checked option and skip iteration
        if ($liElem.find('input').prop('checked')) {
            return;
        }

        // Search for string within option text
        if (liElem.textContent.toLowerCase().indexOf(searchTerm) != -1) {
            $liElem.show();
            $liElem.addClass('filter_visible');
        }
        else {
            $liElem.hide();
            $liElem.removeClass('filter_visible');
        }
    });
};

DazProductList.prototype.handleFilterClick = function(event) {
    event.stopPropagation();

    var elem = $(event.target).closest('li').find('input');
    if (!elem[0]) {
        return;
    }

    var value = elem.val();
    var filterType = elem.attr('data-filtertype');
    var shouldCheck = true;

    if (!this.filterSettings[filterType]) {
        this.filterSettings[filterType] = [];
    }

    var filterPos = this.filterSettings[filterType].indexOf(value);

    if (filterPos != -1) {
        shouldCheck = false;

        this.filterSettings[filterType].splice(filterPos,1);
    } else {
        this.filterSettings[filterType].push(value);
    }

    $('#'+this.filterGetId('large',filterType,value)+'_check').prop('checked',shouldCheck);

    window.setTimeout($.proxy(function() { this.applyFilters(true); },this),5);
};

DazProductList.prototype.filterClear = function(event) {
    $('.platClub_check').prop('checked',false);
    $('.bundle_check').prop('checked',false);
    $('#filter_container').find('input').prop('checked',false);
    $('.owned_check').prop('checked', true);
    this.filterSettings = {owned: true};

    daz.api.sessionStoragePut('showOwned', false);
    daz.api.sessionStoragePut('platClubOnly', false);
    daz.api.sessionStoragePut('bundleOnly', false);

    event.preventDefault();

    this.applyFilters(true);
};

DazProductList.prototype.filterModalApply = function() {
    $('#filter_container').trigger('daztoggle-hide');
}

DazProductList.prototype.filterModalClose = function(event) {
};

DazProductList.prototype.filterModalClear = function(event) {
    this.filterClear();

    // Hide the modal
    $('#filter_container').trigger('daztoggle-hide');
}

DazProductList.prototype.loadFilterFromHash = function() {
    for (var filterType in daz.filter.filters) {
        if (this.urlHash.fparam('filter_'+filterType)) {
            if (filterType == "platClub") {
                // If we are filtering Daz Plus, don't let them change it.
                $('#large_platClub').hide();
            }
            var filter = this.urlHash.fparam('filter_'+filterType).split('^');
            this.filterSettings[filterType] = [];
            for (var i = 0; i < filter.length; i++) {
                this.filterSettings[filterType].push(filter[i]);
            }
        }
    }

    var order = this.urlHash.fparam('order')
    if (order) {
        this.sortOrderSettings = order.split('^');
        let sortDir = this.sortOrderSettings[1] == '-1' ? '-1' : '1';
        let elem = $('#sort_drop').find('li[data-sortdata="' + this.sortOrderSettings[0] +'"][data-sortdir="' + sortDir + '"]');
        // Thanks foundation for not having a better way to close dropdowns.
        let parentElem = $('div.sort_container');
        parentElem.click();
        parentElem.find('.location').html(elem.text());
    }
};

DazProductList.prototype.applyFilters = function(doScroll) {
    this.reset();
    var filterCount = 0;
    var hasFilter = false;
    for (var filterName in this.filterSettings) {
        if (filterName == 'owned') {
            continue;
        }
        if (this.filterSettings[filterName].length < 1) {
            continue;
        }
        hasFilter = true;
        for (var i in this.filterSettings[filterName]) {
            filterCount++;
        }
    }
    this.ordered = [...new Set(daz.filter.filterProducts(this.productList, this.filterSettings))];

    var direction = 'ASC';
    if (this.sortOrderSettings[1] == 1) {
        // Reverse sort
        direction = 'DESC';
    }

    this.ordered = daz.filter.sortProducts(this.ordered, this.sortOrderSettings[0], direction);

    this.filterUpdateCounts();
    var html = this.ordered.length+' of '+(this.productCount !== -1 ? this.productCount : this.productList.length)+' items';
    $('#catalog_amount').html(html);
    var filterTotal = 0;
    for (var filterType in daz.filter.filters) {
        if (filterType == 'owned') {
            continue;
        }
        var filterCount = 0;
        if (typeof this.filterSettings[filterType] == 'object') {
            filterCount = this.filterSettings[filterType].length;
        }
        if (filterCount > 0) {
            $('#large_'+filterType+'_count').html(filterCount).css('visibility','visible');
        } else {
            $('#large_'+filterType+'_count').html(0).css('visibility','hidden');
        }
        filterTotal += filterCount;
    }
    if (filterTotal > 0) {
        $('#small_filter_count').html(filterTotal).css('visibility','visible');
        $('#filter_clear_container').css('display','');
    } else {
        $('#small_filter_count').html(0).css('visibility','hidden');
        $('#filter_clear_container').css('display','none');
    }

    if (doScroll) {
        if (this.infiniteScroll) {
            this.handleScroll();
        } else {
            this.buildPagination();
            this.switchPage(0);
        }
    }

    this.applyHiddenFiltersCss()
};

DazProductList.prototype.filterGetId = function(filterSize, filterType, filter) {
    var filterId = 'filter_'+filterSize+'_'+filterType+'_'+filter;
    return filterId.replace(/[^A-z0-9_]+/g, '_');
};

DazProductList.prototype.filterUpdateCounts = function() {
    for (var filterType in daz.filter.filters) {
        if (filterType == 'owned' ||
            filterType == 'category' ||
            filterType == 'platClub' ||
            filterType == 'bundle' ||
            filterType == 'platinum_club' ||
            !this.filterData[filterType] ||
            !this.filterData[filterType].elem) {
            continue;
        }

        var filterElems = this.filterData[filterType].elem.find('li.filter_visible');

        for (var i = 0; i < filterElems.length; i++) {
            var filterOpt = $(filterElems[i]).attr('data-filterval');

            var filterArgs = JSON.parse(JSON.stringify(this.filterSettings));
            filterArgs[filterType] = [filterOpt];
            var foundList = daz.filter.filterProducts(this.productList, filterArgs);
            var elem = document.getElementById(this.filterGetId('large',filterType,filterOpt)+'_count');
            if (!foundList.length) {
                filterElems[i].style.display = 'none';
            }
            elem.innerHTML = foundList.length;

        }
    }

    // Trigger a reflow
    $('#filter_container .dropdown_content').css('margin-left','0.05px');
    window.setTimeout(function() { $('#filter_container .dropdown_content').css('margin-left',''); }, 1);

};

DazProductList.prototype.ownedHandleClick = function(event) {
    this.filterSettings.owned = !this.filterSettings.owned;

    if (!this.filterSettings.owned) {
        check = false;
    } else {
        check = true;
    }
    daz.api.sessionStoragePut('showOwned', check);

    $('.owned_check').prop('checked', check);

    if (event) {
        event.stopPropagation();
    }
    this.applyFilters(true);
};

DazProductList.prototype.platClubHandleClick = function(event) {
    var check = false;

    if (!this.filterSettings.platClub) {
        this.filterSettings.platClub = ['yes'];
        check = true;
    } else {
        delete this.filterSettings.platClub;
        check = false;
    }
    daz.api.sessionStoragePut('platClubOnly', check);

    $('.platClub_check').prop('checked', check);

    if (event) {
        event.stopPropagation();
    }

    this.applyFilters(true);
};

DazProductList.prototype.bundleHandleClick = function(event) {
    var check = false;

    if (!this.filterSettings.bundle) {
        this.filterSettings.bundle = ['yes'];
        check = true;
    } else {
        delete this.filterSettings.bundle;
        check = false;
    }
    daz.api.sessionStoragePut('bundleOnly', check);

    $('.bundle_check').prop('checked', check);

    if (event) {
        event.stopPropagation();
    }

    this.applyFilters(true);
};

DazProductList.prototype.applyHiddenFiltersCss = function() {
    // this makes sure that the style stays consistent when the filters are collapsed
    let defaultStyle = $('.filter-collapse-button').attr('data-defaultStyle')
    if (defaultStyle != undefined) {
        defaultStyle = JSON.parse(defaultStyle)

        if(Object.keys(defaultStyle).length && !$('#collapsible-product-filters-container').hasClass('product-filters-container')) {
            $('#top-filters-container').css(defaultStyle.topFilterContainer)
            $('#slabs-container').children('.item').css(defaultStyle.item)
            $('.wishlist-grid').children('.item').css(defaultStyle.item)
        }
    }
}

/*** Pagination functions ***/
DazProductList.prototype.switchPage = function(pageNum) {
    this.reset();
    this.currPage = pageNum;
    this.orderedStart = pageNum * this.pageSize;

    var numToLoad = this.pageSize;
    if (pageNum == 0) {
        numToLoad -= this.reuseExistingSlabs(this.pageSize);
    } else {
        this.listElem.html('');
    }

    this.addPlaceholders(numToLoad);
    this.buildPagination();
    this.handleScroll();
    this.applyHiddenFiltersCss()
};

DazProductList.prototype.handlePaginationMaybeScroll = function(event) {
    event.preventDefault();

    if ($(event.target).closest('.toolbar.bottom')[0]) {
        $('html, body').animate({scrollTop: $('.filters').offset().top-40}, 200);
    } else if ($(event.target).closest('.pagination_container')[0]) {
        // do not use scroll behavior smooth -- it has the effect of requesting all new slabs data while scrolling to top
        $(document).scrollTop($('#top-header').offset().top - 120)
    }
};

DazProductList.prototype.handlePaginationPrev = function(event) {
    this.handlePaginationMaybeScroll(event);

    if (this.currPage > 0) {
        this.switchPage(this.currPage - 1);
        this.handleClick(true);
    }
};

DazProductList.prototype.handlePaginationNext = function(event) {
    this.handlePaginationMaybeScroll(event);

    var maxPages = Math.ceil(this.ordered.length / this.pageSize) - 1;
    if (this.currPage < maxPages) {
        this.switchPage(this.currPage + 1);
        this.handleClick(true);
    }
};

DazProductList.prototype.handlePaginationBlankClick = function(event) {
    event.stopPropagation();
    if (!this.$goToPageContainer) return;

    const currPagenum = this.$controlsContainer.find('.pagination .pagination_page.button.active').text();
    this.lastPagenum = Number(this.$controlsContainer.find('.pagination .pagination_page.button').last().text());

    this.$goToPageInput.attr({'placeholder': currPagenum, 'max': this.lastPagenum, 'data-active-page': currPagenum}).val(currPagenum).select();
    this.$goToPageContainer.find('.last_pagenum').text(this.lastPagenum);

    this.$controlsContainer.fadeOut(this.paginationTransitionSpd, () => {
        this.$goToPageContainer.fadeIn(this.paginationTransitionSpd, () => {
            this.$goToPageInput.select();
        });
    })
}

DazProductList.prototype.handlePaginationGoToPageInput = function(event) {
    clearTimeout(this.goToPageTimeout);
    this.goToPageTimeout = null;

    const inputVal = this.$goToPageInput.val().trim();
    const validatedVal = this.validatePagenumInput(inputVal);

    if (!validatedVal) {
        this.$goToPageInput.val('');
        return;
    }

    if (this.$goToPageInput.attr('data-active-page') === inputVal) {
        return;
    }

    this.goToPageTimeout = setTimeout(() => {
        $(document).scrollTop($('#top-header').offset().top - 120);
        this.$goToPageInput.attr({'placeholder': inputVal, 'data-active-page': inputVal}).select();
        const pagenum = parseInt(inputVal - 1, 10);

        this.switchPage(pagenum);
        this.handleClick(true);
    }, 1000);
};

DazProductList.prototype.handleBackToPagination = function(e) {
    this.$goToPageContainer.fadeOut(this.paginationTransitionSpd, () => {
        this.$controlsContainer.fadeIn(this.paginationTransitionSpd, () => {
            this.handleShowHideBackToTop.call(this);
        });
    })
}

DazProductList.prototype.handleShowHideBackToTop = function() {
    const scrollBuffer = 250;

    if (window.scrollY > $('#collapsible-product-display-container').offset().top + scrollBuffer) {
        if (!this.$controlsContainer.find('.back_to_top').is(':visible')) {
            this.$controlsContainer.find('.back_to_top').fadeIn(this.paginationTransitionSpd);
        }
    } else {
        if (this.$controlsContainer.find('.back_to_top').is(':visible')) {
            this.$controlsContainer.find('.back_to_top').fadeOut(this.paginationTransitionSpd);
        }
    }
}

DazProductList.prototype.validatePagenumInput = function(inputVal) {
    inputVal = Number(inputVal);

    if (
        !inputVal
        || isNaN(inputVal)
        || !Number.isInteger(inputVal)
        || inputVal < 1
        || inputVal > this.lastPagenum
    ) {
        return false;
    }

    return true;
}

DazProductList.prototype.handlePaginationClick = function(event) {
    this.handlePaginationMaybeScroll(event);

    var selectedPage = $(event.target).attr('data-pagenum');

    if (selectedPage != null) {
        this.switchPage(parseInt(selectedPage,10));
        this.handleClick(true);
    }
};

DazProductList.prototype.changePageSize = function(event) {
    // Need to toggle all the page size things to the same, but don't want to retrigger this event
    $('.pagesize').off('change');

    var newPageSize = $(event.currentTarget).val();

    $('.pagesize').val(newPageSize);

    newPageSize = parseInt(newPageSize, 10);

    daz.api.storagePut('catalog_pageSize', newPageSize);

    $('.pagesize').blur();
    $('.pagesize').on('change', $.proxy(this.changePageSize, this));
    this.buildPagination();

    this.pageSize = newPageSize;
    if (this.pageSize == -1) {
        this.infiniteScroll = true;
        this.reset();
        this.buildPagination();
        this.handleScroll();
    } else {
        this.infiniteScroll = false;
        this.switchPage(0);
    }

    this.applyHiddenFiltersCss()
};

DazProductList.prototype.buildPagination = function() {
    if (this.infiniteScroll) {
        $('.pagination_container').hide();
        return;
    }

    var maxPages = Math.ceil(this.ordered.length / this.pageSize);
    var displayItems = [];
    if (maxPages < 7) {
        for (var i = 0; i < maxPages; i++) {
            displayItems.push(i);
        }
    } else if (this.currPage < 4) {
        for (var i = 0; i < 5; i++ ) {
            displayItems.push(i);
        }
        displayItems.push(-1);
        displayItems.push(maxPages-1);
    } else if (this.currPage > (maxPages - 4)) {
        displayItems.push(0);
        displayItems.push(-1);
        for (var i = maxPages - 4; i < maxPages; i++) {
            displayItems.push(i);
        }
    } else {
        displayItems.push(0);
        displayItems.push(-1);
        displayItems.push(this.currPage - 1);
        displayItems.push(this.currPage);
        displayItems.push(this.currPage + 1);
        displayItems.push(-1);
        displayItems.push(maxPages-1);
    }

    var html = '';
    for ( var i = 0; i < displayItems.length; i++) {
        if (displayItems[i] == -1) {
            html += '<span class="pagination_blank">...</span>';
        } else {
            var extraClass = '';
            if (displayItems[i] == this.currPage) {
                extraClass = ' active';
            }
            html += '<a class="pagination_page button '+extraClass+'" data-pagenum='+displayItems[i]+'>'+(displayItems[i]+1)+'</a>';
        }
    }
    $('.pagination').html(html);
    if (this.currPage == 0) {
        $('.pagination_container .prev').addClass('disabled');
    } else {
        $('.pagination_container .prev').removeClass('disabled');
    }

    if (this.currPage == maxPages) {
        $('.pagination_container .next').addClass('disabled');
    } else {
        $('.pagination_container .next').removeClass('disabled');
    }

    $('.pagination_container').show();

};

/*** Catalog display functions ***/
DazProductList.prototype.showCatalog = function(ignore, data, isCache) {
    if (this.categoryId != null) {
        // Load the full category with matureFilter=false (special case for the catalog; filtering applies afterward)
        // This allows a user to start with the mature filter enabled, then uncheck the filter to see mature content
        const pm = {};
        const initialList = this.recursiveProducts(this.categoryId, pm)
        this.productList = daz.filter.filterProducts(initialList, {category: [this.categoryId]});
    }

    var direction = 'ASC';
    if (this.sortOrderSettings[1] == 1) {
        // Reverse sort
        direction = 'DESC';
    }
    if (this.productCount === -1) {
        this.productCount = this.productList.length;
    }
    this.ordered = daz.filter.sortProducts(this.productList, this.sortOrderSettings[0], direction);

    this.hideLoading();
    window.setTimeout($.proxy(this.setupFilters,this),1);

    var onlyPlat = daz.api.sessionStorageGet('platClubOnly');
    if (onlyPlat && !this.filterSettings.platClub) {
        this.platClubHandleClick();
    }
    var showOwned = daz.api.sessionStorageGet('showOwned');
    if (showOwned && this.filterSettings.owned) {
        // this.ownedHandleClick();
    }
    var onlyBundle = daz.api.sessionStorageGet('bundleOnly');
    if (onlyBundle && !this.filterSettings.bundle) {
        this.bundleHandleClick();
    }

    if (this.urlHash.fparam('filtered') == '1'
        || this.urlHash.fparam('order') != undefined) {
        this.loadFilterFromHash();
    }
    this.applyFilters(false);

    var topLeft = null;
    if (this.infiniteScroll ) {
        if (this.urlHash.param('p') && !this.urlHash.fparam('index')) {
            // We are coming from someplace that is paginated
            // but don't have a different scroll location in the url
            topLeft = (parseInt(this.urlHash.param('p'),10)-1)*this.pageSize;
        }
        if (this.urlHash.fparam('index')) {
            topLeft = parseInt(this.urlHash.fparam('index'),10);
        }
        if (isNaN(topLeft)) {
            topLeft = null;
        }

        if (topLeft != null && this.ordered[topLeft]) {
            // Time to backfill
            this.addPlaceholders(topLeft - this.orderedStart + 10);
            window.setTimeout($.proxy(function() {this.scrollToIndex(topLeft); },this),100);
        } else {
            this.reuseExistingSlabs(-1);
            this.hideLoading();
        }
    } else {
        var pageNum = 0;
        if (this.urlHash.param('p')) {
            pageNum = parseInt(this.urlHash.param('p'),10)-1;
        }
        if (this.urlHash.fparam('p')) {
            pageNum = parseInt(this.urlHash.fparam('p'),10)-1;
        }

        this.switchPage(pageNum);
        this.buildPagination();
        this.hideLoading();
    }

    $(window).on('scroll', $.proxy(this.handleScroll, this));

    window.setTimeout($.proxy(this.handleScroll,this), 1);
};

DazProductList.prototype.scrollToIndex = function(index) {
    var elem = $('#product-'+this.ordered[index]);
    if (elem[0] && elem.offset().top) {
        var scrollFunc = function() {
            if (window.scrollY < 1) {
                window.setTimeout(scrollFunc,200);
            }
            document.body.scrollTop = elem.offset().top;
        };
        window.setTimeout(scrollFunc, 200);
        window.setTimeout(scrollFunc, 400);
        this.hideLoading();
    } else {
        window.setTimeout($.proxy(function() {this.scrollToIndex(index); },this),50);
    }
};

DazProductList.prototype.reuseExistingSlabs = function(maxSlabs) {
    this.listElem.html('');
    return 0;
    // For someday in the future.
    /*
    var rawElem = this.listElem[0];
    var existingList = [];

    for (var i = 0; i < rawElem.children.length; i++) {
        var productId = $(rawElem.children[i]).attr('data-productid');
        existingList.push(productId);
        if (daz.filter.productIsInCart(productId)) {
            $('#product-'+productId).find('button.btn-cart').css('display','none');
            $('#product-'+productId).find('button.btn-in-cart').css('display','block');
        }
    }


    var elem = null;

    for (var i = 0; i < this.ordered.length; i++) {
        if (document.getElementById('product-'+this.ordered[i])) {
            // Element already exists
            continue;
        }

        if (this.orderedStart === null) {
            // We got to the end of the products on the page, fetch the new
            // slabs starting here
            this.orderedStart = i;
        }
    }
    */
};

DazProductList.prototype.fillVisibleSlabs = function(forceReload) {
    var windowTop = $(window).scrollTop();
    var visibleTop = windowTop + 50;
    var oldTopLeft = this.topLeftIndex;
    var height = $(window).height();
    var windowBot = windowTop + height + 250;
    var pos = null;

    if (this.scrollTimeout) {
        window.clearTimeout(this.scrollTimeout);
        this.scrollTimeout = null;
    }

    if (this.topLeftIndex == null) {
        this.oldTopLeft = -1;
        this.topLeftIndex = 0;
        forceReload = true;
    }

    // Locate the top-left element
    // Start at the last indexed element and go back until we are off the screen
    // (or at the first element)
    while (this.topLeftIndex > 0) {
        pos = this.getPosition(this.ordered[this.topLeftIndex]);
        if ( pos.top && pos.top < visibleTop ) {
            break;
        }

        this.topLeftIndex--;
    }

    // After we have gone off the screen, go forward until we are back on the screen
    // that element is ours.
    while (this.topLeftIndex < this.ordered.length) {
        pos = this.getPosition(this.ordered[this.topLeftIndex]);
        if ( pos.top && pos.top > visibleTop ) {
            break;
        }

        this.topLeftIndex++;
    }

    if (DazProductSlab.requestCount > 50 ) {
        // Give the server a bit of a breather
        if (!this.scrollTimeout) {
            this.scrollTimeout = window.setTimeout($.proxy(function() {this.fillVisibleSlabs(true);}, this), 250);
        }
        return;
    }

    if (true) {
        // Fill in some surrounding elements
        var i = this.topLeftIndex;

        var loadSlabs = {};

        while (i < this.ordered.length) {
            pos = this.getPosition(this.ordered[i]);
            if ( pos.top && pos.top > windowBot) {
                // This is no longer on the screen
                break;
            }

            if (pos.placeholder) {
                loadSlabs[this.ordered[i]] = true;
                this.positionCache[this.ordered[i]].placeholder = false;
            }
            i++;
        }

        var elemCount = 0;
        // Populate the next elements off the edge of the screen
        while (elemCount < 11 && i < this.ordered.length) {
            pos = this.getPosition(this.ordered[i]);

            if (pos.placeholder) {
                loadSlabs[this.ordered[i]] = true;
                this.positionCache[this.ordered[i]].placeholder = false;
            }

            elemCount++;
            i++;
        }

        // Populate the previous elements off the top
        elemCount = 0;
        i = this.topLeftIndex - 1;
        while (elemCount < 11 && i > -1) {
            pos = this.getPosition(this.ordered[i]);

            if (pos.placeholder) {
                loadSlabs[this.ordered[i]] = true;
                this.positionCache[this.ordered[i]].placeholder = false;
            }

            elemCount++;
            i--;
        }

        this.loadProducts(loadSlabs);
    }
};

DazProductList.prototype.loadProducts = function(productIds) {
    for (i in productIds) {
        var elemSelector = '#product-'+i;
        var slab = new DazProductSlab({id: i}, this.slab, elemSelector);
        slab.load();
    }
};

DazProductList.prototype.handleScroll = function() {
    if (!this.scrollTimeout) {
        this.scrollTimeout = window.setTimeout($.proxy(this.fillVisibleSlabs, this), 50);
    }

    if (!this.infiniteScroll) {
        return;
    }

    if (this.orderedStart < this.ordered.length) {
        var offset = this.listElem.offset();
        var windowTop = $(window).scrollTop();
        var height = $(window).height();
        var windowBot = windowTop + height;


        if ((offset.top+this.listElem.height()-windowBot) < (height * 2)) {
            this.addPlaceholders(20);
        }
    }
};

DazProductList.prototype.handleClick = function(event) {
    var shouldProcess = false;
    if (event === true) {
        shouldProcess = true;
    } else {
        var target = $(event.target);
        if (target.closest('a') || target.closest('button')) {
            shouldProcess = true;
        }
    }
    if (!shouldProcess) {
        return;
    }

    var extraHash = [];
    var hasFilter = false;
    if (this.infiniteScroll) {
        extraHash.push('index='+this.topLeftIndex);
    } else {
        if (this.currPage > 0) {
            extraHash.push('p='+(this.currPage+1));
        }
    }

    for (var filterType in this.filterSettings) {
        if (filterType == 'owned' || filterType == 'platClub') {
            continue;
        }
        if (this.filterSettings[filterType].length < 1) {
            continue;
        }
        hasFilter = true;
        if (this.filterSettings[filterType]) {
            extraHash.push('filter_'+filterType+'='+this.filterSettings[filterType].join('^'));
        }
    }

    if (this.sortOrderSettings[0] != 'date' || this.sortOrderSettings[1] != 1) {
        hasFilter = true;
        extraHash.push('order='+this.sortOrderSettings[0]+'^'+this.sortOrderSettings[1]);
    }

    if (hasFilter) {
        extraHash.push('filtered=1');
    }

    this.changingHash = true;
    var newHash = extraHash.join('&');
    if (history && history.pushState) {
        history.pushState(null, null, '#'+newHash);
    } else {
        window.location.hash = newHash;
    }
    this.changingHash = false;
};

DazProductList.prototype.handleOrderClick = function(event) {
    if (event.target.tagName != 'LI') {
        return;
    }

    var elem = $(event.target);
    // Thanks foundation for not having a better way to close dropdowns.
    var parentElem = $('div.sort_container');
    parentElem.click();
    parentElem.find('.location').html(elem.text());

    this.sortOrderSettings = [elem.attr('data-sortdata') , elem.attr('data-sortdir')];
    this.handleClick(true);
    this.applyFilters(true);
};

DazProductList.prototype.handleSortBy = function(event) {
    let selectedOption = $(event.target).find('option:selected');
    this.sortOrderSettings = [selectedOption.attr('data-sortdata') , selectedOption.attr('data-sortdir')];
    this.applyFilters(true);
};

DazProductList.prototype.handleResize = function() {
    this.positionCache = {};

    window.setTimeout($.proxy(this.handleScroll, this), 1);
};

DazProductList.prototype.getPosition = function(productId) {
    if (!this.positionCache[productId]) {
        var elem = $('#product-'+productId);
        var pos = elem.offset();
        if (!elem || !pos) {
            return {'top':null, 'left':null, 'bottom': null, 'right': null};
        }
        pos.bottom = pos.top + elem.height();
        pos.right = pos.left + elem.width();
        pos.placeholder = true;
        this.positionCache[productId] = pos;
    }

    return this.positionCache[productId];
};

DazProductList.prototype.addPlaceholders = function(addCount) {
    var addedElems = 0;

    for (var i = this.orderedStart; i < this.ordered.length && (addedElems < addCount); i++) {
        if (document.getElementById('product-'+this.ordered[i])) {
            // Element already exists
            // This really shouldn't ever happen
            continue;
        }

        var newElem = $(this.slabholder.render({id: this.ordered[i]}))[0];
        this.listElem[0].appendChild(newElem);
        addedElems++;
    }

    this.orderedStart = i;

    if ( this.orderedStart < this.ordered.length ) {
        window.setTimeout($.proxy(this.handleScroll,this),1);
    }
};

DazProductList.prototype.recursiveProducts = function(catId, productMap = {}) {
    let cat = daz.filter.rawCategories[catId]

    if (!cat) return []
    let catProducts = cat?.products || []
    for (let i = 0; i < catProducts.length; i++) {
        if (!productMap[catProducts[i]]) {
            if (daz.filter.sorts.price[catProducts[i]] === undefined) {
                continue;
            }
            productMap[catProducts[i]] = true;
        }
    }

    if (!cat.children || !cat.children.length) {
        return Object.keys(productMap)
    }

    for (var i = 0; i < cat.children.length; i++) {
        this.recursiveProducts(cat.children[i], productMap)
    }
    return Object.keys(productMap)
}
