function DazToggler(elem) {
    var self = this;

    this.options = {
        noElem: false,
        modal: false,
        modalFull: false,
        toggleElem: null,
        visible: false,
        toggleStripHide: false,
        toggleStripShow: false,
        stickyOpen: false,
        preventClose: false
    };

    if (arguments.length > 1) {
        for( var idx in arguments[1]) {
            this.options[idx] = arguments[1][idx];
        }
    }

    this.elem = null;
    this.groupElems = [];

    if (!this.options.noElem) {
        this.elem = $(elem);
        this.elem.on('click',$.proxy(this.toggle, this));
        this.group = this.elem.attr('data-togglegroup');
        
        if (this.elem.attr('data-togglemodal') == 1) {
            this.options.modal = true;
            if (this.elem.attr('data-togglemodalfull') == 1) {
                this.options.modalFull = true;
            }
        }

        if (this.elem.attr('data-togglestrip') == 'hide') {
            this.options.toggleStripHide = true;
        }
        if (this.elem.attr('data-togglestrip') == 'show') {
            this.options.toggleStripShow = true;
        }
        if (!this.options.toggleElem) {
            var toggleElemName = this.elem.attr('data-toggleelem'); 
            // Try siblings first, for duplicated elements
            this.toggleElem = this.elem.siblings(toggleElemName);
            if (!this.toggleElem[0]) {
                this.toggleElem = $(toggleElemName);
            }
        }
        if (this.elem.attr('data-stickyopen') == 1) {
            this.options.stickyOpen = true;
        }

        if (this.group) {
            this.options.hasGroup = true;
            $('.daz-toggler[data-togglegroup='+this.group+']').each(function(idx, elem) {
                var subElem = $($(elem).attr('data-toggleelem'));
                if (subElem[0] != self.toggleElem[0]) {
                    self.groupElems[self.groupElems.length] = subElem;
                }
            });
        }
    }

    if (this.options.toggleElem) {
        this.toggleElem = this.options.toggleElem;
    }

    this.toggleElem.on('daztoggle-show', $.proxy(this.show, this));
    this.toggleElem.on('daztoggle-hide', $.proxy(this.hide, this));
    this.toggleElem.find('.daztoggle-close').click(function(event){ self.toggleElem.trigger('daztoggle-hide'); event.preventDefault(); });

    this.visible = null;
    
    DazToggler.toggles[DazToggler.toggles.length] = this;
}

DazToggler.toggles = [];

DazToggler.closeAll = function() {
    for (var i = 0; i < DazToggler.toggles.length; i++) {
        DazToggler.toggles[i].toggleElem.trigger('daztoggle-hide');
    }
};

DazToggler.prototype.hide = function(event) {
    if (this.elem) {
        this.elem.removeClass('daztoggle_active');
        $('#top_nav_links').removeClass('sub-nav-open');
        $('.nav-slide li').hide();
    }
    if (this.options.modal) {
        $(document).off('.daztoggler');
        daz.helper.startBodyScroll();
        if (!this.options.modalFull) {
            $('#modal_background').hide(100);
            $('#modal_background').off('click');
        }
    }
    this.toggleElem.hide(100, $.proxy(this.doneHide, this));
    this.visible = false;

    if (event) {
        event.stopPropagation();
    }
};

DazToggler.prototype.show = function(event) {
    for (var i = 0; i < this.groupElems.length; i++ ) {
        this.groupElems[i].trigger('daztoggle-hide');
    }

    if (this.elem) {
        this.elem.addClass('daztoggle_active');
    }

    if (this.options.modal) {
        daz.helper.stopBodyScroll();

        var self = this;
        if (!this.options.preventClose) {
            $(document).on('keydown.daztoggler', function(event){
                if (event.which == 27) {
                    self.toggleElem.trigger('daztoggle-hide');
                }
            });
        }
        var addThis = 'modal_open';
        if (this.options.modalFull) {
            addThis = addThis + ' modal_open_full';
        } else {
            // Not fullscreen, add a background blocker
            var blockElem = $('#modal_background');
            if (!blockElem[0]) {
                blockElem = $('<div id="modal_background"></div>');
                $('body').append(blockElem);
            }
            blockElem.show(100);
            if (!this.options.preventClose) {
                blockElem.on('click', function () {
                    self.toggleElem.trigger('daztoggle-hide');
                });
            }
        }
        this.toggleElem.addClass(addThis);
    }

    this.toggleElem.show(100, $.proxy(this.doneShow, this));    
    this.visible = true;
    
    if (!this.options.stickyOpen) {
        $(document).on('click', $.proxy(this.checkClick, this));
    }

    if (event) {
        event.stopPropagation();
    }
};

DazToggler.prototype.checkClick = function(event) {
    if (this.elem != null && this.elem.attr('data-ignorecheckclick') != 1 && !$(this.toggleElem).find(event.target).length && !$(this.toggleElem).is(event.target) ) {
        $(document).off('click', $.proxy(this.checkClick, this));
        this.toggleElem.trigger('daztoggle-hide');
    }
}

DazToggler.prototype.toggle = function(event) {
    if (event) {
        event.stopPropagation();
        event.preventDefault();
    }
    if (this.visible == false || this.toggleElem.css('display') == 'none') {
        this.toggleElem.trigger('daztoggle-show');
    } else {
        this.toggleElem.trigger('daztoggle-hide');
    }
};

DazToggler.prototype.doneHide = function() {
    // If requested, don't set the element style to hidden
    // this way we can toggle elements on some sizes that should be displayed
    // without toggles on other sizes.
    if (this.options.toggleStripHide) {
        this.toggleElem.css('display', '');
    }

    if (this.options.modal) {
        this.toggleElem.removeClass('modal_open modal_open_full');
    }
};

DazToggler.prototype.doneShow = function() {
    // If requested, don't set the element style to hidden
    // this way we can toggle elements on some sizes that should be displayed
    // without toggles on other sizes.
    if (this.options.toggleStripShow) {
        this.toggleElem.css('display', '');
    }
    if (this.options.modal && this.options.modalFull) {
        var self = this;
        $('#modal_background').on('click', function() {self.toggleElem.trigger('daztoggle-hide'); });
    }
};

$(document).ready( function() {
    $('.daz-toggler').each( function(idx, elem) {
        new DazToggler(elem);
    });
    
    window.setTimeout(function() {
        $(document).on('dazresponsive-change', DazToggler.closeAll);
    }, 10);

    $(document).on('clone', function(event, element) {
        $(element).find('.daz-toggler').each( function (idx, elem) {
            new DazToggler(elem);
        });
    });
});
