function DazAccordian(elems) {
    this.elems = $(elems);

    this.elems.click($.proxy(this.toggle, this));
}

DazToggler.prototype.loadToggleElem = function() {
    if (!this.toggleElem) {
        this.toggleElem = $(this.toggleElemSelector);
    }
};

DazToggler.prototype.toggle = function() {
    var self = this;
    this.loadToggleElem();
    if (this.visible = false || this.toggleElem.css('display') == 'none') {
        this.toggleElem.trigger('daztoggle-show');
        this.toggleElem.show(100, function() { self.toggleDone.call(this,'show'); });
        this.visible = true;
    } else {
        this.toggleElem.trigger('daztoggle-hide');
        this.toggleElem.hide(100, function() { self.toggleDone.call(this,'hide'); });
        this.visible = false;
    }
};

DazToggler.prototype.toggleDone = function( showHide ) {
    // If requested, don't set the element style to hidden
    // this way we can toggle elements on some sizes that should be displayed
    // without toggles on other sizes.
    var strip = this.toggleElem.attr('data-togglestrip');
    if (strip == 'none') {
        this.toggleElem.css('display', '');
    } else if (strip == 'show') {
        this.toggleElem.css('display', '');
    }
};

$(document).ready( function() {
    var accordianElems = {};
    $('.daz-accordian').each( function(idx, elem) {
        var $elem = $(elem);
        var group = $elem.attr('data-accordiangroup')
        if (!accordianElems[group]) {
            accordianElems[group] = [];
        }
        accordianElems[group][accordianElems[group].length] = elem;
    });

    for (var groupName in accordianElems) {
        new DazAccordian(accordianElems[groupName]);
    }
});
