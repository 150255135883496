$(document).ajaxComplete(function(evt, xhr){
    if (!xhr || !xhr.responseJSON || !xhr.responseJSON.redir_url) {
        return;
    }
    let wait = 0;
    if (xhr.responseJSON.redir_wait > 0) {
        wait = xhr.responseJSON.redir_wait
    }
    sleep(wait).then(() => {
        daz.helper.setLocation(xhr.responseJSON.redir_url)
    })
});

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function DazApi() {
    this.callParts = {};
    this.data = {};
    this.hasLoaded = false;

    this.collectingPrices = false;
    this.pricesToLoad = {};
}

DazApi.prototype.time = function(timeDesc) {
    if (!this.lastTime) {
        this.lastTime = Date.now();
    }

    const currTime = Date.now();

    //console.log(timeDesc, (currTime - this.lastTime)/1000);
    this.lastTime = currTime;
};

DazApi.prototype.addCall = function(callName, callData) {
    if (!this.callParts[callName]) {
        this.callParts[callName] = {calls:[], data: false, hasRun: false};
    }

    callData.hasRun = false;

    const callIndex = this.callParts[callName].calls.push(callData) - 1;

    if (!this.callParts[callName].data) {
        this.loadCacheData(callName);
    }

    if (this.callParts[callName].data != null) {
        this.runCallback(this.callParts[callName].calls[callIndex], this.callParts[callName], true);
    }
};

DazApi.prototype.runCallback = function(myCall, data, isCache) {
    if (daz.config == null) {
        // Can't call the callback functions without system config
        return;
    }

    if (myCall.hasRun && myCall.onlyOnce) {
        return;
    }

    if (isCache && myCall.onlyLive) {
        return;
    }

    myCall.hasRun = true;

    if (data.data == null) {
        data.data = {};
    }

    window.setTimeout(function(){
        myCall.callbackFunc.call(myCall.callbackClass, myCall, data.data, isCache);
    },1);
};

DazApi.prototype.loadCacheData = function (callName) {
    let storedValue = this.storageGet(callName)

    if (!storedValue) {
        this.callParts[callName]._hash = null
        this.callParts[callName].data = null
    } else {
        this.callParts[callName]._hash = storedValue._hash
        this.callParts[callName].data = storedValue
    }
}

DazApi.prototype.storageGet = function (key) {
    let rawValue = null
    if (key in this.data) {
        return this.data[key]
    }

    try {
        rawValue = localStorage.getItem(key)
        if (!rawValue) {
            this.data[key] = null
        } else {
            this.data[key] = JSON.parse(rawValue)
        }
    } catch (e) {
        this.data[key] = null
    }
    return this.data[key]
}

DazApi.prototype.storagePut = function(key, value) {
    this.data[key] = value;
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {

    }
};

DazApi.prototype.sessionStorageGet = function(key) {
    let value = null
    try {
        let rawValue = sessionStorage.getItem(key);

        if (rawValue != null) {
            value = JSON.parse(rawValue);
        }
    } catch (e) {
        value = null;
    }
    return value;
};

DazApi.prototype.sessionStoragePut = function(key, value) {
    try {
        sessionStorage.setItem(key, JSON.stringify(value));
    } catch (e) {

    }
};

DazApi.prototype.nukeTheFridge = function() {
    try {
        let saveProperties = ['ABTastyData', 'user_showMature', 'FilterData_Categories', 'FilterData_Filters', 'FilterData_Sorts', 'Theme/color', 'catalog_pageSize', 'daz_utm', 'Marketing', "Listrak/save", "Popups", "TERMLY_API_CACHE"];
        let tmp;
        let savedStorage = {};
        for (let idx in saveProperties) {
            tmp = localStorage.getItem(saveProperties[idx]);
            if (tmp != null) {
                savedStorage[saveProperties[idx]] = tmp;
            }
        }

        localStorage.clear();
        sessionStorage.clear();

        for (let idx in savedStorage) {
            localStorage.setItem(idx, savedStorage[idx]);
        }
    } catch (e) {

    }
};

DazApi.prototype.exec = function(){
    let that = this
	//wait until every deferred is ready before really execing
	// we can use this for prerequisites such as logging in
	$.when.apply($,daz.apiExecWaitList).then(function(){
		that.realexec();
	});
};

DazApi.prototype.realexec = function() {
    let apiData = {}

    $(window).trigger('beforeDazApi');

    if (!this.callParts['User/info']) {
        // Always fetch user info, so we know if our cache is for the wrong user
        this.callParts['User/info'] = {calls:[], hasRun:false, params: {q: document.location.search}};
        this.loadCacheData('User/info');
    }

    // Not sure where the final place for SplitTests will be so putting it here
    // TODO: Move this to ab-test.js
    let splitTestCache = null;
    let getMeSomeSplitTests = true;
    if (!this.callParts['SplitTests']) {
        this.callParts['SplitTests'] = {calls:[]}
        this.loadCacheData('SplitTests')
        if (this.callParts['SplitTests'].data
            && this.callParts['SplitTests'].data.splits
        ) {
            splitTestCache = this.callParts['SplitTests'].data.splits
        }
    }
    else if (this.callParts['User/info'].data && (this.callParts['User/info'].data.customerId === 0)) {
        if (this.callParts['SplitTests'].data && this.callParts['SplitTests'].data.splits) {
            getMeSomeSplitTests = false;
        }
    }

    for (let callName in this.callParts ) {
        if (this.callParts[callName].apiData) {
            apiData[callName] = this.callParts[callName].apiData;
        } else {
            apiData[callName] = {};
        }
        if (this.callParts[callName]._hash != null) {
            apiData[callName]._hash = this.callParts[callName]._hash;
        }
        if (this.callParts[callName].calls[0] && this.callParts[callName].calls[0].params) {
            apiData[callName].params = this.callParts[callName].calls[0].params;
        }
    }

    if (apiData['SplitTests'].params == null) {
        apiData['SplitTests'].params = {};
    }
    if (splitTestCache) {
        apiData['SplitTests'].params.q = JSON.stringify(splitTestCache);
    }

    if (!getMeSomeSplitTests) {
        delete(apiData['SplitTests']);
    }

	if (apiData['User/info'].params == null) {
		apiData['User/info'].params = {};
	}
	apiData['User/info'].params.q = document.location.search;

    $.ajax("/dazApi/pagedata", {
        context: this,
        data: JSON.stringify(apiData),
        success: this.apiSuccess,
        error: this.apiError,
        type: 'POST'
    });
};

DazApi.prototype.apiError = function() {
    //console.log('API Error');

    for (let callName in this.callParts) {
        for (let i =0; i < this.callParts[callName].calls.length; i++) {
            if (this.callParts[callName].calls[i].hasRun == false) {
                this.runCallback(this.callParts[callName].calls[i], this.callParts[callName], false);
            }
        }
    }

    window.setTimeout(function() { daz.api.displayErrors(['Unable to load recent personalized data. Cart contents, product ownership and account information may be incorrect.']); }, 25);

};

DazApi.prototype.apiSuccess = function(data) {
    if (typeof data != 'object') {
        this.apiError();
        return;
    }

    if (data['User/info'] && data['User/info'] !== true) {
        let oldUserInfo = this.storageGet('User/info');
        if (!oldUserInfo) {
            oldUserInfo = {customerId: null};
        }
        if (oldUserInfo.customerId != data['User/info'].customerId) {
            // Customer data isn't the same, nuke the old data.
            // Keep the this.data array alone because empty hashes still could match
            this.nukeTheFridge();
        }
    }

    for (let callName in this.callParts) {
        if (!this.callParts[callName]) {
            // Extra returned data
            continue;
        }

        let mustRun = false;

        if (data[callName] && data[callName] !== true) {
            // The data has changed from the cache
            this.storagePut(callName, data[callName]);
            this.callParts[callName].data = data[callName];

            if (typeof(data[callName]['_hash']) != 'undefined') {
                this.callParts[callName]._hash = data[callName]['_hash'];
            }

            // It has not run with the new data
            mustRun = true;
        }

        for (let i =0; i < this.callParts[callName].calls.length; i++) {
            if (mustRun || this.callParts[callName].calls[i].hasRun == false) {
                this.runCallback(this.callParts[callName].calls[i], this.callParts[callName], false);
            }
        }
    }
};

DazApi.prototype.runFromData = function(callName, data) {
    this.callParts[callName].data = data;

    for (let i in this.callParts[callName].calls) {
        this.runCallback(this.callParts[callName].calls[i], this.callParts[callName], false);
    }
};

DazApi.prototype.isLoggedIn = function() {
    const userinfo = this.storageGet('User/info');
    return userinfo && userinfo.customerId && userinfo.customerId > 0;
};


DazApi.prototype.getPrice = function(productId, context, func) {
    if (daz.deals && daz.deals.data && daz.deals.data[productId] === null) {
        dazPricing[productId] = daz.deals.getPrice(productId);
    } else if (daz.deals && daz.deals.data && daz.deals.data[productId] > 0) {
        dazPricing[productId] = daz.deals.data[productId];
    }

    // Let's see if we can do this the easy way
    if (typeof(dazPricing) != 'undefined') {
        if (typeof(dazPricing[productId]) == 'undefined') {
            func.call(context, null);
            return;
        }
        Promise.resolve(dazPricing[productId]).then(price => {
            func.call(context,price);
        })
        return;
    }

    // Nope, the hard way it is.
    if (!this.collectingPrices) {
        this.collectingPrices = true;
        window.setTimeout($.proxy(this.loadProductPrice, this), 25);
    }

    if (!this.pricesToLoad[productId]) {
        this.pricesToLoad[productId] = [];
    }
    this.pricesToLoad[productId].push({'func': func, 'context': context});
};


DazApi.prototype.loadProductPrice = function() {
    if (typeof(dazPricing) == 'undefined') {
        window.setTimeout($.proxy(this.loadProductPrice, this), 25);
        return;
    }

    let priceList = this.pricesToLoad;
    for (let productId in priceList) {
        for (let i = 0; i < priceList[productId].length; i++) {
            priceList[productId][i].func.call(priceList[productId][i].context, dazPricing[productId]);
        }
    }
    this.pricesToLoad = {};
};

DazApi.prototype.displayErrors = function(errors, displayMS) {
    $('#messages').html(Templates.messages.render({
        multi_error: true,
        errors: errors
    }));
    // set display to block since before scrolling into view because it may be 'none'
    $('#messages').css("display", "block")
    $("html, body").animate({ scrollTop: 0 });

    if (displayMS) {
        if (displayMS === true) displayMS = 3000; // default unless user provided

        setTimeout(this.hideErrors, displayMS);
    }
};

DazApi.prototype.hideErrors = function() {
    if (
        $('#messages').is(':visible')
        && $('#messages').children().length > 0
    ) {
        $('#messages').fadeOut(250);
    }
}
