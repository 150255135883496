function DazWishlist(elem) {
    this.containerElem = $(elem);
    this.productId = this.containerElem.attr('data-wishlist-product');

    this.containerElem.find('.wishlist-add').on('click', $.proxy(this.add, this));
    this.containerElem.find('.wishlist-remove').on('click', $.proxy(this.remove, this));
}

DazWishlist.loadData = function (callData, data, isCache) {
    let wishlistIndex = {};

    for (let i = 0; i < data?.wishlistItems?.length; i++) {
        wishlistIndex[data.wishlistItems[i]] = true;
    }

    for (let i = 0; i < daz.wishlist.length; i++) {
        if (wishlistIndex[daz.wishlist[i].productId]) {
            daz.wishlist[i].setActive();
        }
    }
};

DazWishlist.prototype.setActive = function () {
    this.containerElem.find('.wishlist-add').hide();
    this.containerElem.find('.wishlist-remove').show();
};

DazWishlist.prototype.setInactive = function () {
    this.containerElem.find('.wishlist-add').show();
    this.containerElem.find('.wishlist-remove').hide();
};

DazWishlist.prototype.add = function (event) {
    if (!daz.api.isLoggedIn()) {
        daz.api.displayErrors(["You must login to add items to your wishlist."]);
        return;
    }
    this.setActive();

    $.ajax(daz.config.flex_url + 'dazstatic/Wishlist/add/product/' + this.productId, {
        context: this,
        success: this.postAdd,
    });
};

DazWishlist.prototype.postAdd = function (ajaxData) {
    if (ajaxData.success != true) {
        this.setInactive();
        daz.api.displayErrors([ajaxData.message]);
    } else {
        // GTM stuff
        if (daz?.help && DazProductPage?.productId) {
            const gtmPayload = {
                event: 'add_to_wishlist',
                ecommerce: {
                    items: [
                        {
                            item_id: `${DazProductPage.productId}`
                        }
                    ]
                }
            }

            daz.help.gtmTrigger(gtmPayload)
        }
    }
};

DazWishlist.prototype.remove = function (event) {
    this.setInactive();

    $.ajax(daz.config.flex_url + 'dazstatic/Wishlist/remove/product/' + this.productId, {
        context: this,
        success: this.postRemove,
    });
};

DazWishlist.prototype.postRemove = function (ajaxData) {
    if (ajaxData.success != true) {
        this.setActive();
        daz.api.displayErrors([ajaxData.message]);
    }
};

$(window).on('beforeDazApi', function () {
    daz.wishlist = [];

    $('.wishlist-buttons').each(function () {
        daz.wishlist.push(new DazWishlist(this));
    });

    if (daz.wishlist.length > 0) {
        daz.api.addCall('User/info', {callbackClass: DazWishlist, callbackFunc: DazWishlist.loadData, onlyLive: true});
    }
});
