function SubmitForm(url, data) {
    $.ajax({
        type: "POST",
        url: url,
        data: data
    }).done(function(data){
        HandleAdminJSONResponse(data);
    }).fail(function(data) {
        let text = "There was an error communicating with the server.";
        if(data.responseJSON && data.responseJSON.error) {
            text = data.responseJSON.error;
        }
        swal("Error", text, "error");
    });
}

function HandleAdminJSONResponse(data) {
    if (data === null) {
        // we received an empty response, but not errors / 500 series responses, so we good
        location.reload(true);
        return;
    }
    if (typeof data.error !== "undefined") {
        swal("Error", data.error, "error");
        return;
    }
    if (typeof data.redir_url !== "undefined") {
        swal("Success", "Redirecting...", "success")
        sleep(data.redir_wait).then(() => {
            if (data.redir_url.charAt(0) == "#") {
                window.location.hash = data.redir_url
                location.reload(true);
            } else {
                window.location = data.redir_url
            }
        })
        return;
    } else {
        location.reload(true)
    }
}

function SubmitHandler(formID) {
    $("#"+formID).submit(function(e) {
        e.preventDefault();
        var form = $(this);
        SubmitForm(form.attr("action"), form.serialize());
    });
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}