function DazBreadcrumb() {
    let parts = document.referrer.replace(/^https?:\/\//, '').split('/');
    parts.shift();
    this.referrerPath = parts[parts.length-1];

    daz.filter.onLoad(this, this.setBreadcrumb);
}

function categoryNameToURL(cat){
    if (cat.url) {
        return;
    }
    if (!cat.parent) {
        cat.url = "shop";
        return;
    }
    cat.url = cat.name.replace(/[^a-zA-Z\d ]/g, '').replace(/ /g, '-').toLowerCase();
}

function findVendorCatID(categories){
    for (let id in categories) {
        if (categories[id].name === "Vendor") {
            return id
        }
    }

    return 0;
}

function isVendor(refURL, vendors) {
    for (let id in vendors) {
        let url = id.replace(/[^a-zA-Z\d ]/g, '').replace(/ /g, '-').toLowerCase();
        if (refURL == url) {
            return true;
        }
    }

    return false;
}

DazBreadcrumb.prototype.setBreadcrumb = function() {
    let refURL = document.referrer.split("/").pop() || "";
    let dazBreadcrumbId = $('#crumbs').data('dazBreadcrumbId')
    if (!dazBreadcrumbId) {
        return
    }
    this.currentKey = dazBreadcrumbId.toString();
    let catId = null;
    let origCatId = null;

    let vendorCatID = findVendorCatID(daz.filter.rawCategories)
    let isRefURLVendor = isVendor(refURL, daz.filter.filters.vendor)

    if (this.currentKey.indexOf("product") === 0) {
        if (catId == null) {
            let baseId = null;
            let prodId = parseInt(this.currentKey.substring(7));
            for (let id in daz.filter.rawCategories) {
                if (!daz.filter.rawCategories[id].visible) {
                    continue;
                }
                categoryNameToURL(daz.filter.rawCategories[id])
                if (!daz.filter.rawCategories[id].parent) {
                    baseId = parseInt(id);
                }
                if (daz.filter.rawCategories[id].products && daz.filter.rawCategories[id].products.indexOf(prodId) != -1 && catId == null) {
                    if (isRefURLVendor && daz.filter.rawCategories[id].parent != vendorCatID) {
                        continue
                    } else if (!isRefURLVendor && daz.filter.rawCategories[id].parent == vendorCatID){
                        continue
                    }
                    catId = parseInt(id);
                }
                if (catId != null && baseId != null) {
                    break;
                }
            }
            catId = catId != null ? catId : baseId;
        }
    } else if (this.currentKey.indexOf("category") === 0) {
        catId = parseInt(this.currentKey.substring(8));
        origCatId = catId
    }
    // TODO check sales functionality with crumbs

    let breadHtml = '';
    let baseCatId;
    do {
        if (!daz.filter.rawCategories.hasOwnProperty(catId)) break;
        daz.filter.rawCategories[catId].className = window.location.pathname.substring(1) === daz.filter.rawCategories[catId].url ?
        "current" : "notcurrent";
        daz.filter.rawCategories[catId].current = daz.filter.rawCategories[catId].className === "current" ? true : false;
        if (!origCatId || daz.filter.rawCategories[catId].className !== "current") {
            daz.filter.rawCategories[catId].shortName = daz.filter.rawCategories[catId].name.split(" and")[0];
        } else {
            daz.filter.rawCategories[catId].shortName = daz.filter.rawCategories[catId].name;
        }
        categoryNameToURL(daz.filter.rawCategories[catId])
        breadHtml = Templates.breadcrumb.render(daz.filter.rawCategories[catId]) + breadHtml;
        catId = daz.filter.rawCategories[catId].parent;
        if (catId) baseCatId = catId;
    } while (catId != null && catId != 0);

    if (origCatId && daz.filter.rawCategories.hasOwnProperty(origCatId) && daz.filter.rawCategories[origCatId].hasOwnProperty("children") && daz.filter.rawCategories[origCatId].children !== null) {
        daz.filter.rawCategories[origCatId].children.forEach(function(e){
            if (!daz.filter.rawCategories[e] || !daz.filter.rawCategories[e].visible) {
                return;
            }
            daz.filter.rawCategories[e].className = "crumb_sub_nav"
            daz.filter.rawCategories[e].shortName = daz.filter.rawCategories[e].name;
            categoryNameToURL(daz.filter.rawCategories[e])
            breadHtml += Templates.breadcrumb.render(daz.filter.rawCategories[e])
        })
    }

    if (breadHtml.trim() != "") {
        $('#crumbs #crumb_drop').html(breadHtml + $('#crumbs #crumb_drop').html());
        $('#crumbs_clone #crumb_drop').html($('#crumb_drop').html());

        if ($('#crumbs #crumb_drop .placeholder').length > 0) {
            $('#crumbs #crumb_drop .placeholder').remove();
        }
        
        if(location.pathname.split('/')[1] == 'shop') {
            $('#crumbs #crumb_drop').addClass('loaded');
        }

        return;
    }

    // Are we on a vendor page?
    let vn = window.location.pathname.substring(1);
    for (let id in daz.filter.filters.vendor) {
        let url = id.replace(/[^a-zA-Z\d ]/g, '').replace(/ /g, '-').toLowerCase();
        if (vn === url) {
            breadHtml += Templates.breadcrumb.render({
                className: "notcurrent",
                shortName: "3D Models",
                url: "shop"
            })
            breadHtml += Templates.breadcrumb.render({
                className: "notcurrent",
                shortName: "Vendor",
                url: "vendor"
            })
            breadHtml += Templates.breadcrumb.render({
                className: "current",
                shortName: id,
                url: url
            })
            break;
        }
    }

    if (breadHtml.trim() != "") {
        $('#crumbs #crumb_drop').html(breadHtml + $('#crumbs #crumb_drop').html());
        $('#crumbs_clone #crumb_drop').html($('#crumb_drop').html());
        return;
    }

    if ($('#crumbs ul li').length < 1) {
        // Nothing to add, remove the element
        // we just have the base, lets leave it there
        $('#crumbs').remove();
    }
};
