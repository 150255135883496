;var DazNotifier = function() {
	"use strict";

	this.run = function(data) {
		// keep hardcoded until we're ready to launch ab test
		// const splitVariant = daz.api.InSplit('Notifications', 'banner') ? 'a' : 'b';
		const splitVariant = 'a'
		
		if (splitVariant === 'a') {
			this.loadNotifsA(data);
		} else {
			this.loadNotifsB(data);
		}
	}

	this.loadNotifsA = function(data) {
		// orange banner

		class NotifsA {
			constructor(data) {
				this.index = 0;
				this.notes = [];

				this.init(data);
			}

			init(data) {
				if (!data.notifications.length) {
					return;
				}

				const that = this;

				$('body').on('click', '.site_message .close', function(evt) {
					evt.preventDefault( );
					evt.stopPropagation( );
		
					var $this = $(this),
						id = $this.closest('.site_message').attr('id').slice(5);
		
					that.read(id);
				});
		
				$('body').on('click', '.site_message a.container', function(evt) {
					// don't open the modal if the close button is clicked
					if ($(evt.srcElement).is('.close')) {
						return false;
					}
		
					evt.preventDefault( );
					$($(this).attr('href')).fadeIn( );
				});

				this.notes = data.notifications;
				this.display( );
			}

			next() {
				++this.index;
				this.display( );
			}

			display() {
				if ( ! this.notes || ! this.notes.length || ! this.notes[this.index]) {
					$('.site_message').slideUp(500, function( ) {
						$('.site_message').remove( );
					});
	
					$('.modal_container').fadeOut(500, function( ) {
						$('.modal_container').remove( );
					});
	
					return;
				}
	
				const $siteHeader = $('body > .wrapper'),
					note = this.notes[this.index];
	
				// attach the main message template
				$('.site_message').remove( );
				$siteHeader.prepend(Templates.n_notification.render({
					'notification_class': $.trim(note.notification_class),
					'notifier_id': parseInt(note.notifier_id, 10),
					'title': $.trim(note.title)
				}));

				$('.site_message').css({
					'z-index': '0',
					'display': 'none'
				}).fadeIn()
	
				// attach the modal template
				$('.modal_container').remove( );
				$siteHeader.append(Templates.n_notify_modal.render({
					'notification_content': $.trim(note.notification_content)
				}));
	
				$('.modal_container').on('click', function(evt) {
					// don't close the modal if the actual message is interacted with
					if ($(evt.srcElement).is('.message_details')) {
						return false;
					}
	
					var $this = $(this),
						$link = $('a[href="#'+ $this.closest('.modal_container').attr('id')+'"]'),
						id = $link.closest('.site_message').attr('id').slice(5);
	
					$('.modal_container').fadeOut(500);
				});
			}

			read(id) {
				var that = this;
	
				jQuery.ajax({
					type: 'POST',
					url: daz.config.flex_url +'notifier/index/read',
					data: {
						notifierId: id
					},
					success: function (data) {
						if (data.success) {
							that.next( );
						}
					}
				});
			}
		}

		new NotifsA(data);
	}

	this.loadNotifsB = function(data) {
		// dropdown

		class NotifsB {
			constructor(data) {
				this.firstLoad = true;
				this.pageScrolled = false;
				this.defaultViewCount = 6; // change this number to control when notifs are truncated
				this.userNotifs = [];

				this.init(data);
			}

			init(data) {
				let userNotifsRaw = [];

				if (data.notifications && data.notifications.length > 0) {
					userNotifsRaw = [ ...data.notifications ]
				}

				this.handleInitialSetup(userNotifsRaw);
			}

			handleInitialSetup(userNotifsRaw) {
				// close dropdown on scroll for convenience
				$(document).on('scroll', evt => {
					if (
						$('#user-notifs-dd:visible')[0]
						&& !this.pageScrolled
					) {
						this.pageScrolled = true;
						$('[data-toggleelem="#user-notifs-dd"]:visible').click();

						setTimeout(() => {
							this.pageScrolled = false;
						}, 750); // necessary to prevent multiple firings of this event
					}
				})

				// No notifications? end things here...
				if (!userNotifsRaw.length) {
					this.paintNotifs();
					return;
				}
				
				// ...otherwise...
				this.setupNotifs(userNotifsRaw);
			}

			setupNotifs(userNotifsRaw) {
				// handle user notifications
				// sort notifications by their start time
				userNotifsRaw = userNotifsRaw.sort((a,b) => new Date(b.start_time) - new Date(a.start_time));

				// create notification elements
				for (let i = 0; i < userNotifsRaw.length; i++) {
					const notif = userNotifsRaw[i];

					const $newP = $(`<p class="site-msg bs5-my-2 bs5-py-1">${notif.title}...</p>`);
					this.userNotifs.push({$titleEl: $newP, notifObj: notif});
				}

				this.paintNotifs()
			}

			paintNotifs() {
				const that = this;
				const $msgsContainer = $('#user-notifs-dd .notifs-msgs');
				$msgsContainer.empty();

				if (!this.userNotifs.length) {
					$('.site-notifs .site-notifs-cnt').hide();
					$('#user-notifs-dd .notifs-msg-container .no-notifs-msg').show();
					$('.site-notifs').addClass('loaded');
					this.firstLoad = false;

					return;
				} else {
					$('#user-notifs-dd .notifs-msg-container .no-notifs-msg').hide();
					$('.site-notifs .site-notifs-cnt').show();

					if (this.firstLoad) {
						this.firstLoad = false;
						
						$('[data-toggleelem="#user-notifs-dd"]:visible').click();
					}
				}

				for (let i = 0; i < this.defaultViewCount; i++) {
					if (this.userNotifs[i]) {
						const notif = setUpNotifClick(this.userNotifs[i]);
						notif.appendTo($msgsContainer);
					} else {
						break;
					}
				}

				// if we have more notifications than we show by default...
				if (this.userNotifs.length > this.defaultViewCount) {
					const notifsRemaining = this.userNotifs.length - this.defaultViewCount;
					const $viewMoreLink = $(`<p class="notifs-view-more bs5-text-center bs5-fw-bolder">View ${notifsRemaining} more...</p>`);

					$viewMoreLink.on('click', (evt) => {
						evt.stopPropagation();

						$viewMoreLink.hide();

						for (let i = this.defaultViewCount; i < this.userNotifs.length; i++) {
							const notif = setUpNotifClick(this.userNotifs[i]);
							notif.appendTo($msgsContainer);
						}
					})
					
					$viewMoreLink.appendTo($msgsContainer);
				} else {
					if ($('.notifs-view-more')) $('.notifs-view-more').remove();
				}

				// handle clicking on notification
				function setUpNotifClick(notifInfo) {
					const { $titleEl, notifObj } = notifInfo;
					const { notifier_id: id } = notifObj;

					$titleEl.on('click', evt => {
						evt.stopPropagation();
						evt.preventDefault();
						
						daz.helper.stopBodyScroll();
						that.markMsgRead(id);

						function handleCloseMsg(evt) {
							evt.stopPropagation();

							// prevent dismissal when clicking within the message
							if(
								$(evt.target).closest('.message_details')[0]
								&& !$(evt.target).closest('.close')[0]
							) return;
			
							that.dismissModal();
						}
			
						const $notifModal = $(`<div class="notif_modal">
							<div class="message_details notif_content bs5-m-0 bs5-p-1">
								${notifObj.notification_content}
							</div>
						</div>`);
						$notifModal.on('click', handleCloseMsg);
			
						const notifX = $(`<i class="fd fd-ex close" title="Dismiss"></i>`)
						notifX.on('click', handleCloseMsg);
			
						$notifModal.find('.notif_content').prepend(notifX);
			
						$notifModal.appendTo($('body'));
						$('.notif_modal')
							.css('display', 'flex')
							.hide()
							.fadeIn();
					})

					return $titleEl;
				}

				// notifications count icon bubble and add hidden notifications element
				const notifCnt = this.userNotifs.length;

				$('.site-notifs .site-notifs-cnt').show().empty();

				if (notifCnt > 9) {
					$('.site-notifs .site-notifs-cnt').text('9+');
					$('.site-notifs .site-notifs-cnt').addClass('notif-count-text-sm');
				} else {
					$('.site-notifs .site-notifs-cnt').text(notifCnt.toString());
					$('.site-notifs .site-notifs-cnt').removeClass('notif-count-text-sm');
				}

				$('.site-notifs').addClass('loaded');
			}

			markMsgRead(id) {
				const tgtMessageIdx = this.userNotifs.findIndex(({ notifObj }) => notifObj.notifier_id == id);

				if (tgtMessageIdx !== -1) {
					jQuery.ajax({
						type: 'POST',
						url: daz.config.flex_url +'notifier/index/read',
						data: {
							notifierId: `${id}`
						},
						success: data => {
							if (data.success) {
								this.userNotifs.splice(tgtMessageIdx, 1);
								this.paintNotifs();
							}
						}
					});
				};
			}

			dismissModal() {
				daz.helper.startBodyScroll();

				$('.notif_modal').fadeOut(400, function() {
					$('.notif_modal').remove();
				});
			}
		}
		
		new NotifsB(data)
	}
};

(function($) {
	"use strict";
	
	if (daz) {
		daz.notifier = new DazNotifier();
		// let apiResCount = 0;
		let notifsData = null;

		$(window).on('beforeDazApi', function( ) {
			// daz.api.addCall('SplitTests', {callbackClass: daz.notifier, callbackFunc: () => handleApiResponse(), onlyLive: true});
			daz.api.addCall('Notifier/notifications', {callbackClass: daz.notifier, callbackFunc: (callData, data, isCache) => handleApiResponse('notifs', data), onlyLive: true});
		});

		function handleApiResponse(type, data) {
			// apiResCount++;

			if (type === 'notifs') notifsData = data; 
			// if (apiResCount < 2) return;

			daz.notifier.run(notifsData);
		}
	}
}(jQuery));