const dazCheckoutUrl = '/checkout/cart';

class DazCheckout {
    constructor() {
        this.cartData = {
            items: [],
            summary: {
                subtotal: 0,
                discount: 0,
                tax: 0,
                total: 0,
                displaySubTotal: "",
                displayDiscount: "",
                displayTax: "",
                displayTotal: "",
                currencyBalanceTotal: 0,
                currencyBalanceUsed: 0,
                displayCurrencyBalanceTotal: "",
                displayCurrencyBalanceUsed: "",
                tokenAmtStart: 0,
                tokenAmtApplied: 0,
                couponCode: '',
                requirePayment: false
            }
        };
        this.loadingModalActive = false;
        this.hasProcessed = false;
        this.useBalance = true;
        this.dataProvider = [];
        this.checkoutTypes = [];
        this.lastHash = "0x0";
        this.selectedPaymentType = "";
        this.checkoutTypes = [];
        this.checkoutData = {};
        this.checkoutValidators = {};
    }

    init() {
        $(document).trigger('paymentV2:init', [this]);
    }

    triggerUpdates(resp) {
        let hasSubs = false;
        let detailObj = [];
        if (resp.Addresses !== undefined) {
            hasSubs = true;
            this.loadAddressList(resp.Addresses);
        }
        if (resp.HasAddress === true && resp.Address !== undefined) {
            hasSubs = true;
            this.addressLoad(resp.Address.address);
        }

        if (resp.Checkout !== undefined) {
            hasSubs = true;
            detailObj = resp.Checkout;
        }

        if (!hasSubs && resp.Hash !== "") {
            // we don't have any sub structures and have a hash, we are dealing with just the details obj
            detailObj = resp;
        }

        if (Object.keys(detailObj).length > 0) {
            this.updateTotals(detailObj);
            this.updateCheckoutTypes(detailObj);
        }
    }

    // ADDRESS HANDLING
    loadAddressList(addressList) {
        this.addressList = addressList;
        if (this.addressList.length > 0) {
            this.hasSavedAddress = true;
        } else {
            this.hasSavedAddress = false;
        }
        $(document).trigger("checkout:addresslist", addressList)
    }

    hasAddressSelected() {
        if (this.selectedAddress !== undefined && this.selectedAddress.id > 0) {
            return true;
        }
        return false;
    }

    addressLoad(address) {
        if (address.id > 0) {
            if (this.selectedAddress === undefined || this.selectedAddress.id !== address.id) {
                this.showLoader();
                this.selectedAddress = address
                let formData = this.getCartFormData();

                let that = this;

                $.ajax({
                    url: dazCheckoutUrl + "/init",
                    method: "post",
                    data: formData,
                }).done(function(resp) {
                    that.triggerUpdates(resp);
                }).fail(function(err){
                    that.handleAjaxError(err);
                }).always(function (){
                    that.hideLoader();
                });
            }

            $(document).trigger("checkout:addressload", address)
        }
    }

    addressAdd(formData) {
        if (this.loadingModalActive) return;
        this.showLoader();

        let that = this;
        $.ajax({
            url : dazCheckoutUrl + "/addresses/confirm",
            method: 'post',
            data: formData
        }).done(function(resp) {
            that.triggerUpdates(resp);
        }).fail(function(err) {
            that.handleAjaxError(err);
        }).always(function() {
            that.hideLoader();
        });
    }

    updateTotals(details) {
        this.cartData.summary.subtotal = details.SubTotal;
        this.cartData.summary.discount = details.DiscountedTotal;
        this.cartData.summary.tax = details.Tax;
        this.cartData.summary.total = details.GrandTotalWithTax
        this.cartData.summary.displaySubTotal = details.DisplaySubTotal;
        this.cartData.summary.displayDiscount = details.DisplayDiscountedTotal;
        this.cartData.summary.displayTax = details.DisplayTax;
        this.cartData.summary.displayTotal = details.DisplayGrandTotalWithTax;
        this.cartData.summary.currencyBalanceTotal = details.CurrencyBalance;
        this.cartData.summary.currencyBalanceUsed = details.UsedBalance;
        this.cartData.summary.displayCurrencyBalanceTotal = details.DisplayCurrencyBalance;
        this.cartData.summary.displayCurrencyBalanceUsed = details.DisplayUsedBalance;
        if (this.cartData.summary.currencyBalanceUsed > 0) {
            this.useBalance = true;
        } else {
            this.useBalance = false;
        }
        this.lastHash = details.Hash;
        $(document).trigger('checkout:updateTotals', this.cartData.summary);
    }

    updateCheckoutTypes(details) {
        this.checkoutTypes = details.CheckoutTypes;
        let paymentType = "";
        if (this.checkoutTypes.length === 1) {
            paymentType = this.checkoutTypes[0].Type;
        } else {
            if (this.cartData.summary.total === 0) {
                if (this.cartData.summary.currencyBalanceUsed === 0 && !this.cartData.summary.requirePayment) {
                    // total is 0 and no store credit being used.. its a free order
                    paymentType = "free";
                } else if (this.cartData.summary.currencyBalanceUsed > 0 && this.cartData.summary.requirePayment) {
                    // using store credit
                    paymentType = "store_credit";
                }
            }
            if (paymentType === "") {
                for (let i in this.checkoutTypes) {
                    if (this.checkoutTypes[i].Type === "credit_card") {
                        paymentType = "credit_card";
                    }
                }
            }
        }
        $(document).trigger('checkout:updatePaymentMethods', [details, paymentType]);
        if (paymentType !== "") {
            this.changePaymentType(paymentType);
        }
    }

    changePaymentType(newType) {
        if (this.selectedPaymentType !== newType) {
            let oldType = this.selectedPaymentType;
            this.selectedPaymentType = newType;
            $(document).trigger('paymentV2:changePaymentType', [newType, oldType]);
            if (newType === "free") {
                $(document).trigger("checkout:freeOrder");
            }
        }
    }

    update () {
        this.showLoader('Loading...');
        let that = this;
        let cartData = this.getCartFormData();
        this.addressId = cartData.billing_address_id;
        return $.ajax(
            {
                type: 'POST',
                url: dazCheckoutUrl + '/init',
                data: cartData,
            }
        ).done(function (response) {
            that.triggerUpdates(response);
        }).fail(function (errorThrown) {
            that.handleAjaxError(errorThrown, that);
        }).always(function () {
            that.hideLoader();
        });
    }

    getCartFormData () {
        let data = {
            'payment[method]': this.selectedPaymentType,
        };
        if (this.selectedAddress !== undefined) {
            data['billing_address_id'] = this.selectedAddress.id;
        }
        if (this.useBalance) {
            data['payment[use_customer_balance]'] = '1';
        }
        data.hash = this.lastHash;
        if (this.selectedPaymentType && this.checkoutData.hasOwnProperty(this.selectedPaymentType)) {
            data = this.checkoutData[this.selectedPaymentType](data);
        }
        for (let i = 0; i < this.dataProvider.length; i++) {
            data = this.dataProvider[i](data)
        }
        return data;
    }

    // UI ELEMENTS HANDLING
    showLoader(text = 'Processing...') {
        if (this.loadingModalActive) {
            return;
        }

        this.loadingModalActive = true;
        $('#checkout-loading .checkout-loading-text').text(text);
        $('#checkout-loading').fadeIn(150);
    }

    hideLoader = function () {
        if (!this.loadingModalActive) {
            return;
        }
        this.loadingModalActive = false;
        $('#checkout-loading').fadeOut(150);
    }

    // CART HANDLING
    addLicense(productId, license) {
        if (this.loadingModalActive) return;
        this.showLoader('Adding...');

        $.ajax(
            {
                url: '/dazstatic/cart/license/product/' + productId,
                data: { 'licenses': [license] }
            }
        )
            .done(res => {
                document.location.reload(true);
            })
            .fail(err => {
                this.handleAjaxError(err, true);
            });
    }

    toggleBalance (switcher) {
        if (typeof (switcher) === "undefined") {
            this.useBalance = !this.useBalance;
        } else {
            this.useBalance = !!switcher;
        }
        this.update();
    }

    addCoupon(code) {
        if (this.loadingModalActive) return;
        this.showLoader('Applying...');

        let that = this;
        return $.ajax({
            url: dazCheckoutUrl + '/coupon',
            data: {
                coupon_code: code
            }
        }).done(function (result) {
            const gtmPayload = {
                event: "add_coupon",
                coupon_name: code,
                success: ''
            }

            if (result.CouponCode !== "" && result.toString() !== "0") {
                that.cartData.summary.couponCode = result.CouponCode;
                that.triggerUpdates(result);
                gtmPayload.success = "true"
                $(document).trigger('checkout:couponAdd');
                document.location.reload(true);
            } else {
                daz.api.displayErrors(['Coupon code is not valid'], true);
                gtmPayload.success = "false"
                that.hideLoader();
            }

            if (daz?.help) {
                daz.help.gtmTrigger(gtmPayload)
            }
        }).fail(err => {
            daz.api.displayErrors(['Unable to add coupon'], true);
            that.hideLoader();
        });
    }

    removeCoupon() {
        if (this.loadingModalActive) return;
        this.showLoader('Removing...');
        let that = this;

        $.ajax({
            url: dazCheckoutUrl + '/coupon',
            data: {
                coupon_code: '',
                remove: '1'
            }
        }).done(result  => {
            that.cartData.summary.couponCode = ""
            that.triggerUpdates(result);
            $(document).trigger('checkout:couponRemove');
            document.location.reload(true);
        }).fail(err => {
            this.handleAjaxError(err, true);
            that.hideLoader();
        });
    }

    applyGiftcard(code) {
        if (this.loadingModalActive) return;
        daz.checkout.showLoader();

        $.ajax({
            url: dazCheckoutUrl + '/giftcard',
            data: {
                giftcard_code: code,
            }
        }).done(function (result) {
            if (result.Success) {
                daz.checkout.useBalance = true;
                daz.checkout.update();
            } else {
                daz.api.displayErrors([result.Error]);
                daz.checkout.hideLoader();
            }
        }).fail(function () {
            daz.api.displayErrors(['Unable to redeem Gift Card'], true);
        })
    }

    // PAYMENT HANDLING
    process() {
        // TODO:
        // get this to work without a page reload

        if (
            this.hasProcessed // Don't double-process
            || this.loadingModalActive
        ) { return; }

        let data = {}
        try {
            this.validateCheckout();
            data = this.getCartFormData();
        } catch (err) {
            daz.api.displayErrors([e]);
            return;
        }

        this.hasProcessed = true;
        let that = this;
        this.showLoader('Processing...');
        $.ajax({
            type: 'POST',
            url: dazCheckoutUrl + '/checkout',
            data: data,
            success: function (result, ttype, xhr) {
                that.handlePaymentSuccess(result, ttype, xhr)
            },
            error: function (thrownErr) {
                that.handleAjaxError(thrownErr);
            },
        }).always(() => {
            this.hasProcessed = false;
            that.hideLoader();
        });
    }

    validateCheckout() {
        if (!this.selectedPaymentType) {
            throw "Please select a payment type"
        }
        if (this.checkoutValidators.hasOwnProperty(this.selectedPaymentType)) {
            this.checkoutValidators[this.selectedPaymentType]();
        }
    }

    handlePaymentSuccess(reply, ttype, xhr) {
        if (!reply.success) {
            this.handleAjaxError(JSON.stringify(reply));
            return;
        }

        window.location.href = reply.redirect;
    };

    // ERROR HANDLING
    handleAjaxError(error, autoHide = false) {
        // autoHide = optional, bool or amount of ms (num type) to wait before hiding errors banner

        let errorMessages = [];

        try {
            let reply;

            if (typeof error === 'string') {
                reply = JSON.parse(error);
            } else {
                reply = JSON.parse(error.responseText)
            }
            if (reply.message) {
                errorMessages = reply.message;
            } else if (reply.error) {
                errorMessages = [reply.error];
            } else {
                throw "unknown error"
            }
        } catch (e) {
            errorMessages = ["There was an error processing this request."];
        }

        daz.api.displayErrors(errorMessages, autoHide);
    }
}
