/***************** Reusable split test class *****************
 * Instantiated in tracking.js
 * The entire test is initialized with data attributes on the wrapper and variants
 * Wrapper Attributes: 
 *  data-ab-test         REQUIRED: THE NAME OF THE TEST IN MAJENTO 
 *  data-ab-background      OPTIONAL: BACKGROUND COLOR TO SHOW WHILE LOADING
 *  data-ab-track-links     OPTIONAL: JUST HAVING THIS ATTRIBUTE WILL ADD UTM PARAMETERS TO ALL LINKS IN THE TEST
 *  data-ab-debug           OPTIONAL: JUST HAVING THIS ATTRIBUTE LOGS DEBUG MESSAGES TO THE CONSOLE
 *  data-ab-whole-page-test OPTIONAL: JUST HAVING THIS ATTRIBUTE WILL MAKE THE TEST A WHOLE PAGE TEST (MIN HEIGHT 100VH)
 * 
 * Variant Attributes:
 *  data-ab-variant         REQUIRED: THE NAME OF THE VARIANT IN MAJENTO
 *  data-ab-variant-disp    OPTIONAL: DEFAULT IS BLOCK--ONLY SET IF IT SHOULD BE SOMETHING ELSE
 * 
 * Example:
 *  <div data-ab-test="test-name" data-ab-background="#fff" data-ab-track-links data-ab-debug data-ab-whole-page-test>
 *      <div data-ab-variant="variant-name-1">
 *          ...
 *          Variant 1
 *          ...
 *      </div>
 *      <div data-ab-variant="variant-name-2">
 *          ...
 *          Variant 2
 *          ...
 *      </div>
 *  </div>
************************************************************/

function abTest(userOptions) {
    // these come from the data attributes on the wrapper/variants
    // see above for details

    this.options = { ...userOptions }

    // DEBUG: Log the options
    this.options.debugLogging ? console.log('abTest: Initializing', this.options) : null;

    // Should never happen, but just in case
    if (!this.options.testName || !this.options.testWrapper) {
        // annoy the piss out of the user
        console.error('abTest: Required options not provided.')
        return
    }

    // set the page background color to prevent flashing
    this.options.testWrapper.style.backgroundColor = this.options.backgroundColor
    this.options.wholePageTest ? this.options.testWrapper.style.minHeight = '100vh' : null;

    this.setVariantElements(this.options.assignedVariant)

    // OPTIONAL: Link click tracking UTM parameters
    if (this.options.trackLinkClicks) {
        this.options.testWrapper.addEventListener('click', (e) => {
            try {
                if (e.target.tagName === 'A' // if the target is a link
                    && e.target.href // if the link has an href
                    && !e.target.href.startsWith("javascript", 0) // if the link is not a javascript link 
                    && !e.target.href.startsWith("#", 0)  // if the link is not a hash link
                    && e.target.host === window.location.host) { // if the link is not external
                    this.handleUTMLinkClick(e)
                }
            } catch (error) {
                // if debug logging is enabled, log the error and prevent the default link behavior
                if (this.options.debugLogging) {
                    e.preventDefault();
                    console.error('abTest: Error handling link click', error)
                }
            }
        })

        this.forms = this.options.testWrapper.querySelectorAll('form')
        // loop through and assign on submit events to all forms in the test
        for (let i = 0; i < this.forms.length; i++) {

            // Todo: make this more robust. This will only work if the form action has no query string
            this.forms[i].setAttribute('action', this.forms[i].getAttribute('action') + '?' + daz.utm.GenerateString({
                utm_campaign: this.options.testName,
                utm_source: this.options.assignedVariant,
                utm_medium: 'form-submit'
            }))

        }
    }
}

abTest.prototype.setVariantElements = function (variantName) {
    this.options.debugLogging ? console.log('abTest: Called setVariantElements to show ', variantName) : null;
    for (let i = 0; i < this.options.variants.length; i++) {
        const variant = this.options.variants[i]
        if (variant.dataset.abVariant === variantName) {
            variant.style.display = variant.dataset.abVariantDisp || 'block';
            this.options.debugLogging ? console.log('abTest: ', variant.dataset.abVariant, ' shown!') : null;
            // set the data attribute on the wrapper to the assigned variant
            this.options.testWrapper.dataset.abAssignment = this.options.assignedVariant

        } else {
            this.options.debugLogging ? console.log('abTest: ', variant.dataset.abVariant, ' removed!') : null;
            variant.remove()
        }
    }
}

abTest.prototype.handleUTMLinkClick = function (e) {
    // Data attributes generated for storing UTM parameters
    e.target.setAttribute("data-ab-name", this.options.testName)
    e.target.setAttribute("data-ab-variant-name", this.options.assignedVariant)
    //e.target.setAttribute("data-ab-link-idx", 'link-' + this.getlinkIdx(link))

    let utmObject = {
        utm_campaign: this.options.testName,
        utm_source: this.options.assignedVariant,
        utm_medium: e.target.innerText
    }

    // Add UTM parameters to the link
    let url = new URL(e.target.href)
    url.search = daz.utm.GenerateString(utmObject)

    e.target.setAttribute("href", url)
    this.options.debugLogging ? console.log('abTest: Link updated to "', url, ' "') : null;
    e.preventDefault()
    location.href = url
}

//just moved this here for now. Will incorporate in the class later
DazApi.prototype.InSplit = function (split, group) {
    let splitTestAssignment = false
    const SplitTests = this.storageGet('SplitTests')
    if (SplitTests && SplitTests.splits && Array.isArray(SplitTests.splits)) {
        for (let i = 0; i < SplitTests.splits.length; i++) {
            let current = SplitTests.splits[i]
            if (current.split_name === split) {
                splitTestAssignment = current.group_name === group
                break
            }
        }
    }
    return splitTestAssignment
}