function DazFilter() {
    this.sorts = {};
    this.filters = {};
    this.hasLoaded = false;
    this.startLoading = false;
    this.loaded = {
        Filters: false,
        Categories: false,
        Sorts: false,
        Owned: false,
        Cart: false,
        Pricing: false,
        Wishlist: false,
        Deals: false,
    };
    this.processedCategories = false;
    this.loadEvents = [];
    this.hashes = {};
	this.previousSplits = null;
    this.customerGroup = null;
    this.compressed = false;
}

DazFilter.prototype.cleanFilter = function(filterSettings) {
	"use strict";

	// list of possible options for DazFilter
	// so that things don't get passed in that
	// filter out everything
	// if any options get added or removed, this
	// is where to change them
	var whitelist = [
		'category',
        'shopCategories',
		'compat_figures',
		'compat_software',
		'genre',
		'platClub',
		'vendor',
		'owned',
		'inCart',
		'enterprise',
		'bundle',
		'omit',
		'new'
	];

	for (var filterType in filterSettings) {
		if ( ! filterSettings.hasOwnProperty(filterType) || (-1 === whitelist.indexOf(filterType))) {
			delete filterSettings[filterType];
		}
	}

	return filterSettings;
};

DazFilter.prototype.onLoad = function(context, func) {
    if (this.hasLoaded) {
        // Call this in a timeout so it never is called inline
        window.setTimeout(function() { func.call(context); },1);
    } else {
        this.loadEvents[this.loadEvents.length] = [context, func];
        if (daz.api && daz.api.storageGet) {
            this.startLoad();
        }
    }
};

DazFilter.prototype.startLoad = function() {
    if (this.startLoading) {
        // Already trying to load
        return;
    }
    this.startLoading = true;

    daz.api.addCall("SplitTests", {callbackClass: this, callbackFunc: this.loadSplitTests, onlyOnce: false});

    this.loadPart('Filters');
    this.loadPart('Categories');
    this.loadPart('Sorts');
    this.waitForPricing();
    daz.api.addCall("Catalog/owned", {callbackClass: this, callbackFunc: this.loadOwned, onlyOnce: true});
    daz.api.addCall("License/owned", {onlyOnce: true, callbackFunc: function() {}});
    daz.api.addCall("Cart/contents", {callbackClass: this, callbackFunc: this.loadCartContents, onlyOnce: false});
    daz.api.addCall("User/info", {callbackClass: this, callbackFunc: this.loadWishlistContents, onlyOnce: false});
    daz.api.addCall("User/info", {callbackClass: this, callbackFunc: this.initDeals, onlyOnce: false});
};

DazFilter.prototype.initDeals = function(ignore, data, isCache) {
    if (isCache || this.loaded.Deals) {
        return;
    }
    if (!this.loaded.Pricing) {
        setTimeout(() => this.initDeals(ignore, data, isCache), 25);
        return;
    }
    daz.deals = new DazDeals(daz.api);
    daz.deals.initDeals(ignore, data, isCache);

    this.loaded.Deals = true;
    this.doneLoading();
};

DazFilter.prototype.loadPart = function(type) {
    this.compressed = true;
    var funcName = 'process'+type;
    var data = this.loadFromStorage(type);
    if (data != false) {
        this[funcName](data);
    }

    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `/dazstatic/slab/get${type}?all=true&jsonp=true&compress=true`;
    script.async = true;
    document.body.appendChild(script);
};

DazFilter.prototype.waitForPricing = function() {
    if (typeof(dazPricing) == 'undefined') {
        window.setTimeout($.proxy(this.waitForPricing, this), 50);
        return;
    }

    var priceData = [];

    for (var productId in dazPricing) {
        priceData.push([productId,dazPricing[productId]]);
    }

    priceData.sort(function(a,b){
        if (a[1]>b[1]){ return -1;}
        else if (a[1]<b[1]) {return 1;}
        else if (a[0]<b[0]) {return -1;}
        else {return 1;}
    });

    this.sorts.price = {};
    for (var i = 0; i < priceData.length; i++ ) {
        this.sorts.price[priceData[i][0]] = i;
    }

    this.loaded.Pricing = true;
    this.doneLoading();
};

DazFilter.prototype.loadRemotePart = function(type,data) {
    if (this.hashes[type] != data._hash) {
        data.saveDate = Date.now();

        daz.api.storagePut('FilterData_'+type, data);
    }

    if (!this.loaded[type]) {
        data = this.loadFromStorage(type);

        if (data != false) {
            var funcName = 'process'+type;
            this[funcName](data);
        }
    }
};

DazFilter.prototype.loadFromStorage = function(type) {
    var data = daz.api.storageGet('FilterData_'+type);
    if (data == null) {
        // No data in local storage, don't mark it as finished loading
        return false;
    }
    if ((Date.now() - data.saveDate) > 86400000) {
        // Over a day old, don't use it.
        return false;
    }
    this.hashes[type] = data._hash;
    delete data._hash;
    delete data.saveDate;

    return data;
};

DazFilter.prototype.processFilters = function(data) {
    this.filterSorts = data._sorts;
    delete data._sorts;

    for (let filterType in data) {
        this.filters[filterType] = {};
        for (let filterOpt in data[filterType]) {
            if (("&nbsp;" === filterOpt) || /^\s*$/.test(filterOpt)) {
                continue;
            }
            if (this.compressed) {
                this.filters[filterType][filterOpt] = data[filterType][filterOpt];
                continue;
            }
            this.filters[filterType][filterOpt] = {};
            for (let i in data[filterType][filterOpt]) {
                let id = data[filterType][filterOpt][i];
                this.filters[filterType][filterOpt][id] = true;
            }
        }
    }

    this.loaded['Filters'] = true;
    this.doneLoading();
};

DazFilter.prototype.processCategories = function(data) {
    // Get the data for the categories, leave it alone and only process it when necessary
    this.rawCategories = data;
    for (var catId in this.rawCategories) {
        if (this.rawCategories[catId].parent === 0) {
            this.rootCategory = catId;
            break;
        }
    }
    this.filters.category = {};
    this.loaded['Categories'] = true;

    this.doneLoading();
};

DazFilter.prototype.processSorts = function(data) {
    for (var sortType in data) {
        this.sorts[sortType] = {};
        if (this.compressed && typeof(data[sortType]) == 'string') {
            data[sortType] = this.unpackBuffer(data[sortType]);
        }
        for (var i = 0; i < data[sortType]?.length; i++) {
            this.sorts[sortType][data[sortType][i]] = i;
        }
    }

    this.loaded['Sorts'] = true;

    this.doneLoading();
};

DazFilter.prototype.loadOwned = function(ignore, data, isCache) {
    this.filters.owned = {};

    if (data.owned) {
        if (this.compressed) {
            data.owned = this.unpackBuffer(data.owned)
        }
        for (var i = 0; i < data.owned.length; i++) {
            // Giftcard & Daz Plus subscriptions
            if (data.owned[i] === 14548  || data.owned[i] == 16873 || data.owned[i] == 16874 || data.owned[i] == 16875) {
                continue
            }
            this.filters.owned[data.owned[i]] = true;
        }
    }

    this.loaded['Owned'] = true;
    this.doneLoading();
};

DazFilter.prototype.loadCartContents = function(ignore, data, isCache) {
    this.filters.inCart = {};

    if (data.products) {
        for (var i = 0; i < data.products.length; i++) {
            this.filters.inCart[data.products[i]] = true;
        }
    }

    if (!this.loaded['Cart']) {
        this.loaded['Cart'] = true;
        this.doneLoading();
    }
};

DazFilter.prototype.loadSplitTests = function (ignore, data, isCache) {
	try {
		if (!data.splits || !data.splits.length) {
			return
		}

		if (this.previousSplits == null) {
			this.previousSplits = document.body.className;
		}

		let extraClasses = "";
		for (let i = 0; i < data.splits.length; i++) {
			let add = data.splits[i].split_name+"_"+data.splits[i].group_name;
			extraClasses = extraClasses+add+" ";
            $(window).trigger('ab:show', [data.splits[i].split_name, data.splits[i].group_name]);
        }

		document.body.className = this.previousSplits + " " + extraClasses;
	} catch (e) {}
}

DazFilter.prototype.loadWishlistContents = function(ignore, data, isCache) {
    this.filters.inWishlist = {};

    if (data.wishlistItems) {
        for (var i = 0; i < data.wishlistItems.length; i++) {
            this.filters.inWishlist[data.wishlistItems[i]] = true;
        }
    }

    if (!this.loaded['Wishlist']) {
        this.loaded['Wishlist'] = true;
        this.doneLoading();
    }
};

DazFilter.prototype.doneLoading = function() {
    for (var idx in this.loaded) {
        if (!this.loaded[idx]) {
            return;
        }
    }

    this.hasLoaded = true;

    for (var i = 0; i < this.loadEvents.length; i++ ) {
        this.loadEvents[i][1].call(this.loadEvents[i][0]);
    }
};

// Provide force = true if you want to force the filter to run even if hasLoaded = false (useful for one-off filters which don't need to wait for page load)
DazFilter.prototype.filterProducts = function (productList, filterSettings, force = false) {
    if (productList === null) {
        productList = [];
    }
    if (!this.hasLoaded && !force) {
        return productList;
    }

    if (productList === true) {
        // Full list
        // Just use the date sort order, it should be pretty full
        productList = [];
        for (let id in this.sorts.date) {
            productList.push(id);
        }
    }

	filterSettings = this.cleanFilter(filterSettings);

	filterSettings.enterprise = null;
	if (typeof daz.cart != 'undefined' && typeof daz.cart.enterprise != 'undefined') {
		filterSettings.enterprise = daz.cart.enterprise;
	}
    if (filterSettings.enterprise) {
        delete filterSettings['platClub'];
    }

	if (typeof(filterSettings.platClub) == 'object') {
		if (filterSettings.platClub[0] == "yes" || filterSettings.platClub[0] == 1) {
			filterSettings.platClub = 1;
		} else {
			filterSettings.platClub = 0;
		}
	}

    if (typeof(filterSettings.bundle) == 'object') {
        if (filterSettings.bundle[0] == "yes" || filterSettings.bundle[0] == 1) {
            filterSettings.bundle = true;
        } else {
            filterSettings.bundle = false;
        }
    }

    const ignoreList = ['omit', 'owned', 'inCart', 'new', 'platClub', 'enterprise', 'shopCategories', 'bundle'];
    const yesFilters = ['bundle', 'new', 'platClub', 'enterprise'];

    for (let filterType in filterSettings) {
        let newList = [];

        if (ignoreList.indexOf(filterType) !== -1) {
            continue;
        }

        if (filterSettings[filterType].length === 0) {
            continue;
        }

        for (let i = 0; i < productList.length; i++) {
            let id = productList[i]
            let foundIt = false
            for (let ii = 0; ii < filterSettings[filterType].length; ii++) {
                let filterOpt = filterSettings[filterType][ii]
                if (filterType === 'category' && !this.filters.category[filterOpt]) {
                    this.buildCategoryFilter(filterOpt)
                }
                if (filterType !== 'category') {
                    this.unpackFilters(filterType, filterOpt);
                }
                if (this.filters[filterType][filterOpt] != undefined && this.filters[filterType][filterOpt][id]) {
                    foundIt = true
                }
                if (foundIt) {
                    newList.push(id)
                }
            }
        }
        productList = newList;
    }

    productList = this.productsInFilter(productList, filterSettings['inCart'], 'inCart');

    if (typeof filterSettings.owned != 'undefined' && filterSettings.owned === true) {
        const newList = [];
        for (let i = 0; i < productList.length; i++) {
            let id = productList[i];
            if (typeof this.filters.owned[id] == 'undefined') {
                newList.push(id);
            }
        }
        productList = newList;
    }

    if (filterSettings.omit && filterSettings.omit.length > 0) {
        const newList = [];
        filterSettings.omit.forEach(function (productId) {
            productList.forEach(function (id) {
                if (productId != id) {
                    newList.push(id)
                }
            })
        })

        productList = newList;
    }

    for (const filterType of yesFilters) {
        productList = this.productsInFilter(productList, filterSettings[filterType], filterType, 'yes');
    }

    return productList;
};

DazFilter.prototype.productsInFilter = function (productIDs, filterSetting, filterType, filterOpt = null, match = true) {
    if (filterSetting === undefined || !filterSetting) {
        return productIDs;
    }
    filterSetting = !!filterSetting;
    match = !!match;
    const newList = [];

    if (filterOpt) {
        this.unpackFilters(filterType, filterOpt);
        for (let i = 0; i < productIDs.length; i++) {
            if ((this.filters[filterType][filterOpt][productIDs[i]] === true) === filterSetting) {
                newList.push(productIDs[i]);
            }
        }
    } else {
        for (let i = 0; i < productIDs.length; i++) {
            if ((this.filters[filterType][productIDs[i]] === true) === filterSetting) {
                newList.push(productIDs[i]);
            }
        }
    }

    return newList;
}

DazFilter.prototype.unpackBuffer = function(bufferIn) {
    // Base64 decode buffer into byte array
    const asString = atob(bufferIn);
    const buffer = new Array(asString.length);
    for (let i = 0; i < asString.length; i++) {
        buffer[i] = asString.charCodeAt(i)
    }

    // Unpack VarInt ids
    const values = [];
    let index = 0;
    while (index <= buffer.length) {
        let value = 0;
        let myLength = 0;
        while (true) {
            const cb = buffer[index];
            value |= (cb & 0x7F) << (myLength * 7);
            index += 1;
            myLength += 1;
            if (myLength > 5) {
                throw new Error('integer unpack exception');
            }
            if ((cb & 0x80) !== 0x80) {
                break;
            }
        }
        index<= buffer.length && values.push(value);
    }
    return values;
}

DazFilter.prototype.unpackFilters = function (filterType, filterOpt) {
    if (filterType === 'category') {
        return;
    }
    if (this.filters[filterType][filterOpt] === undefined) {
        return;
    }
    if (typeof (this.filters[filterType][filterOpt]) === "object") {
        return
    }
    const opts = this.unpackBuffer(this.filters[filterType][filterOpt]);
    this.filters[filterType][filterOpt] = {};
    for (let id of opts) {
        this.filters[filterType][filterOpt][id] = true;
    }
}

DazFilter.prototype.sortProducts = function (productList, orderName, orderDir) {
    if (!this.hasLoaded) {
        return productList;
    }

    if (!this.sorts[orderName]) {
        return productList;
    }

    var self = this;

    var aVal, bVal;

    var largerVal = 1;
    var smallerVal = -1;

    if (orderDir == 'ASC') {
        largerVal = -1;
        smallerVal = 1;
    }

    var sortOrder = self.sorts[orderName];
    var sortedList = productList.slice(0);


    sortedList.sort(function(a,b) {

        aVal = sortOrder[a];
        if (aVal == undefined) {
            aVal = null;
        }
        bVal = sortOrder[b];
        if (bVal == undefined) {
            bVal = null;
        }

        if (aVal != undefined && bVal != undefined) {
            if (aVal>bVal) {
                return largerVal;
            } else {
                return smallerVal;
            }
        } else if (aVal == undefined && bVal == undefined) {
            if (a > b) {
                return smallerVal;
            } else {
                return largerVal;
            }
        } else {
            if (bVal == undefined) {
                return smallerVal;
            } else {
                return largerVal;
            }
        }
    });

    return sortedList;
};

DazFilter.prototype.buildCategoryFilter = function(categoryId) {
    this.filters.category[categoryId] = this.getChildProducts(categoryId);
};

DazFilter.prototype.getChildProducts = function(parentId) {
    var childCategories = [parentId];
    var products = {};

    while (childCategories.length > 0) {
        var categoryId = childCategories.pop();

        var thisCat = this.rawCategories[categoryId];
        if (!thisCat) {
            continue;
        }
        if (this.compressed && typeof this.rawCategories[categoryId].products === 'string') {
            this.rawCategories[categoryId].products = this.unpackBuffer( this.rawCategories[categoryId].products);
        }
        if (thisCat.children) {
            for (var i = 0; i < thisCat.children.length; i++) {
                childCategories[childCategories.length] = thisCat.children[i];
            }
        }

        if (thisCat.products) {
            for (var i = 0; i < thisCat.products.length; i++) {
                products[thisCat.products[i]] = true;
            }
        }
    }

    return products;
};

DazFilter.prototype.productIsOwned = function(productId) {
    if (!this.hasLoaded) {
        return false;
    }

    if (this.compressed && typeof this.filters.owned === 'string') {
        this.filters.owned = this.unpackBuffer(this.filters.owned);
    }

    return !!this.filters.owned[productId];
};

DazFilter.prototype.productIsInCart = function(productId) {
    if (!this.hasLoaded) {
        return false;
    }

    return !!this.filters.inCart[productId];
};

DazFilter.prototype.productIsInWishlist = function(productId) {
    if (!this.hasLoaded) {
        return false;
    }

    return !!this.filters.inWishlist[productId];
};

DazFilter.prototype.productIsPlatClubExclusive = function(productId) {
    if (!this.hasLoaded) {
        return false;
    }

    if (!this.filters.platClub.hasOwnProperty('exclusive')) {
        return false;
    }

    return !!this.filters.platClub['exclusive'][productId];
};

DazFilter.prototype.categorizeProducts = function(productList) {
    var prods = {}; // Index the product list so it's faster to lookup
    for (var i = 0; i < productList.length; i++) {
        prods[productList[i]] = true;
    }

    return this.categorizeSubProducts(this.rootCategory, prods);
};

DazFilter.prototype.categorizeSubProducts = function(catId, prods) {
    var thisCat = this.rawCategories[catId];
    var catData = {id: catId, products: [], children: []};
    if (!thisCat) {
        return catData;
    }
    var tempProducts = {};
    for (var i = thisCat.products.length-1; i > -1; i--) {
        if (prods[thisCat.products[i]]) {
            tempProducts[thisCat.products[i]] = true;
        }
    }

    for (var i = thisCat.children.length-1; i > -1; i--) {
        var subcat = this.categorizeSubProducts(thisCat.children[i], prods);
        var hasProds = false;
        if (subcat.products.length < 1) {
            continue;
        }
        for (var ii = 0; ii < subcat.products.length; ii++) {
            hasProds = true;
            tempProducts[subcat.products[ii]] = true;
        }
        if (hasProds) {
            catData.children.push(subcat);
        }
    }

    for (var prodId in tempProducts) {
        catData.products.push(prodId);
    }

    return catData;
};

DazFilter.prototype.myCustomerGroup = function() {
    if (this.customerGroup === null) {
        this.customerGroup = parseInt($.cookie('customergroup'), 10);
        if (isNaN(this.customerGroup)) {
            this.customerGroup = 0;
        }
    }
    return this.customerGroup;
}

DazFilter.prototype.isPlatClub = function() {
    let group = this.myCustomerGroup();
    return group === 8 || group === 5;
}

// This needs to be setup before .ready() so that other things in .ready()
// can call it
if (!daz) { var daz = {}; }
daz.filter = new DazFilter();
