class DazMature {
    constructor() {
        this.showMature = null;
        this.loader = null;
    }

    async is(withConfigure = true) {
        if (this.loader !== null) {
            return this.loader;
        }
        await this.init();
        this.loader = new Promise((resolve, reject) => {
            let show = localStorage.getItem('user_showMature');
            const timer = setInterval(() => {
                if (!daz?.api) {
                    return
                }
                clearInterval(timer);
                daz.api.addCall("User/info", {
                    callbackClass: this, callbackFunc: async (call, data) => {
                        this.configure(data.showMature, withConfigure).then((userShow) => {
                            resolve(!!userShow)
                        });
                    },
                    onlyOnce: true
                });

            }, 25);
            if (show === 'true') {
                resolve(true);
            }
        });
        return this.loader;
    }


    async configure(option, withConfigure) {
        if (option === true) {
            return option;
        }
        if (!withConfigure) {
            return false;
        }

        return new Promise((resolve, reject) => {
            $(document).ready(() => {
                const modal = $('#mature-config');
                if (!modal[0]) {
                    resolve(false);
                    return;
                }
                const modalToggler = new DazToggler(null, {
                    toggleElem: modal,
                    modal: true,
                    modalFull: false,
                    noElem: true,
                    preventClose: true
                });

                modal.trigger('daztoggle-show');
                modal.find('.js-mature').on('click', function () {
                    resolve($(this).data('show')?.toString() === '1');
                    modal.trigger('daztoggle-hide');
                })
            })
        })
    }

    async init(withConfigure = false) {
        if (this._initDone) {
            return;
        }
        this._initDone = true;
        document.querySelectorAll('img.maturelock').forEach(elem => {
            this.push(new DazMatureImage(elem));
        });
        if (this.#matureElements.length === 0) {
            return;
        }

        return this.is(withConfigure).then((show) => {
            const loadImages = () => {
                this.#matureElements.forEach((dm) => {
                    dm.toggle(show);
                })
            };

            if (document.readyState !== 'loading') {
                loadImages();
            } else {
                document.addEventListener("DOMContentLoaded", loadImages);
            }
        });
    }

    /**
     * @type DazMatureElement[]
     */
    #matureElements = [];

    push(dm) {
        this.#matureElements.push(dm);
    }

    showAll(e) {
        this.#matureElements.forEach((dm) => {
            dm.show(e);
        })
        this.#matureElements = [];
    }


}

class DazMatureElement {

    /**
     * @param element HTMLElement
     */
    constructor(element) {
        this.element = element;
    }

    element;
    data;
    showing;

    doShow(e) {
    }

    doHide(e) {
    }

    renderBlocker(element) {
        const parent = element.parentElement;
        const container = document.createElement("div");
        container.classList.add('mature-container', 'obscure');
        const div = document.createElement("div");
        div.classList.add("mature-blur");
        div.appendChild(element);
        container.appendChild(div);
        const cover = document.createElement("div");
        cover.classList.add("mature-cover");
        cover.innerHTML = '<div class="mature-content">MATURE CONTENT</div>';
        cover.addEventListener('click', (e) => this.click(e));
        container.appendChild(cover);
        parent.prepend(container);
    }

    click(e) {
    }

    /**
     *
     * @param e Event
     */
    show(e) {
        if (this.showing) {
            return;
        }
        this.showing = true;
        this.doShow(e);
    }

    /**
     *
     * @param e Event
     */
    hide(e) {
        if (this.showing === false) {
            return;
        }
        this.showing = false;
        this.doHide(e);
    }

    toggle(show, e = null) {
        if (show) {
            this.show(e);
        } else {
            this.hide(e);
        }
    }
}

class DazMatureSlab extends DazMatureElement {
    /**
     * @param element HTMLElement
     */
    constructor(element, data) {
        super(element);
        this.data = data;
        this.renderBlocker(element.querySelector('img'));
    }
}

class DazMatureImage extends DazMatureElement {
    /**
     * @param element HTMLElement
     */
    constructor(element) {
        super(element);
        this.data = {...element.dataset};
        this.renderBlocker(element);
    }

    click(e) {
        if (this.showing) {
            return;
        }
        e.stopImmediatePropagation();
        e.preventDefault();
        daz.mature.configure(null, true).then((show) => {
            if (!show) {
                return;
            }
            daz.mature.showAll(e);
        })
    }

    /**
     *
     * @param e Event
     */
    doShow(e) {
        this.element.closest('.mature-container')?.classList.remove("obscure");
    }
}


class DazMatureZoom extends DazMatureElement {
    /**
     * @param element HTMLElement
     */
    constructor(element) {
        super(element.querySelector('.fancybox-image'));
        this.data = {...element.dataset};
        this.renderBlocker(this.element);
    }

    click(e) {
        if (this.showing) {
            return;
        }
        e.stopImmediatePropagation();
        e.preventDefault();
    }
}


daz.mature = new DazMature();

