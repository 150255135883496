// Renew!
function CarouselBase(elem, api) {
    if (!elem) {
        return;
    }

    this.elem = elem;
    this.api = api;
    this.isReady = false;
    this.magnetTimer = null;
    this.lastHighlight = null;
    this.currHighlight = null;
    this.hideSidebars = false;
    this.isScrolling = false;

    this.ul = this.elem.find('>ul');
    // Way to go IOS7, without listening to this event the scrolling
    // on the carousels is garbage
    this.ul.on('touchstart', function(event) {});

    this.endOfRope = false;
    this.startOfRope = true;

    this.elem.on('mouseover', $.proxy(this.mouseOver, this));
    this.elem.on('mouseout', $.proxy(this.mouseOut, this));
    this.ul.on('scroll', $.proxy(this.handleScrollEvent, this));
    this.elem.on('daz-carousel-change', $.proxy(this.changeHighlight, this));
    $(document).on('debounced-resize', $.proxy(this.handleScroll, this));

    this.startInit();
}

CarouselBase.alreadySetupKeyPress = false;
CarouselBase.focusedCarousel = null;
CarouselBase.rotateGroups = {};
CarouselBase.carousels = [];

CarouselBase.factory = function(elem) {
    var type = elem.attr('data-carousel-type');
    if (!type) {
        type = 'Base';
    }

    CarouselBase.setupKeyPress();

    return new window['Carousel'+type](elem, daz.api);
}

// Used to reload carousels on the fly without reloading the whole page, as when filters are applied
CarouselBase.reinitCarousels = function() {
    CarouselBase.carousels.forEach(function(carousel) {
        let fillerHtml = '';

        carousel.displayList = [];
        carousel.displayList = carousel.getProductList();

        if (carousel.displayList.length == 0) {
            // carousel.elem is a jQuery object
            carousel.elem.closest('.carousels').hide();
            carousel.elem.closest('.js-carousels').hide();
            return;
        }

        for (let i = 0; i < carousel.displayList.length; i++) {
            fillerHtml = fillerHtml + Templates.slabholder.render({ elemId: carousel.baseElemId + i, id: i, smallSlab: "true" });
        }
        carousel.ul.html(fillerHtml);

        let lis = carousel.ul.find('>li');
        for (let i = 0; i < carousel.displayList.length; i++) {
            $(lis[i]).attr('data-productid', carousel.displayList[i]);
        }

        $.proxy(carousel.fillAllSlabs, carousel);
        carousel.handleScroll();
        carousel.handleScrollEvent();
    });
}

CarouselBase.initCarousels = function(baseElem) {
    baseElem.find('.daz-carousel').each(function(idx, elem) {
        CarouselBase.carousels.push(new CarouselBase.factory($(elem)));
    });

    baseElem.find('.daz-carousel-subcategory-toggle').each(function(idx, elem) {
        $(elem).on('click', function() {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }
            var subs = baseElem.find('.Subcategories_carousels');
            subs.slideToggle();
            window.setTimeout(function() {subs.find('ul').trigger('scroll');}, 1);
        });
    });
};


CarouselBase.setupKeyPress = function() {
    if (CarouselBase.alreadySetupKeyPress) {
        return;
    }

    CarouselBase.alreadySetupKeyPress = true;

    CarouselBase.focusedCarousel = null;

    $(document).on('keydown', CarouselBase.handleKeyPress);
};

CarouselBase.handleKeyPress = function(event)  {
    if (event.which == 37 || event.which == 39 ) {
        if (CarouselBase.focusedCarousel == null) {
            return;
        }

        event.stopPropagation();

        if (event.which == 37) {
            CarouselBase.focusedCarousel.moveLeft.call(CarouselBase.focusedCarousel);
        } else {
            CarouselBase.focusedCarousel.moveRight.call(CarouselBase.focusedCarousel);
        }
    }
};

CarouselBase.rotateGroup = function(groupName) {
    var idx = Math.floor(Math.random()*CarouselBase.rotateGroups[groupName].items.length);

    CarouselBase.rotateGroups[groupName].items[idx].rotate();
};

CarouselBase.prototype.handleScrollEvent = function() {
    if (!this.scrollTimeout && !this.isScrolling) {
        this.scrollTimeout = window.setTimeout($.proxy(this.handleScroll, this), 150);
    }
};

CarouselBase.prototype.handleScroll = function() {
    this.scrollTimeout = null;
    var idx = 0;
    var currPos = 0;
    var offset = this.ul.offset().left;

    var lis = this.ul.find('>li');
    for (var i = 0; i < lis.length; i++) {
        var $li = $(lis[i]);
        var pos = $li.offset().left - offset;
        if (pos > -0.1) {
            idx = i - 1;
            if (pos < ($li.outerWidth(true)/2)) {
                idx = i;
            }
            break;
        }
    }

    if (idx != this.lastHighlight) {
        this.currHighlight = idx;
        this.elem.trigger('daz-carousel-change', this);
        this.lastHighlight = idx;
    }

};

CarouselBase.prototype.mouseOver = function() {
    CarouselBase.focusedCarousel = this;
};

CarouselBase.prototype.mouseOut = function() {
    CarouselBase.focusedCarousel = null;
};

CarouselBase.prototype.moveLeft = function(isAuto) {
    var durMul = 1;
    if (isAuto === true) {
        durMul = 6;
    }

    var scrollPos = 0;
    var offset = this.ul.offset().left;

    var lis = this.ul.find('>li');
    if (!lis[0]) {
        return;
    }

    lis.each(function(idx, elem) {
        var pos = $(this).offset().left + $(this).outerWidth(true) - offset;
        if (pos < -15) {
            scrollPos += $(this).outerWidth(true);
        }
    });

    if (scrollPos == 0) {
        if (this.startOfRope) {
            // Loop to the right
            this.startOfRope = false;
            this.endOfRope = true;
            var totalWidth = 0;
            lis.each(function() { totalWidth += $(this).outerWidth(true); });
            this.scrollTo(totalWidth, 300 * durMul);
            return;
        } else {
            this.startOfRope = true;
        }
    } else {
        this.startOfRope = false;
    }

    this.endOfRope = false;
    this.scrollTo(scrollPos, 150 * durMul);
};

CarouselBase.prototype.moveRight = function(isAuto) {
    var durMul = 1;
    if (isAuto === true) {
        durMul = 6;
    }

    if (this.endOfRope) {
        // Loop to the left
        this.startOfRope = true;
        this.endOfRope = false;
        this.scrollTo(0, 300 * durMul);
        return;
    }

    var scrollPos = 0;
    var offset = this.ul.offset().left;

    var lis = this.ul.find('>li');
    if (!lis[0]) {
        return;
    }

    var width = this.ul.outerWidth(true);
    var viewportRight = width + offset - $(lis[0]).offset().left;

    this.endOfRope = true;
    for (var i = 0; i < lis.length; i++) {
        $li = $(lis[i]);
        var liWidth = $li.outerWidth(true);
        scrollPos += liWidth;
        if (scrollPos > viewportRight + 15) {
            this.endOfRope = false;
            break;
        }
    }
    if ( i == lis.length-1 ) {
        this.endOfRope = true;
    }

    scrollPos = scrollPos - width;

    this.scrollTo(scrollPos, 150 * durMul);
};

CarouselBase.prototype.scrollTo = function(position, duration) {
    var self = this;
    this.isScrolling = true;
    this.ul.animate({scrollLeft: position}, {
        duration: duration,
        easing: 'linear',
        queue: false,
        done: $.proxy(this.scrollDone, this)
    });
};

CarouselBase.prototype.scrollDone = function() {
    this.isScrolling = false;
};

CarouselBase.prototype.scrollToImage = function(imageElem) {
    var elem = $(imageElem);

    var newLeft = this.ul.scrollLeft()
        + elem.offset().left;
    this.scrollTo(newLeft, 150);
};

CarouselBase.prototype.rotate = function() {
    if (CarouselBase.focusedCarousel == this) {
        // Don't rotate if they have the mouse over this carousel
        return;
    }

    this.moveRight(true);
};

CarouselBase.prototype.mouseOverLeft = function() {
    this.scrollSpeed = -7;
    this.mouseOverInterval = window.setInterval($.proxy(this.mouseOverScroll, this), 25);
};

CarouselBase.prototype.mouseOverRight = function() {
    this.scrollSpeed = 7;
    this.mouseOverInterval = window.setInterval($.proxy(this.mouseOverScroll, this), 25);
};

CarouselBase.prototype.mouseOverScroll = function() {
    this.ul.scrollLeft(this.ul.scrollLeft()+this.scrollSpeed);
};

CarouselBase.prototype.mouseOverEnd = function() {
    window.clearInterval(this.mouseOverInterval);
};

CarouselBase.prototype.changeHighlight = function() {

    var lis = this.ul.find('>li');
    if (!lis[this.currHighlight]) {
        // Can't find an offset to this element
        return;
    }

    var $li = $(lis[this.currHighlight]);

    if (!$li.attr('data-relatedthumb')) {
        // No related thumbnail, don't do anything
        return;
    }

    var thumb = $($li.attr('data-relatedthumb'));
    thumb.addClass('carousel_thumb_highlight');
    var parent = thumb.closest('.img_thumbs');
    if (parent) {
        // Scroll this into view
        var newLeft = parent.scrollLeft() - parent.offset().left
            +thumb.offset().left;
        var newTop = parent.scrollTop() - parent.offset().top
            +thumb.offset().top;
        parent.animate({scrollLeft: newLeft, scrollTop: newTop},{
            duration: 150,
            easing: 'swing',
            queue: true
        });
    }
    if (this.lastHighlight != null) {
        var $li = $(lis[this.lastHighlight]);
        $($li.attr('data-relatedthumb')).removeClass('carousel_thumb_highlight');
    }
};

CarouselBase.prototype.startInit = function() {
    this.finishInit();
};

CarouselBase.prototype.finishInit = function() {
    var parent = this.ul.parent();
    parent.find('a.prev_link').click($.proxy(this.moveLeft, this))
        .on('mouseover', $.proxy(this.mouseOverLeft, this))
        .on('mouseout', $.proxy(this.mouseOverEnd, this));
    parent.find('a.next_link').click($.proxy(this.moveRight, this))
        .on('mouseover', $.proxy(this.mouseOverRight, this))
        .on('mouseout', $.proxy(this.mouseOverEnd, this));

    // Attach click listeners to the thumbnails, if they exist
    var lis = this.ul.find('>li');
    var self = this;
    lis.each(function(idx) {
        if (!$(this).attr('data-relatedthumb')) {
            return;
        }
        var thumb = $($(this).attr('data-relatedthumb'));
        if (!thumb[0]) {
            return;
        }
        var imgElem = this;
        thumb.click(function() {
            self.scrollToImage.call(self, imgElem);
        });
    });

    if (this.elem.attr('data-rotate-time')) {
        if (this.elem.attr('data-rotate-group')) {
            // We need to collect carousels for this group and have a random one
            // rotate when the timeout hits.
            var group = this.elem.attr('data-rotate-group');
            if (!CarouselBase.rotateGroups[group]) {
                CarouselBase.rotateGroups[group] = {timer: null, items: []};
                CarouselBase.rotateGroups[group].timer = window.setInterval(function() {CarouselBase.rotateGroup(group);}, parseInt(this.elem.attr('data-rotate-time')));
            }
            CarouselBase.rotateGroups[group].items[CarouselBase.rotateGroups[group].items.length] = this;
        } else {
            // This carousel rotates to it's own drummer
            if (!this.rotateTimer) {
                this.rotateTimer = window.setInterval($.proxy(this.rotate, this), parseInt(this.elem.attr('data-rotate-time')));
            }
        }
    }

    this.isReady = true;
    this.handleScroll();
    this.handleScrollEvent();
};

CarouselProducts.prototype = new CarouselBase();
function CarouselProducts(elem, api) {
    if (elem) {
        this.baseElemId = elem.attr('id')+'_';

        CarouselBase.call(this, elem, api);
    } else {
        // We're being called from a "extend"
        CarouselBase.call(this);
    }
}
CarouselProducts.prototype.startInit = function() {
    // Prepopulate with about 15 "null" slabs
    let ml = 20;
    if (this.elem.attr('data-maxlength')) {
        let dml = parseInt(this.elem.attr('data-maxlength'), 10);
        if (dml > 0)  {
            ml = dml;
        }
    }
    var fillerHtml = '';
    for (var i = 0; i < ml; i++) {
        fillerHtml = fillerHtml + Templates.slabholder.render({elemId: this.baseElemId+i, id: i, smallSlab: "true"});
    }
    this.ul.html(fillerHtml);

    this.finishInit();

    // Hook in to the Filter to only launch once everything has loaded
    daz.filter.onLoad(this, this.reallyFinishInit);
};

CarouselProducts.prototype.reallyFinishInit = function() {
    this.displayList = [];

    this.displayList = this.getProductList();

    if (this.displayList.length == 0) {
        // No hits for this carousel
        // Hide it's container element
        this.elem.closest('.carousels').hide();
        this.elem.closest('.js-carousels').hide();
    }

    var lis = this.ul.find('>li');
    if (this.displayList.length < 20) {
        for (var i = this.displayList.length; i < 20; i++) {
			if (lis[i]) {
				this.ul[0].removeChild(lis[i]);
			}
        }
    }

    if (this.displayList.length > 20) {
        for (var i = 20; i < this.displayList.length; i++) {
            this.ul.append(Templates.slabholder.render({elemId: this.baseElemId+i, id: this.displayList[i], smallSlab: "true"}));
        }
    }

    for (var i = 0; i < this.displayList.length; i++) {
        $(lis[i]).attr('data-productid', this.displayList[i]);
    }

    this.ul.on('touchstart.fillAll', $.proxy(this.fillAllSlabs, this));
    this.handleScroll();
    this.handleScrollEvent();
};

CarouselProducts.prototype.fillAllSlabs = function() {
    if (!this.displayList || this.displayList.length < 1) {
        return;
    }
    var self = this;

    var lis = this.ul.find('>li');
    lis.each(function(idx) {
        var $this = $(this);
        if ($this.attr('data-placeholder') != '1') {
            // Not a placeholder, don't need to fill it in
            return;
        }
        var slab = new DazProductSlab({id: self.displayList[idx]}, Templates.slab, '#'+self.baseElemId+idx);
        slab.load();
    });

    this.ul.off('touchstart.fillAll');
};

CarouselProducts.prototype.handleScroll = function() {
    CarouselBase.prototype.handleScroll.call(this);

    if (!this.displayList || this.displayList.length < 1) {
        return;
    }

    if (!this.ul.is(':visible')) {
        return;
    }

    var offset = this.ul.offset().left;
    var maxWidth = this.ul.outerWidth(true);
    var self = this;

    var lis = this.ul.find('>li');
    lis.each(function(idx) {
        var $this = $(this);
        if ($this.attr('data-placeholder') != '1') {
            // Not a placeholder, don't need to fill it in
            return;
        }
        var pos = $this.offset().left - offset;
        var width = $this.outerWidth(true);

        if (pos - width < maxWidth &&
            pos + $this.outerWidth(true) > 0) {
            var slab = new DazProductSlab({id: self.displayList[idx]}, Templates.slab, '#'+self.baseElemId+idx);
            slab.load();
        }
    });

    // Auto-hide useless sidebars
    if (this.ul[0].scrollWidth < this.ul.width()+1) {
        if (!this.hideSidebars) {
            this.elem.find('a.prev_link').css('visibility', 'hidden');
            this.elem.find('a.next_link').css('visibility', 'hidden');
            this.hideSidebars = true;
        }
    } else if (this.hideSidebars) {
        this.elem.find('a.prev_link').css('visibility', 'visible');
        this.elem.find('a.next_link').css('visibility', 'visible');
        this.hideSidebars = false;
    }
};

CarouselProducts.prototype.getProductList = function() {
    const dataProducts = this.elem.attr('data-products');
    if (!dataProducts.trim()) return [];

    var rawProducts = dataProducts.split(',').map(function(id) { return parseInt(id.trim(),10); });
    
    var products = [];
    for (var i = 0; i < rawProducts.length; i++ ) {
        if (isNaN(rawProducts[i])) {
            continue;
        }
        products.push(rawProducts[i]);
    }

    return products;
};

CarouselCategory.seenProducts = {};

CarouselCategory.prototype = new CarouselProducts();
CarouselCategory.prototype.constructor = CarouselCategory;
CarouselCategory.prototype.parent = CarouselBase.prototype;

function CarouselCategory(elem, api) {
    if (elem) {
        this.categoryId = elem.attr('data-category-id').split(',');
        CarouselProducts.call(this, elem, api);
    } else {
        // We're being called from a "extend"
        CarouselProducts.call(this);
    }
}

CarouselCategory.prototype.getProductList = function() {
    var products = daz.filter.filterProducts(true, {owned: true, category: this.categoryId});

    // var seenProducts = {};
    var displayProducts = [];
    // Sort them backwards, so we can pop() off the end
    var trendingList = daz.filter.sortProducts(products, 'trending', 'ASC');
    var popularList = daz.filter.sortProducts(products, 'popular', 'ASC');

    while (displayProducts.length < 25) {
        if (trendingList.length > 0) {
            var trendItem = trendingList.pop();
            if (!CarouselCategory.seenProducts[trendItem]) {
                displayProducts.push(trendItem);
                CarouselCategory.seenProducts[trendItem] = true;
            }
        }
        if (popularList.length > 0) {
            var popItem = popularList.pop();
            if (!CarouselCategory.seenProducts[popItem]) {
                displayProducts.push(popItem);
                CarouselCategory.seenProducts[popItem] = true;
            }
        }
        if (trendingList.length == 0 && popularList.length == 0) {
            break;
        }
    }

    return displayProducts;
};

// CarouselCategory.prototype.getProductList = function() {
//     var products = daz.filter.filterProducts(true, {owned: true, category: [2375,3449]});

//     // var seenProducts = {};
//     var displayProducts = [];
//     // Sort them backwards, so we can pop() off the end
//     var trendingList = daz.filter.sortProducts(products, 'trending', 'ASC');
//     var popularList = daz.filter.sortProducts(products, 'popular', 'ASC');

//     while (displayProducts.length < 25) {
//         if (trendingList.length > 0) {
//             var trendItem = trendingList.pop();
//             if (!CarouselCategory.seenProducts[trendItem]) {
//                 displayProducts.push(trendItem);
//                 CarouselCategory.seenProducts[trendItem] = true;
//             }
//         }
//         if (popularList.length > 0) {
//             var popItem = popularList.pop();
//             if (!CarouselCategory.seenProducts[popItem]) {
//                 displayProducts.push(popItem);
//                 CarouselCategory.seenProducts[popItem] = true;
//             }
//         }
//         if (trendingList.length == 0 && popularList.length == 0) {
//             break;
//         }
//     }

//     return displayProducts;
// };

// CarouselCategory.prototype.getProductList = function() {
//     var allProducts = daz.filter.filterProducts(true, {owned: true, category: [2342]});
//     var productsToRemove = daz.filter.filterProducts(true, {owned: true, category: [2375,3449]});
//     var products = allProducts.filter(function(x) { return productsToRemove.indexOf(x) < 0 });

//     console.log('all: '+allProducts.length);
//     console.log('to remove: '+productsToRemove.length);
//     console.log('remainder: '+products.length);

//     // var seenProducts = {};
//     var displayProducts = [];
//     // Sort them backwards, so we can pop() off the end
//     var trendingList = daz.filter.sortProducts(products, 'trending', 'ASC');
//     var popularList = daz.filter.sortProducts(products, 'popular', 'ASC');

//     while (displayProducts.length < 25) {
//         if (trendingList.length > 0) {
//             var trendItem = trendingList.pop();
//             if (!CarouselCategory.seenProducts[trendItem]) {
//                 displayProducts.push(trendItem);
//                 CarouselCategory.seenProducts[trendItem] = true;
//             }
//         }
//         if (popularList.length > 0) {
//             var popItem = popularList.pop();
//             if (!CarouselCategory.seenProducts[popItem]) {
//                 displayProducts.push(popItem);
//                 CarouselCategory.seenProducts[popItem] = true;
//             }
//         }
//         if (trendingList.length == 0 && popularList.length == 0) {
//             break;
//         }
//     }

//     return displayProducts;
// };

CarouselFancy.prototype = new CarouselBase();

function CarouselFancy(elem, api) {
    if (elem) {
        CarouselBase.call(this, elem, api);
        this.currHighlight = 0;
        $(document).on('debounced-resize', $.proxy(this.startInit, this));
    } else {
        CarouselBase.call(this);
    }
}

CarouselFancy.prototype.startInit = function() {
    var totalWidth = 0;
    this.lis = this.ul.find('>li');
    this.lis.on('click', $.proxy(this.handleItemClick,this));
    this.lis.on('touchstart', $.proxy(this.handleItemClick,this));
    this.lis.map(function(){ totalWidth += $(this).outerWidth(); });
    var elemWidth = this.elem[0].scrollWidth;
    this.offsets = [];


    if (totalWidth > elemWidth) {
        // This is how much scrolling can be done on this element
        var lastWidth = $(this.lis[this.lis.length-1]).outerWidth();
        var remainWidth = totalWidth - elemWidth + lastWidth;
        this.offsets[this.lis.length-1] = remainWidth - lastWidth;
        var currOffset = 0;
        for (var i = 0; i < this.lis.length - 1; i++) {
            this.offsets[i] = currOffset;
            currOffset += ($(this.lis[i]).outerWidth()/totalWidth)*remainWidth;
        }
    } else {
        this.offsets[0] = 0;
    }

    this.finishInit();
};

CarouselFancy.prototype.moveLeft = function() {
    this.currHighlight = this.currHighlight-1;
    if (this.currHighlight < 0) {
        this.currHighlight = this.lis.length - 1;
    }

    this.redisplay();
    this.scrollTo(this.offsets[this.currHighlight]+1, 150);
};

CarouselFancy.prototype.moveRight = function() {
    this.currHighlight = this.currHighlight+1;
    if (this.currHighlight == this.lis.length) {
        this.currHighlight = 0;
    }

    this.redisplay();
    this.scrollTo(this.offsets[this.currHighlight]+1, 150);
};

CarouselFancy.prototype.handleScroll = function() {
    if (!this.lis) {
        return;
    }
    this.scrollTimeout = null;

    var scrollPos = this.ul.scrollLeft();
    for (var i = 0; i < this.offsets.length; i++) {
        if (this.offsets[i] > scrollPos) {
            break;
        }
        this.currHighlight = i;
    }

    this.redisplay();
}

CarouselFancy.prototype.handleItemClick = function(event) {
    if (this.rotateTimer) {
        window.clearInterval(this.rotateTimer);
        this.rotateTimer = false;
    }

    if ($(event.currentTarget).hasClass('active')) {
        // Don't do anything special, this is the active item, pass the click on through
        return;
    }

    for (var i = 0; i < this.lis.length; i++ ) {
        if (this.lis[i] == event.currentTarget) {
            this.currHighlight = i;
            this.redisplay();
            this.scrollTo(this.offsets[this.currHighlight]+1, 150);
            break;
        }
    }

    event.preventDefault();
};

CarouselFancy.prototype.redisplay = function() {
    if (!this.lis) {
        return;
    }

    var classes = ['active', 'oob', 'left_1', 'left_2', 'left_3', 'left_4', 'left_5', 'right_1', 'right_2', 'right_3', 'right_4', 'right_5'];
    for (var i in classes) {
        this.lis.removeClass(classes[i]);
    }

    for (var i = 0; i < this.lis.length; i++ ) {
        var distance = i - this.currHighlight;
        var $li = $(this.lis[i]);
        if (distance < -5 ) {
            $li.addClass('oob').addClass('left_5');
        } else if (distance > 5) {
            $li.addClass('oob').addClass('right_5');
        } else if (distance == 0) {
            $li.addClass('active');
        } else if (distance < 0) {
            $li.addClass('left_'+Math.abs(distance));
        } else {
            $li.addClass('right_'+Math.abs(distance));
        }
    }
};

$(document).ready( function() {
    CarouselBase.initCarousels($(document));
});
