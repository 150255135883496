function DazUtm() {

}

DazUtm.prototype.Record = function() {
	if (window.location.search == '') {
		return;
	}

	var checkFor = [
		'utm_campaign',
		'utm_content',
		'utm_term',
		'utm_source',
		'utm_medium',
		'utm_adgroup',
		'utm_keyword',
		'utm_matchtype'
	];

	var foundIt = false;
	var trackData = {};

	// Thanks Internet Explorer, we can't just use URLSearchParams
	if (typeof URLSearchParams != 'function') {
		// Do it the caveman way.
		var parts = window.location.search.slice(1).split("&");
		for (var i = 0; i < parts.length; i++) {
			var splits = parts[i].split('=');

			for (var ii = 0; ii < checkFor.length; ii++) {
				if (splits[0] == checkFor[ii]) {
					foundIt = true;
					trackData[checkFor[ii]] = splits[1];
					break;
				}
			}
		}
	} else {
		// We aren't stuck in IE, do it the normal way
		var params = new URLSearchParams(window.location.search);
		for (var i = 0; i < checkFor.length; i++) {
			if (params.get(checkFor[i]) != null) {
				foundIt = true;
				trackData[checkFor[i]] = params.get(checkFor[i]);
			}
		}
	}

	if (!foundIt) {
		return;
	}

	var oldTrackData = [];

	try {
		oldTrackData = JSON.parse(window.localStorage.getItem("daz_utm"));
		if (oldTrackData == null) {
			oldTrackData = [];
		}
	} catch (e) {
		oldTrackData = [];
	}

	trackData["recorded_at"] = Math.floor(Date.now() / 1000);
	oldTrackData[oldTrackData.length] = trackData;

	window.localStorage.setItem("daz_utm", JSON.stringify(oldTrackData));
}

DazUtm.prototype.Send = function(type, id) {
	var data;

	try {
		data = window.localStorage.getItem("daz_utm");
	} catch (e) {
		return;
	}

	if (data == null || data == "") {
		return;
	}

	var postData = {
		"record_type": type,
		"record_id": id,
		"utm_data": data
	};

	const attr = daz.api.sessionStorageGet(`attr_${id}`);
	if (attr) {
		postData.attr = attr;
	}

	var params = new URLSearchParams(window.location.search);
	if (params.has('fbclid')) {
		postData.fbclid = params.get('fbclid');
	}

	$.ajax({
		type: "POST",
		url: "/dazutm/record",
		data: postData,
		success: function() {
			window.localStorage.removeItem("daz_utm");
		}
	});

}

if (typeof daz == "undefined") { daz = {}; }
daz.utm = new DazUtm();

DazUtm.prototype.GetReferrer = function(){
	var referrer = document.referrer.split('/')[2];

	if (referrer == 'www.daz3d.com' || !referrer) {
		return;
	}

	var oldTrackData = [];

	try {
		oldTrackData = JSON.parse(window.localStorage.getItem('daz_utm'));
		if (oldTrackData == null) {
			oldTrackData = [];
		}
	} catch (e) {
		oldTrackData = [];
	}

	var trackData = {
		'recorded_at': Math.floor(Date.now() / 1000),
		'utm_campaign' : 'refer',
		'utm_content' : referrer,

	};

	oldTrackData[oldTrackData.length] = trackData;

	window.localStorage.setItem("daz_utm", JSON.stringify(oldTrackData));
}

DazUtm.prototype.GetPage = function(url){
	var oldTrackData = [];

	try {
		oldTrackData = JSON.parse(window.localStorage.getItem('daz_utm'));
		if (oldTrackData == null) {
			oldTrackData = [];
		}
	} catch (e) {
		oldTrackData = [];
	}

	var trackData = {
		'recorded_at': Math.floor(Date.now() / 1000),
		'utm_campaign' : 'page',
		'utm_content' : url,
	};

	oldTrackData[oldTrackData.length] = trackData;

	window.localStorage.setItem("daz_utm", JSON.stringify(oldTrackData));
}

DazUtm.prototype.GetSearch = function(trackingId, query, productId){

	var trackData = {
		'recorded_at': Math.floor(Date.now() / 1000),
		'utm_campaign' : 'search',
		'utm_content' : trackingId,
		'utm_keyword' : query,
		'utm_term' : productId.toString(),
	};

	let oldTrackData = [ trackData ];

	window.localStorage.setItem("daz_utm", JSON.stringify(oldTrackData));
}

DazUtm.prototype.GenerateString = function(utmObj){
	const params = new URLSearchParams({
		...utmObj
	})
	return params.toString()
}

$(document).ready(function() {
	daz.utm.Record();
	daz.utm.GetReferrer();
});

