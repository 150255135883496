function DazResponsive() {
    this.oldSize = null;
    this.newSize = null;
    this.sizeBox = null;
    this.resizeTimeout = null;
    this.checkSize();

    $(window).resize($.proxy(this.checkSize,this));
}

DazResponsive.addListener = function(func, obj) {
    if (this.newSize != null) {
        func.call(obj, this.newSize, this.oldSize);
    }
    
    var self = this;
    $(document).on('dazresponsive-change', function() { func.call(obj, self.oldSize, self.newSize)});
};


DazResponsive.prototype.loadSizeBox = function() {
    if (this.sizeBox == null) {
        this.sizeBox = $('#size_box');
    }
};

DazResponsive.prototype.checkSize = function() {
    if (this.resizeTimeout != null) {
        window.clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = window.setTimeout(this.triggerResizeDebounce, 100);

    this.loadSizeBox();
    if (!this.sizeBox) {
        return null;
    }

    this.oldSize = this.newSize;
    this.newSize = this.sizeBox.css('content');

    if (this.newSize != this.oldSize) {
        //console.log("Size change", this.oldSize, this.newSize);
        $(document).trigger('dazresponsive-change', {'old': this.oldSize, 'new': this.newSize});
    }
};

DazResponsive.prototype.triggerResizeDebounce = function() {
    $(document).trigger('debounced-resize');
};

$(document).ready(function() {
    daz.responsive = new DazResponsive();
});
