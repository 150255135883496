function DazHelper() {
}

DazHelper.prefix = 'sc';
DazHelper.purchaseHooks = {};
DazHelper.signupHooks = {};
DazHelper.confirmHooks = {};
DazHelper.eventHooks = {};
DazHelper.pageHooks = {};
DazHelper.purchaseData = null;
DazHelper.purchaseDataCJ = null;
DazHelper.sentUserId = false;

DazHelper.prototype.tagName = function () {
    return DazHelper.prefix + 'ri' + 'pt';
}

DazHelper.prototype.clearEcommerce = function () {
    dataLayer.push({ ecommerce: null });
}

DazHelper.prototype.gtmTrigger = function (arguments) {
    if (arguments.ecommerce && window.dataLayer?.length) {
        this.clearEcommerce();
    }
    window.dataLayer = window.dataLayer || [];

    const userInfo = localStorage.getItem('User/info') || null;
    if (userInfo) {
        try {
            const infoParsed = JSON.parse(userInfo)
            if (infoParsed.customerId && infoParsed.customerId > 0) {
                arguments.userId = infoParsed.customerId;
                if (!DazHelper.sentUserId) {
                    window.dataLayer.push({ 'userId': infoParsed.customerId });
                    DazHelper.sentUserId = true;
                }
            }
        } catch (err) {
            console.error(err)
        }
    }

    window.dataLayer.push(arguments);
}

// Keep these here just in case there is something calling them from within CMS content
DazHelper.prototype.ga = function () { }
DazHelper.prototype.fb = {}

DazHelper.prototype.purchase = function (purchaseData, purchaseDataCJ) {
    DazHelper.purchaseData = purchaseData;
    DazHelper.purchaseDataCJ = purchaseDataCJ // a duplicate of purchaseData for CJ with some filters applied

    const keys = Object.keys(DazHelper.purchaseHooks);
    for (const key of keys) {
        DazHelper.purchaseHooks[key](purchaseData, purchaseDataCJ);
    }
}

DazHelper.prototype.viewItem = function (itemData) {
    // only expect to work with one item, but gtm expects property name of items
    const { item_id, item_name } = itemData;
    let { price } = itemData;

    const gtmPayload = {
        event: 'view_item',
        currency: 'USD',
        value: 0,
        ecommerce: {
            items: [
                {
                    id: item_id,
                    item_id,
                    item_name,
                    price: 0
                }
            ]
        },
        items: [
            {
                id: item_id,
                google_business_vertical: 'retail',
                item_id,
                item_name,
                price: 0
            }
        ]
        // gtmPayload.items & .value used for ga remarketing
    }

    if (price && Number(price)) {
        price = Number(price)

        gtmPayload.value = price
        gtmPayload.items[0].price = price
        gtmPayload.ecommerce.items[0].price = price
    }

    this.gtmTrigger(gtmPayload)
}

DazHelper.prototype.signup = function (signupData) {
    this.gtmTrigger({ event: "sign_up", method: "Daz3d.com" });
}

DazHelper.prototype.confirm = function (confirmData) {
    const keys = Object.keys(DazHelper.confirmHooks);
    for (const key of keys) {
        DazHelper.confirmHooks[key](confirmData);
    }
}

DazHelper.prototype.event = function (name, data) {
    if (Object.keys(data).length == 0 || name.length == 0) {
        return
    }
    const keys = Object.keys(DazHelper.eventHooks);
    for (const key of keys) {
        DazHelper.eventHooks[key](name, data);
    }
}

DazHelper.prototype.page = function (pageData) {
    const keys = Object.keys(DazHelper.pageHooks);
    for (const key of keys) {
        DazHelper.pageHooks[key](pageData);
    }
}

DazHelper.prototype.onPage = function () {
    $(window).on('beforeDazApi', function () {
        daz.api.addCall('User/info', {
            callbackClass: daz.help,
            callbackFunc: daz.help.realOnPage,
            onlyOnce: true,
            onlyLive: true
        });
    });
}

DazHelper.prototype.realOnPage = function (dontCare, apiInfo) {
    daz.api.addCall('SplitTests', {
        callbackClass: daz.help,
            callbackFunc: (dontCareStill, data) => this.page({p: document.location, r: document.referrer, u: apiInfo.customerId, ab: data, whyDoesNobodyCare: dontCareStill}),
            onlyOnce: true
    })
}

DazHelper.pageHooks.ab = function (pageData) {
    let variants = []
    const element = document.querySelector("[data-ab-test]");

    if (!element) {
        return
    }

    element.querySelectorAll('[data-ab-variant]').forEach(variant => {
        variants.push(variant)
    })
    // hide the variants
    let hideCss = document.createElement('style');
    hideCss.textContent = `[data-ab-variant] { display: none; }`;
    element.prepend(hideCss);

    // get the split info
    let splitData = {'split_name':element.dataset.abTest, 'group_name' : 'control'}
    if(pageData?.ab?.splits){
        pageData.ab.splits.forEach(split => {
            if(split['split_name'] === element.dataset.abTest){
                splitData = split
            }
        });
    }


    /*
    let queryParams = new URLSearchParams(window.location.search);
    queryParams.set("utm_campaign", element.dataset.abTest);
    queryParams.set("utm_source", splitData['group_name']);
    history.replaceState(null, null, "?"+queryParams.toString());
     */

    // create the split test
    DazHelper.abTest = new abTest({
        testName: element.dataset.abTest,
        testWrapper: element,
        assignedVariant: splitData['group_name'],
        backgroundColor: ("abBackground" in element.dataset ? element.dataset.abBackground : '#000'),
        trackLinkClicks: ("abTrackLinks" in element.dataset),
        debugLogging: ("abDebug" in element.dataset),
        wholePageTest: ("abWholePageTest" in element.dataset),
        variants: variants
    })
}

DazHelper.pageHooks.t = function (pageData) {
    const startTime = new Date().getTime();
    const gtagConfig = 'GTM-5JJ4MS2';
    window.dataLayer = window.dataLayer || [];
    let e = document.createElement(DazHelper.prototype.tagName());
    e.id = 't-pageHooks'
    e.async = true;
    e.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtagConfig;
    document.head.appendChild(e);
    window.dataLayer.push({
        'gtm.start': startTime,
        event: 'gtm.js'
    });
    let tag = document.createElement(DazHelper.prototype.tagName());
    tag.id = 'yt-pageHooks';
    tag.async = true;
    tag.src = "https://www.youtube.com/iframe_api";
    document.head.appendChild(tag);
};

DazHelper.eventHooks.t = function (name, data) {
    DazHelper.prototype.gtmTrigger({ 'event': name, data });
};

DazHelper.confirmHooks.t = function (confirmData) {
    DazHelper.prototype.gtmTrigger({ 'event': 'confirmation' });
};

DazHelper.signupHooks.t = function (signupData) {
    DazHelper.prototype.gtmTrigger({ 'event': 'sign up' });
};

// Listrak
DazHelper.pageHooks.l = function (pageData) {
    daz.listrak.initListrak()

    //Send to listrak if they're logged in
    if (DazProductPage.productId > 0 && pageData.u > 0) {
        daz.listrak.browseProduct(DazProductPage.productId.toString())
    }
    //Store if they're not logged in
    else if (DazProductPage.productId > 0) {
        daz.listrak.storeBrowseProduct(DazProductPage.productId.toString())
    }
    //Handle what's been stored once they're logged in
    if (daz.api.storageGet('Listrak/save') && pageData.u > 0) {
        daz.listrak.sendStorage()
    }
};

DazHelper.purchaseHooks.listrak = function (d) {
    let backup = document.createElement('img');
    backup.src = "https://fp.listrakbi.com/fp/3N8jP0RCyiWw.jpg";
    backup.height = 1;
    backup.width = 1;
    document.querySelector('body').appendChild(backup);

    (function (d) { if (typeof _ltk == "undefined") { if (document.addEventListener) document.addEventListener("ltkAsyncListener", function () { _ltk_util.ready(d) }); else { e = document.documentElement; e.ltkAsyncProperty = 0; e.attachEvent("onpropertychange", function (e) { if (e.propertyName == "ltkAsyncProperty") { _ltk_util.ready(d) } }) } } else { _ltk_util.ready(d) } })(function () {
        _ltk.Order.SetCustomer(d.e, d.f, d.l);
        _ltk.Order.OrderNumber = d.o;
        _ltk.Order.ItemTotal = 0.0;
        _ltk.Order.ShippingTotal = 0.0;
        _ltk.Order.TaxTotal = d.x;
        _ltk.Order.HandlingTotal = 0.0;

        for (let i = 0; i < d.p.length; i++) {
            _ltk.Order.AddItem(d.p[i].s, 1, d.p[i].t)
            _ltk.Order.ItemTotal += d.p[i].t
        }
        _ltk.Order.OrderTotal = _ltk.Order.ItemTotal + d.x;
        _ltk.Order.Submit();

    });
};

DazHelper.eventHooks.l = function (name, data) {
    if (daz?.api?.isLoggedIn() && daz?.listrak) {
        daz.listrak.cartChange(name, data)
    }
    else if (name === 'add_cart' || name === 'remove_cart') {
        daz.listrak.storeCartChange(data)
    }
}

// Commission Junction

// Commission Junction g-tag code for ALL pages. Must be placed AFTER site page and conversion tags.
let cjMainTag = function (tagPath, newElem, firstScript) {
    tagPath = 'https://www.mczbf.com/tags/276240949187/tag.js';

    newElem = document.createElement('script'); newElem.src = tagPath;
    newElem.type = 'text/java' + 'script'; newElem.async = true; newElem.id = 'cjapitag';

    firstScript = document.getElementsByTagName('script')[0]; 
    firstScript.parentNode.insertBefore(newElem, firstScript)
};

let getCjeCookie = () => {
    let cjeCookie = document.cookie.split(';')
    cjeCookie = cjeCookie.filter(item => item.includes('cje='))

    if (cjeCookie.length) {
        cjeCookie = cjeCookie[0].split('=')[1]
        return cjeCookie
    } else {
        return ''
    }
}

let concurrentS2S = () => {
    let cjEvent = getCjeCookie()
    let eventTime = `${encodeURIComponent(new Date().toISOString())}`
    let orderId = window.cj.order.orderId
    let currency = window.cj.order.currency
    let coupon = window.cj.order.coupon
    let purchasedItems = window.cj.order.items
    let items = []
    let discount = window.cj.order.discount
    let customerStatus = window.cj.order.cust_status

    purchasedItems.forEach((item, i) => items.push(`ITEM${i + 1}=${item.itemId}&AMT${i + 1}=${item.unitPrice}&QTY${i + 1}=${item.quantity}&`))

    let url = `https://www.emjcd.com/u?CID=1511600&TYPE=432256&METHOD=S2S&SIGNATURE=qvTbwfF3uzWuRpcKYWge35cj&CJEVENT=${cjEvent}&eventTime=${eventTime}&OID=${orderId}&currency=${currency}&coupon=${coupon}&${items.join('')}discount=${discount}&cust_status=${customerStatus}`

    fetch(url, { mode: "no-cors" })
        .then((response) => response.text())
}

DazHelper.pageHooks.c = function (pageData) {

    // Commission Junction g-tag code for site pages that are not transaction confirmation or purchase confirmation pages. This code needs to be placed BEFORE the main CJ tag.
    if (!pageData.p.pathname.includes('/shop/checkout/success')) {
        if (!window.cj) window.cj = {}

        cj.sitePage = {
            enterpriseId: 1511600,
            pageType: '',
            userId: pageData.u.toString(),
            emailHash: '',
            referringChannel: '',
        };

        cjMainTag()
    }

    // add cart data to cj.sitePage items when on cart page.
    if (pageData.p.pathname.includes('/checkout/cart')) {
        let trackCartData = document.querySelectorAll('#temporary-cart-data')
        if (trackCartData[0].dataset.cartdata !== undefined && trackCartData[0].dataset.cartdata !== '') {
            let cartData = JSON.parse(trackCartData[0].dataset.cartdata)
            if (cartData) {
                let productData = cartData.products
                window.cj.sitePage.cartSubtotal = cartData.subtotal / 100
                window.cj.sitePage.items = []

                for (let i = 0; i < productData.length; i++) {
                    let product = {}
                    product.unitPrice = productData[i].base_price / 100
                    product.itemId = productData[i].sku
                    product.quantity = productData[i].qty

                    window.cj.sitePage.items.push(product)
                }
            }
        }
    }
};

DazHelper.purchaseHooks.gtm = function (purchaseData) {
    const { firstTimeBuyer } = purchaseData
    let subtotalAfterDiscounts = 0
    let prods = []

    for (let i = 0; i < purchaseData.p.length; i++) {
        let thisProd = purchaseData.p[i]
        subtotalAfterDiscounts += thisProd.t
        prods.push({
            item_name: thisProd.n,
            id: thisProd.i.toString(),
            item_id: thisProd.i.toString(),
            value: thisProd.t,
            google_business_vertical: 'retail',
            price: thisProd.t,
            quantity: thisProd.q,
            discount: thisProd.d,
            index: i + 1,
        })
    }

    let gtmPayloadPurchase = {
        event: "purchase",
        ecommerce: {
            transaction_id: purchaseData.o,
            value: purchaseData.t || 0.00,
            tax: purchaseData.x || 0.00,
            shipping: purchaseData.s || 0.00,
            currency: "USD",
            firstTimeBuyer: firstTimeBuyer ? 'true' : 'false',
            items: prods,
        },
        items: prods
    }

    daz.help.gtmTrigger(gtmPayloadPurchase)
    if (firstTimeBuyer) daz.help.gtmTrigger({ event: "first_time_buyer", purchase_value: purchaseData.t || 0.00 });
}

DazHelper.purchaseHooks.commissionJunc = function (ignore, purchaseData) {
    // new commission junction code
    // conversion confirmation tag
    if (window.location.pathname.includes('/shop/checkout/success')) {
        if (!window.cj) window.cj = {}

        const customerStatus = $('#order-summary-container').attr('data-first-time-buyer') === 'true' ? 'new' : 'return';

        cj.order = {
            enterpriseId: 1511600, // required
            pageType: 'conversionConfirmation',
            orderId: purchaseData.o.toString(), // required
            actionTrackerId: 432256, // required
            currency: 'USD', // required
            amount: 0, // required
            discount: 0, // required
            coupon: '', // optional
            cjeventOrder: getCjeCookie(),
            items: [],
            cust_status: customerStatus, // Changed to cust_status due to CJ request
        }

        // purchaseData.p is an array of products that qualify for commission junction tracking.
        // Products are filered in the user_checkout.go file includeInCommisison function.
        for (let i = 0; i < purchaseData.p.length; i++) {
            let product = {}
            product.unitPrice = purchaseData.p[i].t + purchaseData.p[i].d
            product.unitPrice = Number(product.unitPrice.toFixed(2))
            product.itemId = purchaseData.p[i].s
            product.quantity = purchaseData.p[i].q

            cj.order.amount += product.unitPrice
            cj.order.discount += purchaseData.p[i].d
            cj.order.items.push(product)
        }

        cj.order.discount = Number(cj.order.discount.toFixed(2))
        cj.order.amount = Number(cj.order.amount.toFixed(2))
        cjMainTag()
        concurrentS2S()
    }
};

daz.help = new DazHelper();
