function GiftCard(productId, minAmount, maxAmount, messageMaxLength) {
    this.productId = productId;
    this.minAmount = minAmount;
    this.maxAmount = maxAmount;
    this.maxMessageLength = messageMaxLength;

}

GiftCard.prototype.parseCurrency = function(currency) {
    if (currency.length < 1) {
        return 0.00;
    }

    if (currency.length > 2 && currency[currency.length-3] == ',') {
        // Looks like they are doing euro style ./, notation
        currency = currency.replace('.','').replace(',','.');
    }

    return parseFloat(currency.replace(/[^0-9.]/g, ''));
};

GiftCard.prototype.validate = function() {
    var errors = [];
    var currAmount = this.parseCurrency($('#giftcard_amount_input').val());
    if (currAmount > this.maxAmount || currAmount < this.minAmount) {
        errors.push('Invalid gift card amount');
    }

    if (this.maxMessageLength > 0 && $('#giftcard_message').val().length > this.maxMessageLength) {
        errors.push('Gift card message is too long');
    }

    if ($('#giftcard_sender_name').val().length < 2) {
        errors.push('Sender name is too short');
    }
    if ($('#giftcard_sender_email').val().length < 7) {
        errors.push('Sender email is too short');
    }
    if ($('#giftcard_recipient_name').val().length < 2) {
        errors.push('Recipient name is too short');
    }
    if ($('#giftcard_recipient_email').val().length < 7) {
        errors.push('Recipient email is too short');
    }

    if (errors.length > 0) {
        daz.api.displayErrors(errors);
        return false;
    }

    return true;
    
};

GiftCard.prototype.addToCart = function() {
    if (!this.validate()) {
        return false;
    }

    var form = $('form.giftcard-form');

    $.ajax({
        url: form.attr("action"),
        data: form.serialize(),
        cache: false,
        complete: function(){
            window.location = "/checkout/cart"
        },
    });

    return true;
};
