/**
 * Daz Custom Video Player W / Playlists
 * @param {string} containerSelector Selector where we are going to add all the elements
 * @param {array} playlist Object containing a videos array and playlist info
 * @param {object} options OPTIONAL: Custom Classes to be added to elements 
 * 
    ////////////////////////
    EXAMPLE PLAYLIST OBJECT
    ///////////////////////    

    const playlist = {
        "vids": [
            {   
                //REQUIRED   
                "title": "",
                "desc": "",
                "fileName": {
                    // MUST HAVE AT LEAST ONE
                    "720": "", 
                    "1080": ""
                },

                //OPTIONAL
                "shortTitle": "", // If not provided it will use the whole title
                "posterImg": "", //If not provided it will use the playlist defaultPoster
                "resources": [
                    // LINKS TO ASSOCIATED RESOURCES
                    {"name": "", "href": ""}
                ],
                "timeCodes": [
                    // TIME CODE LINKS
                    {"name": "", "time":""}
                ]
            }
        ],
        "videoRoot": "", //OPTIONAL: Root folder for files both video and posters
        "playlistName": "", //REQUIRED: Used to generate querystring navigation
        "playlistDesc": "", //OPTIONAL: A blurb to go under the course title
        "defaultPoster": "", //OPTIONAL: A fallback when the video doesnt have a provided poster image
        "autoAdvance": true, //REQUIRED: Auto start the next vid?
    }

    ////////////////////////
    EXAMPLE ELEMENT AND CALL
    ///////////////////////    
    <div class="beginner">
    </div>

    const player1 = new DazVidPlayer('.beginner', playlist, {
        // optional options
        playerCustomClasses : '',
        playlistCustomClasses : '';
    });
 */

// TODO: Break the html into a partial

// TODO: Make it possible to use only video and no playlist

// TODO: Separate the styles for the player and master class page

function DazVidPlayer(containerSelector, playlist, options = {}) {
    this.container = document.querySelector(containerSelector)
    this.uniqueId = 'dazVid-' + new Date().getTime();

    this.urlParams = new URLSearchParams(window.location.search);
    this.params = Object.fromEntries(this.urlParams.entries());
    this.hasParams = !!Object.keys(this.params).length > 0;
    this.options = options;
    // Playlist Tracking
    this.playlist = playlist;
    this.currentVideo = 0;
    this.isPlaying = false;
    this.isFirstInSession = true;
    // TODO: Make a quality select 
    this.quality = "1080";
    
    // TODO: Track video progress for analytics
    this.currentVideoProgress = "0%";

    // For analytics
    this.videoStatus = "";

    this.playlistName = this.playlist.playlistName.replace(/\s+/g, '_').toLowerCase();

    // We doing this?
    this.mp4Compat = !document.createElement('video').canPlayType('video/mp4');
    if (this.mp4Compat) {
        // I am a silly browser that can't do simple tasks
        this.container.innerHTML = `<h2>Your browser won't let you see this video. Consider a better one?</h2>`;
        // ABORT
        return;
    }

    this.svgs = [
        { id: 'play-icon', path: `<path d="M8.016 5.016l10.969 6.984-10.969 6.984v-13.969z"></path>` },
        { id: 'pause-icon', path: `<path d="M14.016 5.016h3.984v13.969h-3.984v-13.969zM6 18.984v-13.969h3.984v13.969h-3.984z"></path>` },
        { id: 'vol-up', path: `<path d="M14.016 3.234q3.047 0.656 5.016 3.117t1.969 5.648-1.969 5.648-5.016 3.117v-2.063q2.203-0.656 3.586-2.484t1.383-4.219-1.383-4.219-3.586-2.484v-2.063zM16.5 12q0 2.813-2.484 4.031v-8.063q1.031 0.516 1.758 1.688t0.727 2.344zM3 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6z"></path>` },
        { id: 'vol-down', path: `<path d="M5.016 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6zM18.516 12q0 2.766-2.531 4.031v-8.063q1.031 0.516 1.781 1.711t0.75 2.32z"></path>` },
        { id: 'vol-mute', path: `<path d="M12 3.984v4.219l-2.109-2.109zM4.266 3l16.734 16.734-1.266 1.266-2.063-2.063q-1.547 1.313-3.656 1.828v-2.063q1.172-0.328 2.25-1.172l-4.266-4.266v6.75l-5.016-5.016h-3.984v-6h4.734l-4.734-4.734zM18.984 12q0-2.391-1.383-4.219t-3.586-2.484v-2.063q3.047 0.656 5.016 3.117t1.969 5.648q0 2.203-1.031 4.172l-1.5-1.547q0.516-1.266 0.516-2.625zM16.5 12q0 0.422-0.047 0.609l-2.438-2.438v-2.203q1.031 0.516 1.758 1.688t0.727 2.344z"></path>` },
        { id: 'fullscreen-icon', path: `<path d="M14.016 5.016h4.969v4.969h-1.969v-3h-3v-1.969zM17.016 17.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 9.984v-4.969h4.969v1.969h-3v3h-1.969zM6.984 14.016v3h3v1.969h-4.969v-4.969h1.969z"></path>` },
        { id: 'fullscreen-close-icon', path: `<path d="M15.984 8.016h3v1.969h-4.969v-4.969h1.969v3zM14.016 18.984v-4.969h4.969v1.969h-3v3h-1.969zM8.016 8.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 15.984v-1.969h4.969v4.969h-1.969v-3h-3z"></path>` },
        { id: 'pip', path: `<path d="M21 19.031v-14.063h-18v14.063h18zM23.016 18.984q0 0.797-0.609 1.406t-1.406 0.609h-18q-0.797 0-1.406-0.609t-0.609-1.406v-14.016q0-0.797 0.609-1.383t1.406-0.586h18q0.797 0 1.406 0.586t0.609 1.383v14.016zM18.984 11.016v6h-7.969v-6h7.969z"></path>` }
    ];

    // This will create the SVGs needed for controls
    this.appendSvgs();

    // This appends all the needed elements
    this.appendVideoElements();
    this.appendPlaylistElements();

    this.vidContainer = this.container.querySelector('#' + this.uniqueId);
    this.playlistContainer = this.container.querySelector('#' + this.uniqueId + '-playlist');
    this.descriptionContainer = this.container.querySelector('#' + this.uniqueId + '-description');
    this.titleContainer = this.container.querySelector('#' + this.uniqueId + '-title');
    this.playbackAnim = this.vidContainer.querySelector('.playback-animation');
    this.controls = this.vidContainer.querySelector('.video-controls');
    this.playlistLinks = this.playlistContainer.querySelectorAll('.jump-to-video');
    this.mobilePlaylist = this.container.querySelector('.mobilePlaylist');
    this.video = this.container.querySelector('video');
    // Hide default controls
    this.video.controls = false;

    // Buttons and such
    this.buttons = {
        play: this.vidContainer.getElementsByClassName('play-button')[0],
        volume: this.vidContainer.getElementsByClassName('volume-button')[0],
        fullscreen: this.vidContainer.getElementsByClassName('fullscreen-button')[0],
        pip: this.vidContainer.getElementsByClassName('pip-button')[0]
    };

    // Input watcher
    for (let e of this.vidContainer.querySelectorAll('input[type="range"].slider-progress')) {
        e.style.setProperty('--value', e.value);
        e.style.setProperty('--min', e.min == '' ? '0' : e.min);
        e.style.setProperty('--max', e.max == '' ? '100' : e.max);
        e.addEventListener('input', () => e.style.setProperty('--value', e.value));
    }

    // Icon switching elements
    this.playButtonIcons = this.buttons.play.querySelectorAll('use');
    this.volumeIcons = this.vidContainer.querySelectorAll('.volume-button use');
    this.volumeMute = this.vidContainer.querySelector('use[href="#vol-mute"]');
    this.volumeLow = this.vidContainer.querySelector('use[href="#vol-down"]');
    this.volumeHigh = this.vidContainer.querySelector('use[href="#vol-up"]');
    this.volume = this.vidContainer.querySelector('.volume');

    // Time keeping
    this.timeElapsed = this.vidContainer.getElementsByClassName('time-elapsed')[0];
    this.duration = this.vidContainer.getElementsByClassName('duration')[0];
    this.progressBar = this.vidContainer.querySelector('.video-progress progress');
    this.seek = this.vidContainer.querySelector('.seek');
    this.seekTooltip = this.vidContainer.querySelector('.seek-tooltip');

    // Has query string overrides and the playlist param matches
    if (this.hasParams && this.params.playlist === this.playlistName) {
        // Has a video index passed and it is inbetween the limits of the playlist length
        if (this.params.hasOwnProperty('vid') &&
            parseInt(this.params.vid) >= 0 &&
            parseInt(this.params.vid) < this.playlist.vids.length) {
            this.currentVideo = parseInt(this.params.vid);
        }
    }

    this.initVideo();
    this.addEventListeners();

    // Intersection observer
    // TODO: Figure out why the observer isnt working
    // this.observer = new IntersectionObserver((entries, observer) => {
    //     entries.forEach(entry => {
    //         if (entry.intersectionRatio != 1 && !this.video.paused) {
    //             this.playPause();

    //             // off screen so kill the hotkeys
    //             document.removeEventListener('keyup', this.keyboardShortcuts.bind(this));
    //         }
    //         else {
    //             // In order to make the hotkeys work they have to be on the document... 
    //             // Since there will be multiple players on the page, I am going to 
    //             // bind them only when the player is on screen. 
    //             document.addEventListener('keyup', this.keyboardShortcuts.bind(this));
    //         }
    //     });
    // }, { threshold: 1 });
    // this.observer.observe(this.video);
}

// TODO: Move this to a template
DazVidPlayer.prototype.appendVideoElements = function () {
    let wrap = `<p class="bs5-mt-5 description-title">${this.playlist.playlistName}</p>
    ${this.playlist.playlistDesc ? '<p class="course-desc bs5-mb-5">' + this.playlist.playlistDesc + '</p>' : ''}
    <div id="${this.uniqueId}" class="player-column bs5-col-12 bs5-col-lg-8 bootstrap-gutter-margin ${this.options.playerCustomClasses ? this.options.playerCustomClasses : ''}">
        <div class="row">
            <h4 id="${this.uniqueId}-title"><span class="video-title"></span></h4>
        </div>
        <div class="row">
            <div class="bs5-position-relative daz-video-container">
                <div class="playback-animation is-loading">
                    <button class="big-play">
                        <div class="css-play"></div>
                    </button>
                </div>
                <div class="video-controls">
                    <div class="bottom-controls">
                        <div class="left-controls">
                            <button data-title="Play/Pause" class="play-button">
                                <svg class="playback-icons">
                                    <use href="#play-icon"></use>
                                    <use class="hidden" href="#pause-icon"></use>
                                </svg>
                            </button>
                        </div>
                        <div class="video-progress">
                            <progress value="0" min="0"></progress>
                            <input class="seek" value="0" min="0" type="range" step="1">
                            <div class="seek-tooltip">00:00</div>
                        </div>
                        <div class="time">
                            <time class="time-elapsed">00:00</time>
                            <span> / </span>
                            <time class="duration">00:00</time>
                        </div>
                        <div class="volume-controls">
                            <button data-title="Mute" class="volume-button">
                                <svg>
                                    <use class="hidden" href="#vol-mute"></use>
                                    <use class="hidden" href="#vol-down"></use>
                                    <use href="#vol-up"></use>
                                </svg>
                            </button>
                            <input class="volume styled-slider slider-progress" value="1" data-mute="0.5" type="range" max="1" min="0" step="0.01">
                        </div>
                        <div class="right-controls">
                            <button data-title="PIP" class="pip-button">
                                <svg>
                                    <use href="#pip"></use>
                                </svg>
                            </button>
                            <button data-title="Full screen" class="fullscreen-button">
                                <svg>
                                    <use href="#fullscreen-icon"></use>
                                    <use href="#fullscreen-close-icon" class="hidden"></use>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <video controls class="video daz-video-player" poster="" preload>
                </video>
            </div>
        </div>
        <div class="row">
            <div id="${this.uniqueId}-description" class="bs5-my-3">
                <p class="description-title">Description</p>
                <div class="dynamic-desc bs5-mb-5"></div>
                <div class="video-links bs5-row"></div>
            </div>
        </div>
    </div>`
    this.container.insertAdjacentHTML("afterBegin", wrap)
}

DazVidPlayer.prototype.appendPlaylistElements = function () {
    let vidLinks = '';
    let vidLinksMobile = '';
    for (let i = 0; i < this.playlist.vids.length; i++) {
        const vid = this.playlist.vids[i];
        vidLinks += `<li class="playlist-item"><a class="jump-to-video list-group-item list-group-item-action" href="javascript:" data-vid-index="${i}">${vid.shortTitle ? vid.shortTitle : vid.title}</a></li>`
        vidLinksMobile += `<option value="${i}">Part ${parseInt(i) + 1}: ${vid.shortTitle ? vid.shortTitle : vid.title}</option>`
    }
    const playlistMenu = `<div id="${this.uniqueId}-playlist" class="${this.options.playlistCustomClasses ? this.options.playlistCustomClasses : ""} bs5-ms-0 bs5-ms-lg-3 bs5-p-4 bs5-col bs5-d-none bs5-d-lg-block"><p class="description-title">Chapters</p><ul class="list-group video-list">${vidLinks}</ul></div>`
    const mobileMenu = `<select class="mobilePlaylist bs5-my-4 bs5-mx-0 bs5-form-select bs5-d-block bs5-d-lg-none bs5-col-12" aria-label="Chapter Selection">${vidLinksMobile}</select>`
    this.container.insertAdjacentHTML("beforeend", playlistMenu)
    this.container.querySelector('#' + this.uniqueId + '-description').insertAdjacentHTML("afterbegin", mobileMenu)
}

DazVidPlayer.prototype.appendSvgs = function () {
    if (!document.getElementById('#videoSvg')) {
        let svg = ``;
        this.svgs.forEach(element => {
            svg = svg + `<symbol id="${element.id}" viewBox="0 0 24 24">${element.path}</symbol>`
        });
        document.body.insertAdjacentHTML('beforeend', `<svg style="display:none" id="#videoSvg"><defs>${svg}</defs></svg>`)
    }
}

DazVidPlayer.prototype.initVideo = function () {
    this.playbackAnim.classList.add('is-loading');
    this.mobilePlaylist.value = this.currentVideo;

    // Deal with posters
    if (this.playlist.vids[this.currentVideo].hasOwnProperty('posterImg') && this.playlist.vids[this.currentVideo].posterImg.length !== 0) {
        this.video.setAttribute('poster', this.playlist.videoRoot ? this.playlist.videoRoot + this.playlist.vids[this.currentVideo].posterImg : this.playlist.vids[this.currentVideo].posterImg);
    } else if (this.playlist.hasOwnProperty('defaultPoster') && this.playlist.defaultPoster.length !== 0) {
        this.video.setAttribute('poster', this.playlist.defaultPoster)
    }

    // Generate title
    const title = this.playlist.vids[this.currentVideo].shortTitle ? this.playlist.vids[this.currentVideo].shortTitle : this.playlist.vids[this.currentVideo].title;
    this.titleContainer.querySelector('.video-title').innerHTML = title;

    //Description Time   If not wrapped in a p, wrap it
    this.descriptionContainer.querySelector('.dynamic-desc').innerHTML = (this.playlist.vids[this.currentVideo].desc).substring(0.3) == "<p>" ? this.playlist.vids[this.currentVideo].desc : "<p>" + this.playlist.vids[this.currentVideo].desc + "</p>";
    // Add any resource links to the description
    this.descriptionContainer.querySelector('.video-links').innerHTML = ""
    this.appendTimeCodeLinks();
    this.appendResourceLinks();

    //Video loading
    this.video.src = this.hasValue(this.playlist.videoRoot) ? this.playlist.videoRoot + this.playlist.vids[this.currentVideo].fileName[this.quality] : this.playlist.vids[this.currentVideo].fileName[this.quality];
    this.video.load();

    this.playlistLinks.forEach(link => {
        if (link.getAttribute('data-vid-index') == this.currentVideo) {
            link.classList.add('selected');
        } else {
            link.classList.remove('selected');
        }
    });

    // has query strings
    if (this.hasParams && this.params.playlist === this.playlistName) {
        // Has a timecode
        if (this.params.hasOwnProperty('time')) {
            // now lets do a gross hack to make the data what the timecode function expects
            // which is a click event object
            this.timeCodeLinkHandler({ target: { dataset: { time: this.params.time } } })
        }
    }
}

DazVidPlayer.prototype.appendResourceLinks = function () {
    if (this.playlist.vids[this.currentVideo].hasOwnProperty('resources') && this.playlist.vids[this.currentVideo].resources.length > 0) {
        let resourceLinks = '';
        this.playlist.vids[this.currentVideo].resources.forEach(element => {
            resourceLinks += `<li><a target="_blank" href="${element.href}" class="resource"><span class="underline">${element.name}</span> <span class="no-underline"><i id="da_arrow" class="diag-arrow"></i></span></a></li>`
        });
        this.descriptionContainer.querySelector('.video-links').insertAdjacentHTML('beforeend', `<ul class="resource-links bs5-col-6"><p class="description-title">Resources</p>${resourceLinks}</ul>`)
    }
}

DazVidPlayer.prototype.appendTimeCodeLinks = function () {
    if (this.playlist.vids[this.currentVideo].hasOwnProperty('timeCodes') && this.playlist.vids[this.currentVideo].timeCodes.length > 0) {
        let resourceLinks = '';
        this.playlist.vids[this.currentVideo].timeCodes.forEach(element => {
            resourceLinks += `<li><a href="javascript:" class="timecode-link resource underline" data-time="${element.time}">[<span class="orange">${element.time}</span>] ${element.name}</a></li>`
        });
        this.descriptionContainer.querySelector('.video-links').insertAdjacentHTML('beforeend', `<ul class="timecode-links bs5-col-6"><li><p class="description-title">Jump to time</p></li>${resourceLinks}</ul>`)
    }
    // Now we need to bind an event listener to these 
    this.descriptionContainer.querySelectorAll('.timecode-link').forEach(item => {
        item.addEventListener('click', this.timeCodeLinkHandler.bind(this))
    })

}
// END LOADING BITS

// Playing state toggling
DazVidPlayer.prototype.playPause = function () {
    if (!this.isPlaying) {
        this.video.play();
        this.playButtonIcons[0].classList.add('hidden')
        this.playButtonIcons[1].classList.remove('hidden')
        this.playbackAnim.classList.add('out')
    } else {
        this.video.pause();
        this.playButtonIcons[0].classList.remove('hidden')
        this.playButtonIcons[1].classList.add('hidden')
        this.playbackAnim.classList.remove('out')
    }
    this.isPlaying = !this.isPlaying;
    this.videoStatus = this.isPlaying ? "start" : "pause";
    this.pushToDataLayer();
}

// Time related bits
DazVidPlayer.prototype.setDuration = function () {
    const videoDuration = Math.round(this.video.duration);
    const time = this.formatTime(videoDuration);
    this.duration.innerText = `${time.minutes}:${time.seconds}`;
    this.duration.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`);
    this.seek.setAttribute('max', videoDuration);
    this.progressBar.setAttribute('max', videoDuration);
}

DazVidPlayer.prototype.updateTimeElapsed = function () {
    const time = this.formatTime(Math.round(this.video.currentTime));
    this.timeElapsed.innerText = `${time.minutes}:${time.seconds}`;
    this.timeElapsed.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`);
    this.seek.value = Math.floor(this.video.currentTime);
    this.progressBar.value = Math.floor(this.video.currentTime);
}

DazVidPlayer.prototype.formatTime = function (timeInSeconds) {
    const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);
    // Turns out some of these videos are over an hour... so this is to convert to minutes.
    const hours = parseInt(result.substr(1, 2));
    let mins = parseInt(result.substr(3, 2)) + hours * 60;
    return {
        minutes: mins,
        seconds: result.substr(6, 2),
    };
}

DazVidPlayer.prototype.updateTimeTooltip = function (event) {
    const skipTo = Math.round((event.offsetX / event.target.clientWidth) * parseInt(event.target.getAttribute('max')), 10);
    this.seek.setAttribute('data-seek', skipTo)
    const t = this.formatTime(skipTo);
    this.seekTooltip.textContent = `${t.minutes}:${t.seconds}`;
    const rect = this.video.getBoundingClientRect();
    this.seekTooltip.style.left = `${event.pageX - rect.left}px`;
}

DazVidPlayer.prototype.skipAhead = function (event) {
    const skipTo = event.target.dataset.seek ? event.target.dataset.seek : event.target.value;
    this.video.currentTime = skipTo;
    this.progressBar.value = skipTo;
    this.seek.value = skipTo;
}

DazVidPlayer.prototype.timeCodeLinkHandler = function (event) {
    let timecode = event.target.dataset.time.split(':');
    let skipTo;
    if (timecode.length === 1) {
        skipTo = timecode;
    } else if (timecode.length === 2) {
        // Minutes to seconds
        skipTo = (parseInt(timecode[0] * 60)) + parseInt(timecode[1]);
    } else if (timecode.length = 3) {
        // In case of over an hour
        skipTo = (parseInt(timecode[0] * 60)) + (parseInt(timecode[1] * 60)) + parseInt(timecode[2]);
    }
    this.video.currentTime = skipTo;
    this.progressBar.value = skipTo;
    this.seek.value = skipTo;
}
// END TIME BITS

// Volume related bits
DazVidPlayer.prototype.updateVolume = function () {
    if (this.video.muted) {
        this.video.muted = false;
    }
    this.video.volume = this.volume.value;
}

DazVidPlayer.prototype.volumeIconSwitching = function () {
    this.volumeIcons.forEach(icon => {
        icon.classList.add('hidden');
    });
    this.buttons.volume.setAttribute('data-title', 'Mute')
    if (this.video.muted || this.video.volume === 0) {
        this.volumeMute.classList.remove('hidden');
        this.buttons.volume.setAttribute('data-title', 'Unmute')
    } else if (this.video.volume > 0 && this.video.volume <= 0.5) {
        this.volumeLow.classList.remove('hidden');
    } else {
        this.volumeHigh.classList.remove('hidden');
    }
}

DazVidPlayer.prototype.toggleMute = function () {
    this.video.muted = !this.video.muted;
    if (this.video.muted) {
        this.buttons.volume.setAttribute('data-volume', this.buttons.volume.value);
        this.buttons.volume.value = 0;
    } else {
        this.buttons.volume.value = this.buttons.volume.dataset.volume;
    }
}
// END VOLUME

// Screen size related
DazVidPlayer.prototype.toggleFullScreen = function () {
    if (document.fullscreenElement) {
        document.exitFullscreen();
    } else if (document.webkitFullscreenElement) {
        // Need this to support Safari
        document.webkitExitFullscreen();
    } else if (this.vidContainer.webkitRequestFullscreen) {
        // Need this to support Safari
        this.vidContainer.webkitRequestFullscreen();
    } else {
        this.vidContainer.requestFullscreen();
    }
}

DazVidPlayer.prototype.togglePip = async function () {
    try {
        if (this.video !== document.pictureInPictureElement) {
            this.buttons.pip.disabled = true;
            await this.video.requestPictureInPicture();
        } else {
            await document.exitPictureInPicture();
        }
    } catch (error) {
        console.error(error)
    } finally {
        this.buttons.pip.disabled = false;
    }
}
// END SCREEN

// QOL bits
DazVidPlayer.prototype.hideControls = function () {
    if (this.video.paused) {
        return;
    }
    this.controls.classList.add('off-canvas');
}

DazVidPlayer.prototype.showControls = function () {
    this.controls.classList.remove('off-canvas');
}

DazVidPlayer.prototype.hasValue = function (property) {
    if (property && property.length !== 0) {
        return true
    } else {
        return false
    }
}

// Todo: Hook this up with a intersection observer so the hotkeys only work for the player on screen
DazVidPlayer.prototype.keyboardShortcuts = function (event) {
    const { key } = event;
    switch (key) {
        case 'k':
            this.playPause();
            if (this.video.paused) {
                this.showControls();
            } else {
                setTimeout(() => {
                    this.hideControls();
                }, 2000);
            }
            break;
        case 'm':
            this.toggleMute();
            break;
        case 'f':
            this.toggleFullScreen();
            break;
        case 'p':
            this.togglePip();
            break;
    }
}

DazVidPlayer.prototype.videoChange = function (event) {
    let changeTo = '';
    if (event.target.getAttribute('data-vid-index')) {
        // I am from the big playlist
        changeTo = event.target.getAttribute('data-vid-index');
    } else {
        // I am from the mobile playlist
        changeTo = this.mobilePlaylist.value;
    }
    this.currentVideo = changeTo;
    this.initVideo();
}

DazVidPlayer.prototype.pushToDataLayer = function () {
    if (daz.help) {
        daz.help.gtmTrigger({
            "event": 'dazCourseVideo',
            "gtm.videoStatus": this.status,
            "gtm.videoUrl": this.playlist.vids[this.currentVideo].fileName[this.quality],
            "gtm.videoTitle": this.playlist.vids[this.currentVideo].title,
            "gtm.courseTitle": this.playlistName
        })
    }
}

// ALL THE LISTENERS
DazVidPlayer.prototype.addEventListeners = function () {
    // Play/Pause events
    this.buttons.play.addEventListener('click', this.playPause.bind(this));
    this.video.addEventListener('click', this.playPause.bind(this));

    // Playlist
    this.playlistContainer.querySelectorAll('.playlist-item').forEach(item => {
        item.addEventListener('click', this.videoChange.bind(this))
    })
    this.mobilePlaylist.addEventListener('change', this.videoChange.bind(this))

    // Elapsed and Progress
    this.video.addEventListener('timeupdate', this.updateTimeElapsed.bind(this));
    this.seek.addEventListener('mousemove', this.updateTimeTooltip.bind(this));
    this.seek.addEventListener('input', this.skipAhead.bind(this));

    // Volume
    this.volume.addEventListener('input', this.updateVolume.bind(this));
    this.video.addEventListener('volumechange', this.volumeIconSwitching.bind(this));
    this.buttons.volume.addEventListener('click', this.toggleMute.bind(this));

    // Screen size
    this.buttons.fullscreen.addEventListener('click', this.toggleFullScreen.bind(this))
    this.buttons.pip.addEventListener('click', this.togglePip.bind(this));
    document.addEventListener('DOMContentLoaded', () => {
        if (!('pictureInPictureEnabled' in document)) {
            this.buttons.pip.classList.add('hidden');
        }
    });

    // Video Element
    this.video.addEventListener("canplay", () => {
        this.playbackAnim.classList.remove('is-loading')
        if (!this.isFirstInSession) {
            this.video.play();
        }
        this.videoStatus = 'loaded';
        this.pushToDataLayer();
    });
    this.video.addEventListener('loadeddata', this.setDuration.bind(this));
    // Auto load next?
    this.video.addEventListener("ended", () => {
        this.videoStatus = 'complete'
        this.pushToDataLayer()
        this.isFirstInSession = false;
        if (this.playlist.autoAdvance) {
            this.currentVideo++;
            if (this.currentVideo >= this.playlist.length) {
                this.currentVideo = 0;
                return
            }
            this.initVideo();
        }
    });

    // QOL
    this.video.addEventListener('mouseenter', this.showControls.bind(this));
    this.video.addEventListener('mouseleave', this.hideControls.bind(this));
    this.controls.addEventListener('mouseenter', this.showControls.bind(this));
    this.controls.addEventListener('mouseleave', this.hideControls.bind(this));
}