const errors = {
    firstname: [],
    lastname: [],
    email: [],
    password: [],
    tos: []
}

$(document).ready(() => {
    if (document.querySelector('form[name="new-user-registration"]')) {
        const registerForms = document.querySelectorAll('form[name="new-user-registration"]');
        registerForms.forEach(registerForm => {
            const firstNameInput = registerForm.querySelector('[name="firstname"]');
            const lastNameInput = registerForm.querySelector('[name="lastname"]');
            const emailInput = registerForm.querySelector('[name="email"]');
            const passwordInput = registerForm.querySelector('[name="password"]');
            const inputsArr = [firstNameInput, lastNameInput, emailInput, passwordInput];

            const tosCheckbox = registerForm.querySelector('[name="eula"]');

            const submitBtn = registerForm.querySelector('#register-submit');

            // prevent user from typing spaces in email field
            emailInput.addEventListener('keydown', key => {
                if (key.keyCode === 32) key.preventDefault();
            })

            // validation: takes in an individual input field or all input fields
            function validate(inputArr = inputsArr) {
                // disable submit button until validation passed
                submitBtn.setAttribute('disabled', 'true')
                submitBtn.style.opacity = '.5';
                submitBtn.style.cursor = 'not-allowed';

                // handle input fields
                inputArr.forEach(input => {
                    const inputName = input.getAttribute('name');

                    // reset related errors to build updated list
                    errors[inputName] = [];

                    // check for entered text
                    if (!input.value.trim()) {
                        errors[inputName].push('Please fill out this field')
                    } else {
                        // check email for proper format
                        // just checking for something before and after @ symbol
                        if (input === emailInput) {
                            const emailArr = input.value.split('@');

                            if (emailArr.length === 2) {
                                for (let i = 0; i < emailArr.length; i++) {
                                    if (emailArr[i].trim().length === 0) {
                                        errors.email.push('Please enter a valid email')
                                        break;
                                    }
                                }
                            } else {
                                errors.email.push('Please enter a valid email')
                            }
                        }

                        // check password for min length of 7
                        if (input === passwordInput && input.value.length < 7) {
                            errors.password.push('Password must be at least 7 characters')
                        }
                    }
                })

                // enable submit button if no input errors
                let errorsFound = false;
                for (let i = 0; i < Object.values(errors).length; i++) {
                    if (Object.values(errors)[i].length > 0) {
                        errorsFound = true;
                        break;
                    }
                }

                if (errorsFound === false) {
                    submitBtn.removeAttribute('disabled')
                    submitBtn.style.opacity = '1';
                    submitBtn.style.cursor = 'pointer';

                    // for if ran by form submit action
                    return true;
                } else {
                    // for if ran by form submit action
                    return false;
                }
            }

            function showInputErrs(inputArr = inputsArr) {
                inputArr.forEach(input => {
                    const inputName = input.getAttribute('name');
                    const inputLabel = input.parentElement.querySelector('label');

                    // remove previous error messages shown
                    if (document.querySelector(`#err-${inputName}`)) {
                        document.querySelector(`#err-${inputName}`).remove()
                        inputLabel.setAttribute('style', 'color: #fff;')
                    }

                    if (errors[inputName].length > 0) {
                        // change input label text to red
                        inputLabel.setAttribute('style', 'color: red;')

                        // build error message elements
                        const errContainer = document.createElement('div');
                        errContainer.setAttribute('id', `err-${inputName}`)
                        errContainer.classList.add('validation-wrap')

                        errors[inputName].forEach(errStr => {
                            const errMsg = document.createElement('p');
                            errMsg.classList.add('validation-error')
                            errMsg.innerText = errStr;
                            errContainer.appendChild(errMsg)
                        })

                        // show error messages in form
                        input.parentElement.appendChild(errContainer)
                    }
                })
            }

            // listen for input field changes
            // validate individual field after time in ms and on blur
            const validateWaitMs = 1000;
            let validateTimer;

            inputsArr.forEach(input => {
                input.addEventListener('keyup', () => {
                    validate([input])

                    validateTimer = setTimeout(() => showInputErrs([input]), validateWaitMs)
                })

                input.addEventListener('keydown', () => {
                    validate([input])

                    clearTimeout(validateTimer)
                })

                input.addEventListener('blur', () => showInputErrs([input]))
            })

            // remove error message about tos if user checks the box
            tosCheckbox.addEventListener('change', () => {
                const tosErrMsg = registerForm.querySelector('#err-tos');

                if (tosCheckbox.checked && tosErrMsg) {
                    tosErrMsg.remove()
                }
            })

            // if user tries to submit without agreeing to tos, show message
            submitBtn.addEventListener('click', () => {
                const tosErrMsg = registerForm.querySelector('#err-tos');

                // add error msg if it's not already there
                if (!tosCheckbox.checked && !tosErrMsg) {
                    const tosCheckboxParent = tosCheckbox.parentElement;

                    const errMsg = document.createElement('p');
                    errMsg.setAttribute('id', 'err-tos')
                    errMsg.classList.add('validation-error')
                    errMsg.innerText = 'You must check the box above ';

                    const span = document.createElement('span');
                    span.classList.add('bs5-text-nowrap')
                    span.innerText = 'to continue.';

                    errMsg.appendChild(span)
                    tosCheckboxParent.appendChild(errMsg);
                }
            })

            // listen for form submit and validate user input
            registerForm.addEventListener('submit', (e) => {
                const validated = validate();

                // if validation fails, don't submit form
                if (!validated) {
                    e.preventDefault()
                    showInputErrs()
                } else {
                    e.preventDefault()
                    // Let the user know we're working on it
                    submitBtn.setAttribute('disabled', 'true')
                    submitBtn.style.opacity = '.5';
                    submitBtn.style.cursor = 'not-allowed';
                    submitBtn.innerText = 'Please wait...'
                    // append the loading spinner
                    const spinner = `<div class="fixed loading"><img src="/static/images/loading.gif"  alt="loading"/><div>Loading...</div></div>`;
                    document.body.insertAdjacentHTML('beforeend', spinner);
                    const action = registerForm.getAttribute('action');
                    const formData = new FormData(registerForm);
                    var popups = JSON.parse(localStorage.getItem("Popups"))
                    if (popups) {
                        formData.append('popupEmail', popups.popupEmail)
                    }
                    formData.append("surl", document.location.pathname)
                    fetch(action, {
                        method: 'POST',
                        body: formData
                    }).then(response => {
                        response.json().then(data => {
                            if (data.error) {
                                // remove the loading spinner
                                document.querySelector('.fixed.loading').remove();
                                // re-enable the submit button
                                submitBtn.removeAttribute('disabled')
                                submitBtn.style.opacity = '1';
                                submitBtn.style.cursor = 'pointer';
                                submitBtn.innerText = 'Download Daz Studio';
                                
                                daz.api.displayErrors([data.error])
                            } else if (data.redir_url) {
                                let goto = new URL(data.redir_url, document.location.href);
                                let qString = new URLSearchParams(window.location.search);

                                // add current query string to redirect url
                                if (qString) {
                                    qString.forEach((value, key) => {
                                        goto.searchParams.set(key, value);
                                    });
                                }
                                localStorage.removeItem("Popups")
                                window.location.href = goto.toString()
                            } else {
                                localStorage.removeItem("Popups")
                            }

                        })
                    });
                }
            })

            // validate form on page load in case user had input info and left page before submitting
            validate()
        })
    }
})
