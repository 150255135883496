DazCatalog.prototype = new DazProductList();

function DazCatalog() {
    DazProductList.call(this);
};

/*** INIT FUNCTIONS ***/
DazCatalog.prototype.init = function(rawCatalogData) {
    var listElem = $('#slabs-container');
    if (!listElem || !listElem[0]) {
        return;
    }

    DazProductList.prototype.init.call(this, listElem, rawCatalogData);
};

