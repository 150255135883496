function ListrakHelper() {
}

ListrakHelper.history = {
    'BrowseProduct' : [],
    'AddToCart' : {}
}

ListrakHelper.prototype.initListrak = function(){
    (function (d, tid, vid) {
        if (typeof _ltk != 'undefined') return;
        var js = d.createElement('script');
        js.id = 'ltkSDK';
        js.src = "https://cdn.listrakbi.com/scripts/script.js?m=" + tid + "&v=" + vid;
        d.querySelector('head').appendChild(js);
    })(document, '3N8jP0RCyiWw', '1');
}

// Helper function to standardize both slab imageUrls and cartProduct imageUrls for Listrak
var standardizeImageUrl = (imageUrl) => {
    let StorageBaseURL = "https://gcdn.daz3d.com";

    // if product.imageUrl begins with mconfig.ImageSizeCGI (e.g. "/cdn-cgi") + "/image/width=380,height=494,fit=cover/", remove it
    if(imageUrl.startsWith('/')){
        // Use regex to find the first slash after "fit="
        let slashIndex = imageUrl.indexOf('/', imageUrl.indexOf('fit=') + 4);
        imageUrl = imageUrl.substring(slashIndex + 1);
    }
    // if product.imageUrl begins with "p/", prefix with mconfig.StorageBaseURL + "/"
    else if(imageUrl.startsWith('p/')){
        imageUrl = StorageBaseURL + '/' + imageUrl
    }
    return imageUrl
}

ListrakHelper.prototype.cartChange = function(name, data){
    if(name === 'add_cart'){
        (function(d){if(typeof _ltk=="undefined"){if(document.addEventListener)document.addEventListener("ltkAsyncListener",function(){_ltk_util.ready(d)});else{e=document.documentElement;e.ltkAsyncProperty=0;e.attachEvent("onpropertychange",function(e){if(e.propertyName=="ltkAsyncProperty"){_ltk_util.ready(d)}})}}else{_ltk_util.ready(d)}})(function() {

            let products = data.products
            for(let i=0; i < products.length; i++){
                let product = products[i];
                let imageUrl = standardizeImageUrl(product.imageUrl);
                _ltk.SCA.AddItemWithLinks(product.id, 1, product.salePrice, product.name, imageUrl, product.url);
            }
            _ltk.SCA.Submit();
            _ltk.Activity.AddProductAddedToCart(data.id);
        })
    }
    else if(name === 'remove_cart'){
        (function(d){if(typeof _ltk=="undefined"){if(document.addEventListener)document.addEventListener("ltkAsyncListener",function(){_ltk_util.ready(d)});else{e=document.documentElement;e.ltkAsyncProperty=0;e.attachEvent("onpropertychange",function(e){if(e.propertyName=="ltkAsyncProperty"){_ltk_util.ready(d)}})}}else{_ltk_util.ready(d)}})(function() {
            let products = data.products
            for(let i=0; i < products.length; i++){
                let product = products[i];
                let imageUrl = standardizeImageUrl(product.imageurl);
                _ltk.SCA.AddItemWithLinks(product.id, 1, product.price / 100, product.name, imageUrl);
            }
            _ltk.SCA.Submit();
        })
    }
    else if(name === 'clear_cart'){
        (function(d){if(typeof _ltk=="undefined"){if(document.addEventListener)document.addEventListener("ltkAsyncListener",function(){_ltk_util.ready(d)});else{e=document.documentElement;e.ltkAsyncProperty=0;e.attachEvent("onpropertychange",function(e){if(e.propertyName=="ltkAsyncProperty"){_ltk_util.ready(d)}})}}else{_ltk_util.ready(d)}})(function() {
            _ltk.SCA.ClearCart();
        })
    }
}

ListrakHelper.prototype.storeCartChange = function(data){
    let history = daz.api.storageGet('Listrak/save')
    if(!history){
        history = ListrakHelper.history
    }
    history.AddToCart = data
    daz.api.storagePut('Listrak/save', history);
}

ListrakHelper.prototype.browseProduct = function(productId){
    (function (d) {if(typeof _ltk=="undefined"){if(document.addEventListener)document.addEventListener("ltkAsyncListener",function(){_ltk_util.ready(d)});else{e=document.documentElement;e.ltkAsyncProperty=0;e.attachEvent("onpropertychange",function(e){if(e.propertyName=="ltkAsyncProperty"){_ltk_util.ready(d)}})}}else{_ltk_util.ready(d)}})(function(){
        _ltk.Activity.AddProductBrowse(productId);
    })
}

ListrakHelper.prototype.storeBrowseProduct = function(productId){
    let history = daz.api.storageGet('Listrak/save')
    if(!history){
        history = ListrakHelper.history
    }
    history.BrowseProduct.push(productId)
    daz.api.storagePut('Listrak/save', history);
}

ListrakHelper.prototype.sendStorage = function(){
    let history = daz.api.storageGet('Listrak/save')
    if(history && history.BrowseProduct.length > 0){
        history.BrowseProduct.forEach(function(id){
            ListrakHelper.prototype.browseProduct(id)
        })
    }
    if(history && Object.keys(history.AddToCart).length > 0){
        ListrakHelper.prototype.cartChange('add_cart', history.AddToCart)
    }
    localStorage.removeItem('Listrak/save')
}

daz.listrak = new ListrakHelper()