function DazDisplay(filters, sort, type, size) {
	this.sort = sort || 'manual';
	this.displayType = type || 'slab';
	this.displaySize = size || 'small';

	this.rawFilters = filters;
	this.displayFilters = [];
	this.products = [];
	this.sortField = 'popular';
	this.sortDir = 'DESC';

	$('#display_sort_drop').find('li').on('click', $.proxy(this.changeDisplay, this));

	daz.filter.onLoad(this, this.filterDisplayResults);
}

DazDisplay.prototype.changeDisplay = function(event) {
	if (!$(event.currentTarget).attr('data-sortdata')) {
		return;
	}
	var targetLi = $(event.currentTarget);
	this.sortField = targetLi.attr('data-sortdata');
	this.sortDir = (targetLi.attr('data-sortdir')==-1)?'ASC':'DESC';
	$('#display_sort_label').text(targetLi.text());
	targetLi.trigger('daztoggle-hide');

	this.renderFilters();
};

DazDisplay.prototype.filterDisplayResults = function() {
	var idx, filtr = { };

	for (var id in this.rawFilters) {
		if (this.rawFilters.hasOwnProperty(id)) {
			this.displayFilters.push(this.rawFilters[id]);
			idx = this.displayFilters.length - 1;

			this.displayFilters[idx].id = id;
			this.displayFilters[idx].products = true;

			filtr = $.extend({}, this.rawFilters[id].options);

			// the extra filter options get cleaned out in the daz.filter
			this.displayFilters[idx].products = daz.filter.filterProducts(true, filtr);

			// merge into full products array
			Array.prototype.push.apply(this.products, this.displayFilters[idx].products);
		}
	}

	// array unique
	this.products = this.products.reverse( ).filter(function (e, i, arr) {
		return (-1 === arr.indexOf(e, i + 1));
	}).reverse( );

	this.renderFilters();
};

DazDisplay.prototype.renderFilters = function() {
	var i;

	// Sort the products so we can display the most popular results first
	this.products = daz.filter.sortProducts(this.products, this.sortField, this.sortDir);

	for (i = 0; i < this.displayFilters.length; i++) {
		this.displayFilters[i].products = daz.filter.sortProducts(this.displayFilters[i].products, this.sortField, this.sortDir);
	}

	if ('auto' === this.sort) {
		// Sort the filters so that the filters with the most popular items are first
		var self = this;
		var getProdIndex = function (prodId) {
			for (var i = 0; i < self.products.length; i++) {
				if (self.products[i] == prodId) {
					return i;
				}
			}

			return 9999999;
		};

		this.displayFilters.sort(function (a, b) {
			if (getProdIndex(a.products[0]) > getProdIndex(b.products[0])) {
				return 1;
			}
			else {
				return -1;
			}
		});
	}

	if ('carousel' === this.displayType) {
		var carouselData = [];

		// Now add the html for the carousels
		for (i = 0; i < this.displayFilters.length; i += 1) {
			carouselData.push({
				filterId: this.displayFilters[i].id,
				name: this.displayFilters[i].title,
				productList: this.displayFilters[i].products.slice(0, 30).join(','),
				fullProductList: this.displayFilters[i].products.join(','),
				productLength: this.displayFilters[i].products.length,
				// only switch to full product list if only one filter group and less than 30 products
				expanded: ((1 === this.displayFilters.length) && (30 >= this.displayFilters[i].products.length)),
				idx: i
			});
		}

		if (1 === this.displayFilters.length) {
			$('.display-title .display-expand').hide();
		}
		else {
			$('.display-title .display-expand').on('click', $.proxy(this.expandFullDisplay, this));
		}

		$('#display_results')
			.html(Templates.displayCarousels.render({carousels: carouselData}))
			.find('.display-expand').on('click', $.proxy(this.expandDisplay, this));

		window.setTimeout(function () {
			CarouselBase.initCarousels($('#display_results'));
		}, 1);
	}
	else {
		$('.display-title .display-expand').hide();
		this.showProductList('', null, false);
	}
};

DazDisplay.prototype.expandDisplay = function(event) {
	event.preventDefault();

	var $container = $(event.target).closest('h3');

	var filterId = $container.attr('data-filterid');

	this.showProductList($container.html(), filterId);
};

DazDisplay.prototype.expandFullDisplay = function(event) {
	event.preventDefault();

	this.showProductList('All Display Results', null);
};

DazDisplay.prototype.showProductList = function(title, filterId, scroll) {
	title = title || '';
	if ('undefined' === typeof scroll) {
		scroll = true;
	}
	scroll = !! scroll;

	if (filterId != null) {
		var filtr = $.extend({}, this.rawFilters[filterId].options);

		// the extra filter options get cleaned out in the daz.filter
		var productList = daz.filter.filterProducts(this.products, filtr);
	} else {
		productList = this.products.slice(0);
	}

	productList = daz.filter.sortProducts(productList, this.sortField, this.sortDir);

	$('#display_details').show()
		.find('h2').html(title);

	if (!daz.displayList) {
		daz.displayList = new DazDisplayList();
		daz.displayList.init($('#display_details_list'), productList);
	} else {
		daz.displayList.productList = productList.slice(0);
		daz.displayList.reinit();
	}

	if (scroll) {
		window.setTimeout(function () {
			$('html, body').animate({
				scrollTop: $("#display_details").offset().top - $("#crumbs_clone").outerHeight()
			}, 1000);
		}, 50);
	}
};

DazDisplayList.prototype = Object.create(DazProductList.prototype);

function DazDisplayList() {
	DazProductList.call(this);
}

DazDisplayList.prototype.reinit = function() {
	for (var filterType in daz.displayList.filterData) {
		daz.displayList.filterData[filterType].sorted = false;
	}
	DazProductList.prototype.reinit.call(this);
};

DazDisplayList.prototype.handleClick = function(event) {
	// Default one does all sorts of stuff to mess with the hash,
	// we don't care that much.
};
