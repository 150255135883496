DazWishlistDisplay.prototype = new DazProductList();

function DazWishlistDisplay(comments) {
    DazProductList.call(this);

    this.slabholder = Templates.slabholder;
    this.slab = Templates.slab;

    this.commentList = comments;
    this.hasWishlistData = false;
}

/*** INIT FUNCTIONS ***/
DazWishlistDisplay.prototype.init = function (callData, data, isCache) {
    let listElem = $('ul.wishlist-grid');
    if (!listElem || !listElem[0]) {
        //console.log("Cannot find wishlist grid");
        return;
    }

    let productList = data.wishlistItems.slice(0);

    $('.clear-all-wishlist-confirm').on('click', () => {
        this.removeAll(productList)
    })

    listElem.off('click');

    DazProductList.prototype.init.call(this, listElem, productList);
};

DazWishlistDisplay.prototype.loadProducts = function (productIds) {
    for (i in productIds) {
        let elemSelector = '#product-' + i;
        let comment = null;
        if (i in this.commentList) {
            comment = this.commentList[i];
        }
        let slab = new DazProductSlab({id: i, comment: comment}, this.slab, elemSelector);
        slab.load();
    }
};

DazWishlistDisplay.prototype.setActive = function (productId) {
    this.listElem.find('#product-' + productId).removeClass('removed');
    this.listElem.find('#product-' + productId + ' .wishlist-add').hide();
    this.listElem.find('#product-' + productId + ' .wishlist-remove').show();
};

DazWishlistDisplay.prototype.setInactive = function (productId) {
    this.listElem.find('#product-' + productId).addClass('removed');
    this.listElem.find('#product-' + productId + ' .wishlist-add').show();
    this.listElem.find('#product-' + productId + ' .wishlist-remove').hide();
};

DazWishlistDisplay.prototype.add = function (productId) {
    this.setActive(productId);

    $.ajax(daz.config.flex_url + 'dazstatic/Wishlist/add/product/' + productId, {
        context: this,
        success: this.postAdd,
    });
};

DazWishlistDisplay.prototype.postAdd = function (ajaxData) {
    if (ajaxData.success != true) {
        daz.api.displayErrors([ajaxData.message]);
    }
};

DazWishlistDisplay.prototype.remove = function (productId) {
    this.setInactive(productId);

    $.ajax(daz.config.flex_url + 'dazstatic/Wishlist/remove/product/' + productId, {
        context: this,
        success: this.postRemove,
    });
};

DazWishlistDisplay.prototype.removeAll = function (productIdList) {
    for (let id of productIdList) {
        this.remove(id)
    }
};

DazWishlistDisplay.prototype.postRemove = function (ajaxData) {
    if (ajaxData.success != true) {
        daz.api.displayErrors([ajaxData.message]);
    }
};
