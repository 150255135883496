function DazPlaceholder( api ) {
    this.api = api;
};

DazPlaceholder.prototype.init = function() {
    var elems = $('.static-placeholder');

    for (var i = 0; i < elems.length ; i++) {
        this.addCallbackForElem(elems[i]);
    }
};

DazPlaceholder.prototype.addCallbackForElem = function(element) {
    var apiCallName = element.getAttribute('data-apicall');
    var apiFunc = apiCallName.toLowerCase().replace('/','_');
    var callData = {
        callbackClass: this,
        callbackFunc: this.replacePlaceholder,
        apiFunc: apiFunc, 
        elem: element,
        template: element.getAttribute('data-apitemplate')
    };

    var addCall = true;

    if (typeof this[(apiFunc+'_before')] == 'function') {
        addCall = this[(apiFunc+'_before')](callData);
    }

    if (addCall === false) {
        // This placeholder call doesn't want to run
        return;
    }

    this.api.addCall(apiCallName, callData);

};

DazPlaceholder.prototype.replacePlaceholder = function(callData, data, isCache) {
    // Handle template replacement
    var shouldReplace = true;
    
    if (typeof this[(callData.apiFunc+'_after')] == 'function') {
        shouldReplace = this[(callData.apiFunc+'_after')](callData, data, isCache);
    }
    if (shouldReplace === false) {
        // This ph doesn't want templating to be run
        return;
    }

    if (!Templates[callData.template]) {
        //console.log("[ERR] Could not find template: "+callData.template);
        return;

    }
    
    data.config = daz.config;
    
    $(callData.elem).html(Templates[callData.template].render(data));
};
