
;(function($, doc) {
	"use strict";

    var preventChangeAction = false;

    $(doc).ready(function() {

        $('input[name="licenses[]"]:checkbox').on('change', function (evt) {

            if (preventChangeAction) {
                return;
            }

            preventChangeAction = true;

            var $this = $(this);
            var val = $this.prop('checked');
            if (val) {
                daz.cart.addLicenseType($this.attr('value'));
            }
            else {
               daz.cart.removeLicenseType($this.attr('value'))
            }

            if (daz.cart.purchased || daz.cart.in_cart) {
                if (daz.cart.licenses.length) {
                    $('.product-shop button[class|="btn"]').css('display', 'none');
                    $('.product-shop button.btn-license').css('display', '');
                    $('.primary_info button[class|="btn"]').css('display', 'none');
                    $('.primary_info button.btn-license').css('display', '');
                    $('.product-shop .sales_info').css('display','none');
                    $('.product-shop .lisence_title').css('display','');
                    $('.primary_info .sales_info').css('display','none');
                    $('.primary_info .lisence_title').css('display','');
                }
                else {
                    $('.product-shop button[class|="btn"]').css('display', 'none');
                    $('.primary_info button[class|="btn"]').css('display', 'none');
                    $('.product-shop .sales_info').css('display','');
                    $('.primary_info .sales_info').css('display','');

                    if (daz.cart.purchased) {
                        $('.product-shop button.btn-purchased').css('display', '');
                        $('.primary_info button.btn-purchased').css('display', '');
                        $('.product-shop .sales_info').css('display','');
                        $('.primary_info .sales_info').css('display','');
                        $('.product-shop .lisence_title').css('display','none');
                        $('.primary_info .lisence_title').css('display','none');
                    }
                    else {
                        $('.product-shop button.btn-in-cart').css('display', '');
                        $('.primary_info button.btn-in-cart').css('display', '');
                    }
                }
            }

            // propagate this selection to all other checkboxes
            $('input[name="licenses[]"][value=' + $this.attr('value') + ']:checkbox').prop('checked', val);

            setTimeout(function () {
                preventChangeAction = false;
            }, 0);

        }).first().trigger("change");

    });

}(jQuery, document));
