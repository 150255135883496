function DazSearch() {
    this.productData = {};
    this.displayedProducts = {}
    this.rawProducts = [];
    this.products = [];
    this.paginationData = {
        MaxPerPage: 60,
        CurrentPage: 1,
        NofResults: "Loading"

    };
    this.loadFacets([]);
    this.sortField = 'search';
    this.sortDir = 'DESC';
    this.searchQuery = this.getSearchParameter();
    $('#search_sort_drop li').on('click', $.proxy(this.changeSearch, this));
    $('.js-search-query').text(this.searchQuery);
    daz.filter.onLoad(this, this.filterSearchResults);
    $(document).ready(function () {
        daz.searchList = new DazSearchList();
        daz.searchList.init($('#search_details_list'), []);
        daz.searchList.pageSize = 60;
        daz.searchList.currPage = 1;
        daz.searchList.loadPage(0)
    })

}

DazSearch.prototype.getSearchParameter = function () {
    var r = new RegExp('[\\?&]q=([^&#]*)');
    var results = r.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

DazSearch.prototype.loadFacets = function (facets) {
   var loadedFacets = {};

   for (var i in facets) {
       var hawkType = facets[i].Field;
       var filterType = hawkType;
       switch (hawkType) {
           case 'figures':
               filterType = 'compat_figures'
               break;
           case 'software':
               filterType = 'compat_software'

       }
       loadedFacets[filterType] = {};

       for (var j in facets[i].Values) {
           var item = facets[i].Values[j];
           loadedFacets[filterType][item.Value] = item;
       }
   }

   this.facets = loadedFacets;
};


DazSearch.prototype.loadProducts = function (products) {
    this.productData = {};
    this.displayedProducts = {}
    this.rawProducts = products;
    this.products = [];

    for (var i = 0; i < products.length; i++) {
        if (products[i] == null || products[i][0] == 0) {
            continue;
        }
        this.productData[products[i][0]] = {id: products[i][0], price: products[i][1]};
        this.products.push(products[i][0]);
    }
};

DazSearch.prototype.changeSearch = function (event) {
    var targetLi = $(event.currentTarget);
    this.sortField = targetLi.attr('data-sortdata');
    targetLi.trigger('daztoggle-hide');
};

DazSearch.prototype.filterSearchResults = function () {
    // Setup relevancy sorting
    daz.filter.sorts.search = {};
    for (var i = 0; i < this.products.length; i++) {
        daz.filter.sorts.search[this.products[i]] = i;
    }

    this.categories = daz.filter.categorizeProducts(this.products);
    this.displayCategories = this.getDisplayCategories(this.categories);
    this.renderCategories();

    $('#search_amount').html(this.paginationData.NofResults + " item" + (this.paginationData.NofResults ? "s" : ""));

};

DazSearch.prototype.renderCategories = function () {
    // Sort the products so we can display the most popular results first
    this.products = daz.filter.sortProducts(this.products, this.sortField, this.sortDir);

    for (var i = 0; i < this.displayCategories.length; i++) {
        this.displayCategories[i].products = daz.filter.sortProducts(this.displayCategories[i].products, this.sortField, this.sortDir);
    }

    // Sort the categories so that the categories with the most popular items are first
    var self = this;
    var getProdIndex = function (prodId) {
        for (var i = 0; i < self.products.length; i++) {
            if (self.products[i] == prodId) {
                return i;
            }
        }

        return 9999999;
    }
    this.displayCategories.sort(function (a, b) {
        if (getProdIndex(a.products[0]) > getProdIndex(b.products[0])) {
            return 1;
        } else {
            return -1;
        }
    });

    var carouselData = [];

    // Now add the html for the carousels
    for (var i = 0; i < this.displayCategories.length; i++) {
        var currCategory = daz.filter.rawCategories[this.displayCategories[i].id];
        var nameParts = [];
        while (true) {
            nameParts.push(currCategory.name);
            if (currCategory.parent == daz.filter.rootCategory
                || !daz.filter.rawCategories[currCategory.parent]) {
                break;
            }
            currCategory = daz.filter.rawCategories[currCategory.parent];
        }

        carouselData.push({
            categoryId: this.displayCategories[i].id,
            name: nameParts[0],
            subName: nameParts.slice(1),
            productList: this.displayCategories[i].products.slice(0, 30).join(','),
            productLength: this.displayCategories[i].products.length,
            expanded: this.displayCategories.length == 1,
            idx: i
        });
    }

    if (this.displayCategories.length == 1) {
        $('h1 .search-expand').hide();
    } else {
        $('h1 .search-expand').on('click', $.proxy(this.expandFullSearch, this));
    }

    //$('#search_results').html(Templates.searchCarousels.render({carousels: carouselData}));
    $('#search_results').find('.search-expand').on('click', $.proxy(this.expandSearch, this));
    this.showProductList('All Search Results', null);


    var self = this;
    window.setTimeout(function () {
        CarouselBase.initCarousels($('#search_results'));
    }, 1);
};

DazSearch.prototype.getDisplayCategories = function (category) {
    var removeProducts = {};
    var displaySubs = [];
    var minSize = Math.max(9, Math.round(this.products.length * 0.07));
    var currCategory = daz.filter.rawCategories[category.id];
    for (var i = 0; i < category.children.length; i++) {
        if (category.children[i].products.length > 9) {
            var childDisplay = this.getDisplayCategories(category.children[i]);
            if (childDisplay.length < 1) {
                continue;
            }
            for (var ii = 0; ii < childDisplay.length; ii++) {
                displaySubs.push(childDisplay[ii]);
            }
        }
    }

    for (var i = 0; i < category.products.length; i++) {
        if (this.displayedProducts[category.products[i]]) {
            category.products.splice(i, 1);
            i--;
        }
    }

    if (category.products.length > minSize
        || (!currCategory.parent && category.products.length > 0)) {
        displaySubs.push(category);
        for (var i = 0; i < category.products.length; i++) {
            this.displayedProducts[category.products[i]] = true;
        }
    }

    // Now that everything is sorted, see if we need to "un-sort" it if there is only "one" active category.
    // The "one" is in quotes because there could be two categories, but we don't count the root category
    if (displaySubs.length == 2 && (displaySubs[0].id == daz.filter.rootCategory
        || displaySubs[1].id == daz.filter.rootCategory)) {
        var newDisplaySubs = [{
            id: daz.filter.rootCategory,
            products: displaySubs[0].products.concat(displaySubs[1].products)
        }];
        displaySubs = newDisplaySubs;
    }

    return displaySubs;
};

DazSearch.prototype.expandSearch = function (event) {
    event.preventDefault();

    var $container = $(event.target).closest('h3');

    var categoryId = parseInt($container.attr('data-categoryid'), 10);

    this.showProductList($container.html(), categoryId);
}

DazSearch.prototype.expandFullSearch = function (event) {
    event.preventDefault();

    this.showProductList('All Search Results', null);
};

DazSearch.prototype.showProductList = function (title, categoryId) {
    if (categoryId != null) {
        var productList = daz.filter.filterProducts(this.products, {category: [categoryId]});
    } else {
        productList = this.products.slice(0);
    }

    productList = daz.filter.sortProducts(productList, this.sortField, this.sortDir);

    $('#search_details').find('h2').html(title);

    $('#search_details').show();

    if (!daz.searchList) {
        daz.searchList = new DazSearchList();
        daz.searchList.init($('#search_details_list'), productList);
        daz.searchList.pageSize = this.paginationData.MaxPerPage;
        daz.searchList.currPage = this.paginationData.CurrentPage - 1;
    } else {
        daz.searchList.pageSize = this.paginationData.MaxPerPage;
        daz.searchList.currPage = this.paginationData.CurrentPage - 1;
        daz.searchList.productList = productList.slice(0);
        daz.searchList.reinit();
    }

    window.setTimeout(function () {
        $('html,body').animate({scrollTop: $("#search_details").offset().top - $("#crumbs_clone").outerHeight()}, 1000);
    }, 50);
};

DazSearchList.prototype = new DazProductList();

function DazSearchList() {
    DazProductList.call(this);
}

DazSearchList.prototype.reinit = function () {
    for (var filterType in daz.searchList.filterData) {
        daz.searchList.filterData[filterType].sorted = false;
    }
    DazProductList.prototype.reinit.call(this);
};

DazSearchList.prototype.handleClick = function (event) {
    // Default one does all sorts of stuff to mess with the hash,
    // we don't care that much.
};

DazSearchList.prototype.loadPage = function (pageNum) {
    var that = this;
    platClub = this.filterSettings.hasOwnProperty('platClub');
    $.ajax({
        url: "/catalogsearch/json/",
        data: {
            q: daz.search.searchQuery,
            pp: $('.js-pagesize').val(),
            p: pageNum + 1,
            s: this.sortOrderSettings[0],
            vendor: this.filterSettings.vendor,
            genre:  this.filterSettings.genre,
            figure:  this.filterSettings.compat_figures,
            software: this.filterSettings.compat_software,
            owned: this.filterSettings.owned,
            bundlez: this.filterSettings.bundle,
            pc: platClub
        }
    }).done(function (result) {
        daz.search.paginationData = result.Pagination;
        daz.search.loadProducts(result.Results);
        daz.search.loadFacets(result.Facets);
        that.currPage = pageNum;
        that.switchPage(pageNum);
        window.setTimeout(function () {
            daz.search.filterSearchResults.call(daz.search);
        }, 1);
    })

}

DazSearchList.prototype.switchPage = function (pageNum) {
    this.orderedStart = 0;

    var numToLoad = this.pageSize;
    if (pageNum == 0) {
        numToLoad -= this.reuseExistingSlabs(this.pageSize);
    } else {
        this.listElem.html('');
    }
    this.reset();

    this.addPlaceholders(numToLoad);
    this.buildPagination();

    this.handleScroll();
}

DazSearchList.prototype.buildPagination = function () {
    if (this.infiniteScroll) {
        $('.pagination_container').hide();
        return;
    }

    var limit = daz.search.paginationData.NumberOfPageLinks;
    var maxPages = daz.search.paginationData.NofPages;
    var displayItems = [];
    if (maxPages < limit + 2) {
        for (var i = 0; i < maxPages; i++) {
            displayItems.push(i);
        }
    } else if (this.currPage < (limit - 1)) {
        for (var i = 0; i < limit; i++) {
            displayItems.push(i);
        }
        displayItems.push(-1);
        displayItems.push(maxPages - 1);
    } else if (this.currPage > (maxPages - (limit - 1))) {
        displayItems.push(0);
        displayItems.push(-1);
        for (var i = maxPages - (limit - 1); i < maxPages; i++) {
            displayItems.push(i);
        }
    } else {
        displayItems.push(0);
        displayItems.push(-1);
        displayItems.push(this.currPage - 1);
        displayItems.push(this.currPage);
        displayItems.push(this.currPage + 1);
        displayItems.push(-1);
        displayItems.push(maxPages - 1);
    }

    var html = '';
    for (var i = 0; i < displayItems.length; i++) {
        if (displayItems[i] == -1) {
            html += '<span class="pagination_blank">...</span>';
        } else {
            var extraClass = '';
            if (displayItems[i] == this.currPage) {
                extraClass = ' active';
            }
            html += '<a class="pagination_page button ' + extraClass + '" data-pagenum=' + displayItems[i] + '>' + (displayItems[i] + 1) + '</a>';
        }
    }
    $('.pagination').html(html);
    if (this.currPage === 0) {
        $('.pagination_container .prev').addClass('disabled');
    } else {
        $('.pagination_container .prev').removeClass('disabled');
    }

    if (this.currPage + 1 === maxPages) {
        $('.pagination_container .next').addClass('disabled');
    } else {
        $('.pagination_container .next').removeClass('disabled');
    }

    $('.pagination_container').show();

};


DazSearchList.prototype.handlePaginationNext = function (event) {
    this.handlePaginationMaybeScroll(event);

    var maxPages = daz.search.paginationData.NofPages;
    if (this.currPage + 1 < maxPages) {
        this.loadPage(this.currPage + 1);
        this.handleClick(true);
    }
};


DazSearchList.prototype.handlePaginationPrev = function (event) {
    this.handlePaginationMaybeScroll(event);

    if (this.currPage > 0) {
        this.loadPage(this.currPage - 1);
        this.handleClick(true);
    }
};

DazSearchList.prototype.handlePaginationClick = function (event) {
    this.handlePaginationMaybeScroll(event);

    var selectedPage = $(event.target).attr('data-pagenum');

    if (selectedPage != null) {
        this.loadPage(parseInt(selectedPage, 10));
        this.handleClick(true);
    }
};


DazSearchList.prototype.reuseExistingSlabs = function (maxSlabs) {
    return 0;
};

DazSearchList.prototype.applyFilters = function(doScroll) {
    var filterCount = 0;

    var hasFilter = false;
    for (var filterName in this.filterSettings) {
        if (filterName == 'owned') {
            continue;
        }
        if (this.filterSettings[filterName].length < 1) {
            continue;
        }
        hasFilter = true;
        for (var i in this.filterSettings[filterName]) {
            filterCount++;
        }
    }
    this.ordered = this.productList;

    var items = 'items';
    if (this.ordered.length == 1) {
        items = 'item';
    }
    var html = this.ordered.length+' ';
    if (this.ordered.length != this.productList.length) {
        html += 'of '+this.productList.length+' ';
        items = 'items';
    }
    html += items;
    $('#catalog_amount').html(html);
    var filterTotal = 0;
    for (var filterType in daz.filter.filters) {
        if (filterType == 'owned') {
            continue;
        }
        var filterCount = 0;
        if (typeof this.filterSettings[filterType] == 'object') {
            filterCount = this.filterSettings[filterType].length;
        }
        if (filterCount > 0) {
            $('#large_'+filterType+'_count').html(filterCount).css('visibility','visible');
        } else {
            $('#large_'+filterType+'_count').html(0).css('visibility','hidden');
        }
        filterTotal += filterCount;
    }
    if (filterTotal > 0) {
        $('#small_filter_count').html(filterTotal).css('visibility','visible');
        $('#filter_clear_container').css('display','');
    } else {
        $('#small_filter_count').html(0).css('visibility','hidden');
        $('#filter_clear_container').css('display','none');
    }

    if (doScroll) {
        if (this.infiniteScroll) {
            this.handleScroll();
        } else {
            this.buildPagination();
            this.loadPage(this.currPage);
        }
    }
};

DazSearchList.prototype.sortFilter = function(filterType) {
    var elem = this.filterData[filterType].elem.find('ul');

    if (!daz.search.facets.hasOwnProperty(filterType)) {
        elem.empty();
        elem.append("<li>No Filters Available</li>");
        return;
    }

    var sortedFilter = daz.search.facets[filterType];

    elem.empty();

    if (sortedFilter.length > 15) {
        var label = $('div.'+filterType+'_container a label')[0].textContent;
        var newElem = $('<input maxlength=255 class=filter_search size=32 data-filtertype="'+filterType+'" type=text placeholder="Enter '+label+' name...">');

        newElem.on('keyup', $.proxy(this.handleFilterSearch,this));

        elem.append(newElem);
    }

    for (var i in sortedFilter) {
        var idx = sortedFilter[i].Value;
        var len = sortedFilter[i].Count;
        var sel = sortedFilter[i].Selected ? 'checked' : '';
        var addClass =  sortedFilter[i].Selected ? 'filter_visible' : '';

        if (len == 0) {
            continue;
        }

        var extraHtml = '';
        if (i > 15) {
            extraHtml = 'style="display: none;"';
        } else {
            extraHtml = 'class="filter_visible"';
        }

        var largeElem = $('<li id="'+this.filterGetId('large',filterType,idx)+'" data-filterval="'+idx+'"   '+extraHtml+'><input class="' + addClass + '" ' + sel + ' id="'+this.filterGetId('large',filterType,idx)+'_check" type=checkbox data-filtertype="'+filterType+'" value="'+idx+'">'+idx+' (<span id="'+this.filterGetId('large',filterType,idx)+'_count" class="filter_count">'+len+'</span>)</li>');

        largeElem.on('click',$.proxy(this.handleFilterClick,this));
        elem.append(largeElem);
    }

    this.filterData[filterType].sorted = true;

    this.filterUpdateCounts();
};


DazSearchList.prototype.filterUpdateCounts = function() {
    for (var filterType in daz.search.facets) {
        if (filterType == 'owned' ||
            filterType == 'category' ||
            filterType == 'platClub' ||
            filterType == 'platinum_club' ||
            !this.filterData[filterType] ||
            !this.filterData[filterType].elem) {
            continue;
        }

        var filterElems = this.filterData[filterType].elem.find('li.filter_visible');

        for (var i = 0; i < filterElems.length; i++) {
            var filterOpt = $(filterElems[i]).attr('data-filterval');

            var elem = document.getElementById(this.filterGetId('large',filterType,filterOpt)+'_count');
            elem.innerHTML = daz.search.facets[filterType][filterOpt].Count;

        }
    }

    // Trigger a reflow
    $('#filter_container .dropdown_content').css('margin-left','0.05px');
    window.setTimeout(function() { $('#filter_container .dropdown_content').css('margin-left',''); }, 1);

};
