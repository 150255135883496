function DazNav(elem) {
    this.parentElem = elem;
    this.resizeTimeout = null;
    this.wasSqueezed = false;
    this.parentElem.on('clone', function(dontCare, cloneElem) {new DazNav($(cloneElem));});
    
    this.elem = this.parentElem.find('ul.breadcrumbs');

    window.setTimeout($.proxy(this.init, this), 1);
    $(window).on('resize', $.proxy(this.handleResize, this));
    $(window).load($.proxy(this.startInit, this));
    
    // this.elem.find('li.nav_more').click($.proxy(this.moreToggle, this));

    this.startInit();
}

DazNav.prototype.startInit = function() {
    window.setTimeout($.proxy(this.init,this), 1);
};

DazNav.prototype.init = function() {
    // Make it recalculate everything
    this.wasSqueezed = true;
    var self = this;
    this.maxWidth = 0;
    this.elem.find('> li').each(function (idx, subnav) { 
        self.maxWidth += $(subnav).outerWidth(true);
    });    

    this.crumbWidth = 0;
    this.crumbCount = 0;
    this.elem.find('li.notcurrent').each(function (idx, subnav) { 
        self.crumbCount++;
        self.crumbWidth += $(subnav).outerWidth(true);
    });

    this.actuallyResize();
};

DazNav.prototype.handleResize = function() {
    if (this.resizeTimeout != null) {
        window.clearTimeout(this.resizeTimeout);
        this.resizeTimout = null;
    }

    this.resizeTimeout = window.setTimeout($.proxy(this.actuallyResize,this), 10);
};

DazNav.prototype.actuallyResize = function() {
    this.resizeTimeout = null;

    var elemWidth = this.elem.innerWidth();
    this.titleWidth = this.elem.find('li.current').outerWidth(true);

    if (this.wasSqueezed == false && this.maxWidth < elemWidth) {
        // If the categories fit, you must aquit
        return;
    }
    this.wasSqueezed = false;
    
    var newCrumbWidth = 0;
    if ((this.crumbWidth + this.titleWidth) > elemWidth) {
        // Can't fit the crumbs and title on this line, let's get drastic
        // Do we need to shrink the title?
        if (this.titleWidth > (elemWidth/2)) {
            this.elem.find('li.current').css({'max-width': '50%'});
        } else {
            this.elem.find('li.current').css({'max-width': 'auto'});
        }
        this.titleWidth = this.elem.find('li.current').outerWidth(true);
        var calcWidth = (elemWidth - this.titleWidth - 30) / this.crumbCount;
        var crumbs = this.elem.find('li.notcurrent');
        crumbs.css({'max-width': calcWidth+'px'});

        // Recalculate crumbWidth for the subnav calcs
        crumbs.each(function (idx, subnav) { 
            newCrumbWidth += subnav.scrollWidth;
        });
        
        this.wasSqueezed = true;
    } else {
        this.elem.find('li.notcurrent').css({'max-width': 'auto'});
        this.elem.find('li.current').css({'max-width': 'auto'});
        newCrumbWidth = this.crumbWidth;
    }
};
