function DazDeals(api) {
    this.hasLoaded = false;
    this.data = [];
    this.deals = {};
    this.resolvers = [];
    this.waiters = [];
    this.api = api;
}

DazDeals.prototype.initDeals = function (ignore, data, isCache) {
    if (isCache || this.hasLoaded || !daz.api.isLoggedIn()) {
        return;
    }
    this.data = {};
    this.deals = {};
    if (data.deals) {
        for (let deal in data.deals) {
            this.deals[deal] = data.deals[deal];
            for (let i in data.deals[deal].products) {
                const id = data.deals[deal].products[i];
                this.data[id] = null;
            }
        }
    }

    this.doneLoading();
};

DazDeals.prototype.initCountdown = function(baseElem, deal = 'mine', onFinish = null) {
    if (!this.deals || !this.deals[deal]) {
        console.error('deal ' + deal + ' not initialized');
        return;
    }
    const expires = new Date(Date.parse(this.deals[deal].expires));
    $(baseElem).countdown({
        year: expires.getUTCFullYear(),
        month: expires.getUTCMonth() + 1,
        day: expires.getUTCDate(),
        hour: expires.getUTCHours(),
        minute: expires.getUTCMinutes(),
        second: expires.getUTCSeconds(),
        timezone: 0,
        collapse: true,
        labels: true,
        onFinish: function () {
            if (onFinish) {
                onFinish();
            }
        }
    });

}

DazDeals.prototype.initCarousel = function(baseElem, deal = 'mine', init = true) {
    if (!this.deals || !this.deals[deal]) {
        console.error('deal ' + deal + ' not initialized');
        return;
    }
    const $e = $(baseElem);
    const ids = [];
    for (const i in this.deals[deal].products) {
        ids.push(this.deals[deal].products[i]);
    }
    $e.find('.daz-carousel').attr('data-products', ids.join(','));

    init && CarouselBase.initCarousels($e);
}

DazDeals.prototype.getPrice = async function (productId) {
    if (this.data[productId] > 0) {
        return this.data[productId];
    }
    if (this.waiters[productId]) {
        return this.waiters[productId];
    }
    this.waiters[productId] = await new Promise((resolve) => {
        this.resolvers[productId] = (price) => {
            this.data[productId] = price;
            resolve(price);
            return price;
        }
    });
    return this.waiters[productId];
};

DazDeals.prototype.doneLoading = function () {
    if (this.data.length === 0) {
        this.hasLoaded = true;
        return;
    }

    const ids = [];
    for (let id in this.data) {
        id = parseInt(id, 10);
        if (isNaN(id) || id <= 0) {
            continue;
        }
        ids.push(id);
    }

    this.hasLoaded = true;
    if (ids.length === 0) {
        return;
    }
    fetch('/customer/deals/load?ids=' + ids.join(',')).then((response) => {
        if (!response.ok) {
            throw 'could not load customer deals';
        }
        return response.json();
    }).then(json => {
        for (let id in json) {
            try {
                const price = json[id];
                id = parseInt(id, 10);
                if (this.resolvers[id]) {
                    const f = this.resolvers[id];
                    f(price);
                } else {
                    this.data[id] = price;
                }
            }
            catch (e) {
                console.error(e);
            }
        }

        const myDealProds = this.deals?.mine?.products || [];
        if (myDealProds.length) this.tracking([ ...myDealProds]);
    }).catch(() => {
        console.error("could not load customer deals");
        this.data = null
    })
}

DazDeals.prototype.tracking = function (myDealProdIds) {
    const isLightningLanding = document.location.pathname === '/lightning-deals'
    const viewed_deal_item_id = DazProductPage?.productId || '';
    const isViewingLightningProd = myDealProdIds.includes(viewed_deal_item_id);

    // since this fires on every page, only continue when applicable
    if (!isLightningLanding && !isViewingLightningProd) return;

    const expires = this.deals?.mine?.expires || '';

    const gtmPayload = {
        event: 'view_lightning_page',
        expires,
        viewed_deal_item_id: `${viewed_deal_item_id}`
    }

    for (let i = 0; i < myDealProdIds.length; i++) gtmPayload[`deal_item_id_${i}`] = `${myDealProdIds[i]}`
    
    daz.help.gtmTrigger(gtmPayload)
}