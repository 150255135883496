function DazProductSlab(productData, templateFunc, elemSelector) {
    this.productData = productData;
    this.templateFunc = templateFunc;
    this.elemSelector = elemSelector;
    this.needPrice = false;
    this.needFilter = false;
    this.needSlab = true;
    this.retryCount = 10;
    this.currency = '';

    if (typeof this.productData.canOrder == 'undefined') {
        // Need to figure out if this product is owned or not
        this.needFilter = true;
        daz.filter.onLoad(this, this.loadFilter);
    }

    if (typeof this.productData.price == 'undefined') {
        // Need to get price data for this product
        this.needPrice = true;
        daz.api.getPrice(this.productData.id, this, this.loadPrice);
        daz.filter.onLoad(this, () => {
            daz.api.getPrice(this.productData.id, this, this.loadPrice);
        });
    }
}

DazProductSlab.requestCount = 0;

DazProductSlab.init = function (elem) {
    let elemList = elem.find('.daz-slab');

    for (let i = 0; i < elemList.length; i++) {
        let $elem = $(elemList[i]);
        let productIds = [];
        if ($elem.attr('data-categoryid')) {
            productIds = daz.filter.filterProducts(true, {
                'category': [$elem.attr('data-categoryid')],
                'owned': true
            });
            let orderName = $elem.attr('data-order');
            if (!orderName) {
                orderName = 'date';
            }
            let orderDir = $elem.attr('data-order-direction');
            if (!orderDir) {
                orderDir = -1;
            }
            productIds = daz.filter.sortProducts(productIds, orderName, orderDir);
        } else if ($elem.attr('data-products')) {
            productIds = $elem.attr('data-products').split(',');
            for (let ii = 0; ii < productIds.length; ii++) {
                productIds[ii] = productIds[ii].trim();
            }
        }

        if ($elem.attr('data-limit')) {
            productIds = productIds.slice(0, parseInt($elem.attr('data-limit'), 10));
        }

        let hideOwned = true;
        if ($elem.attr('data-showowned') == '1') {
            hideOwned = false;
        }

        if (productIds.length < 1) {
            continue;
        }

        let holderName = $elem.attr('data-slabholder');
        if (!holderName) {
            holderName = 'slabholder';
        }
        let slabName = $elem.attr('data-slab');
        if (!slabName) {
            slabName = 'slab';
        }

        $elem.html('');

        for (let ii = 0; ii < productIds.length; ii++) {
            if (hideOwned && daz.filter.productIsOwned(productIds[ii])) {
                continue;
            }
            let slabElemId = "autoslab" + Math.round(Math.random() * 10000000);
            let slabData = {id: productIds[ii], elemId: slabElemId};
            let slabElem = Templates[holderName].render(slabData);
            $elem.append(slabElem);
            let slab = new DazProductSlab(slabData, Templates[slabName], '#' + slabElemId);
            slab.load();
        }
    }

    let priceElems = elem.find('.daz-price');

    for (let i = 0; i < priceElems.length; i++) {
        let $priceElem = $(priceElems[i]);
        let pricesJson = $priceElem.attr('data-prices');
        let productId = $priceElem.attr('data-product');
        let stockPrice = $priceElem.attr('data-msrp');
        let freeTrial = $priceElem.attr('data-trial');
        if (pricesJson) {
            let prices = JSON.parse(pricesJson);
            if (prices.hasOwnProperty(daz.cart.currency)) {
                stockPrice = prices[daz.cart.currency].toString();
            }
        }
        let tpl = 'productPrice';
        if ($priceElem.attr('data-template')) {
            let dataTemplate = $priceElem.attr('data-template');
            if (Templates[dataTemplate]) {
                tpl = $priceElem.attr('data-template');
            }
        }
        DazProductSlab.getPrice(productId, stockPrice, pricesJson, freeTrial==='true').then(priceData => {
            $priceElem.html(Templates[tpl].render(priceData)).show();
            if (!priceData.canOrder) {
                elem.find('.btn-cart').hide();
            }
        })
    }
};

DazProductSlab.getPrice = async function (productId, stockPrice, pricesJson, freeTrial = false) {
    let currency
    if (pricesJson) {
        let prices = JSON.parse(pricesJson)
        if (prices.hasOwnProperty(daz.cart.currency)) {
            currency = daz.cart.currency
        }
        stockPrice = prices[currency].toString()
    }
    let priceData = {}
    if (daz.filter.productIsPlatClubExclusive(productId) && !daz.filter.isPlatClub()) {
        priceData.onSale = false
        priceData.salePrice = ""
        priceData.displayPrice = true
        priceData.basePriceDisplay = "Daz+ Exclusive"
        priceData.canOrder = false
    } else {
        return new Promise(resolve => {
            let price = 0
            daz.api.getPrice(productId, this, function (gotPrice) {
                price = gotPrice
                priceData = DazProductSlab.formatPrice({
                    price: price,
                    basePrice: parseFloat(stockPrice),
                    currency: currency,
                    canOrder: true,
                    freeTrial: freeTrial,
                })

                if (
                    priceData.onSale
                    && priceData.percentDiscount 
                    && priceData.percentDiscount > 0
                ) {
                    const productImgBox = document.querySelector('.product-img-box')

                    if (productImgBox) {
                        const percentOffFlag = productImgBox.querySelector('.percent-off-flag')
                        
                        if (
                            percentOffFlag
                            && percentOffFlag.offsetParent === null // only do stuff if flag not yet shown
                        ) {
                            percentOffFlag.prepend(`${priceData.percentDiscount}% OFF`);
                            percentOffFlag.style.display = 'block';
                        }
                    }
                }

                resolve(priceData);
            })
        })
    }
    return priceData
}

// basePrice is $0.00 format while data.price is non-decimal (i.e. pennies or DAZ)
DazProductSlab.formatPrice = function (data) {
    let currency = data.currency;
    if (!currency) {
        currency = daz.cart.currency;
    }
    let basePrice = parseFloat(data.basePrice)

    data.basePriceDisplay = data.basePrice;
    if (currency === "USD") {
        data.salePriceDisplay = basePrice.toFixed(2)
        if (typeof data.price == 'undefined' || data.price == null) {
            data.price = data.basePrice * 100;
        }
        data.salePrice = 0.0;
        data.salePrice = data.price / 100;
        if (data.salePrice < 0.01) {
            data.salePriceDisplay = 'Free';
        } else {
            data.salePriceDisplay = "$" + data.salePrice.toFixed(2)
            data.amountSavedDisplay = "$" + ((basePrice * 100 - data.price) / 100).toFixed(2)
        }

        if (data.salePrice >= basePrice) {
            data.onSale = false;
            if (data.salePrice > basePrice) {
                basePrice = data.salePrice
            }
        } else {
            data.onSale = true;
            data.percentDiscount = Math.round((basePrice - data.salePrice) / basePrice * 100);
        }

        data.basePriceDisplay = basePrice.toFixed(2)
        if (basePrice < 0.01) {
            data.basePriceDisplay = 'Free';
        } else {
            data.basePriceDisplay = '$' + data.basePriceDisplay;
        }
    } else if (currency === "DAZ") {
        data.basePriceDisplay = "¤" + data.basePriceDisplay;
    } else {
        if (basePrice < 0.01) {
            data.basePriceDisplay = 'Free';
        } else {
            data.basePriceDisplay = data.basePrice;
        }
    }

    if (data.price < 0.01 && data.basePrice > 0 && data.freeTrial) {
        data.onSale = false;
        data.basePriceDisplay = "Free Trial"
    } else {
        data.freeTrial = false;
    }
    data.canOrder = data.canOrder && currency === daz.cart.currency;
    if (data.isDisabled) {
        data.onSale = false;
        data.basePriceDisplay = "Inactive";
        data.canOrder = false;
    }
    return data;
};

window.loadedSlabs = {};
DazProductSlab.prototype.load = function () {
    if (window.loadedSlabs[this.productData.id]) {
        window.loadedSlabs[this.productData.id].then(data => {
            this.loadAjax(data);
            return data;
        })
        return;
    }
    DazProductSlab.requestCount++;
    window.loadedSlabs[this.productData.id] = fetch("/dazApi/slab/" + this.productData.id)
        .then(response => {
            return response.json();
        }).then(data => {
            this.loadAjax(data);
            return data;
        }).catch(error => {
            // console.log(error);
        });
};

DazProductSlab.prototype.loadFilter = function () {
    this.productData.owned = daz.filter.productIsOwned(this.productData.id);
    this.productData.inCart = daz.filter.productIsInCart(this.productData.id);
    this.productData.inWishlist = daz.filter.productIsInWishlist(this.productData.id);

    if (!this.productData.inCart && !this.productData.owned) {
        this.productData.canOrder = true;
    } else {
        this.productData.canOrder = false;
    }

    this.needFilter = false;
    this.partLoaded();
};

DazProductSlab.prototype.loadPrice = function (price) {
    this.productData.price = price;

    this.needPrice = false;
    this.partLoaded();
};

DazProductSlab.prototype.loadAjax = function (ajaxData) {
    DazProductSlab.requestCount--;
    for (let idx in ajaxData) {
        if (idx === 'price') {
            this.productData.basePrice = parseFloat(ajaxData.price);
        } else if (idx === 'trial') {
            this.productData.trial = ajaxData.trial
        } else if (idx === 'mature') {
            this.productData.mature = !!ajaxData.mature;
        } else if (idx === 'currency') {
            this.productData.currency = ajaxData.currency
        } else if (idx === 'prices') {
            let hasPrice = false;
            for (let cur in ajaxData.prices) {
                if (cur === daz.cart.currency && ajaxData.prices.hasOwnProperty(cur)) {
                    // For addons, productData should already have basePrice in pennies.
                    // Use this if possible, since the ajaxData price is a generic price
                    // rather than the addon price set for this specific product.
                    if (ajaxData.type === 'addon' && this.productData.basePrice) {
                        this.productData.basePrice = parseFloat(this.productData.basePrice / ((cur === 'USD') ? 100 : 1));
                    } else {
                        this.productData.basePrice = parseFloat(ajaxData.prices[cur]);
                    }
                    this.productData.currency = cur;
                    this.productData.canOrder = true;
                    hasPrice = true;
                    break;
                }
            }
            if (!hasPrice) {
                this.productData.basePrice = 0;
                this.productData.currency = daz.cart.currency;
                this.productData.canOrder = false;
            }
        } else {
            this.productData[idx] = ajaxData[idx];
        }
    }
    this.productData.platClubExclusive = daz.filter.productIsPlatClubExclusive(ajaxData.id);
    if (this.productData.platClubExclusive && !daz.filter.isPlatClub()) {
        this.productData.onSale = false;
        this.productData.salePrice = "";
        this.productData.displayPrice = true;
        this.productData.basePriceDisplay = "Daz+ Exclusive";
        this.productData.canOrder = false;
    }
    if (this.productData.trial) {
        this.productData.onSale = false;
        this.productData.basePriceDisplay = 'Free Trial';
        this.productData.freeTrialDisplay = 'Free Trial';
    }
    if (this.productData.type === 'giftcard') {
        this.productData.canOrder = false;
    }
    if (this.productData.type === 'opensea' && daz.opensea) {
        daz.opensea.LoadSlab(this, 5);
    } else {
        this.needSlab = false;
    }
    if ($(this.elemSelector).attr('data-nameoverride')) {
        this.productData.name = $(this.elemSelector).attr('data-nameoverride');
    }
    this.partLoaded();
};

DazProductSlab.prototype.partLoaded = function () {
    if (!this.needFilter && !this.needPrice && !this.needSlab) {
        // We have everything we need
        this.draw();
    }
};

DazProductSlab.prototype.draw = function () {
    const elem = $(this.elemSelector)[0];
    if (!elem) {
        return;
    }
    daz.mature.is(false).then((showMature) => {
        let data = this.productData;


        data.displayPrice = true;
        if (data.type == 'giftcard') {
            data.displayPrice = false;
        } else if (data.type === 'opensea') {
            data.onSale = false;
            data.salePrice = "";
            data.displayPrice = data.canOrder;
            if (data.quantity <= 1) {
                delete data["quantity"];
            }
        } else if (data.platClubExclusive && !daz.filter.isPlatClub()) {
            // do nothing, we just don't want the price to be formatted
        } else {
            data = DazProductSlab.formatPrice(data);
        }

        if (!data.canOrder) {
            $(this.elemSelector).find('.btn-cart').hide();
        }

        if (Date.now() / 1000 < data.newTime) {
            data.isNew = true;
        } else {
            data.isNew = false;
        }

        if (!elem) {
            //console.log("Missing element", this.elemSelector);
            if (this.retryCount > 0) {
                this.retryCount--;
                window.setTimeout($.proxy(function () {
                    this.draw();
                }, this), 50);
            }
            return;
        }

        elem.setAttribute('data-placeholder', '0');
        elem.innerHTML = this.templateFunc.render(data);

        const wishlistContainer = elem.querySelector('.wishlist-buttons');
        if (wishlistContainer) {
            new DazWishlist(wishlistContainer);
        }

        if (data.mature && !showMature) {
            daz.mature.push(new DazMatureSlab(elem, data));
        }
    });
};

$(function () {
    // Since we filter on owned, we need to setup the filter here
    daz.filter.onLoad(DazProductSlab, function () {
        DazProductSlab.init($(document));
    });
});
let mmm = 0;
