let DazProductPage = {
    productId: 0,
    owned: function(callData, data, isCache) {
        if (this.productId === 14548  || this.productId == 16873 || this.productId == 16874 || this.productId == 16875) {
            return;
        }
        let products = data.owned;
        for (let i = 0; i < products.length; i++) {
            if (products[i] == this.productId) {
                daz.cart.purchased = this.productId;
                $('.product-shop button[class|="btn"]').css('display','none');
                $('.product-shop button.btn-purchased').css('display','');
                $('.primary_info button[class|="btn"]').css('display','none');
                $('.primary_info button.btn-purchased').css('display','');

                // new product page layout
                $('.cart_buttons button[class|="btn"]').css('display','none');
                $('.cart_buttons button.btn-purchased').css('display','');
                $('div.add-to-wishlist').css('display','none');

                for (let type in data.licensed) {
                    $('input[type="checkbox"][id^="add-'+type+'-license-for-"]').unbind().on('change', function(){
                        if($(this).prop('checked')) {
                            let sku = $(this).prop('value');
                            $('.cart_buttons button.btn-purchased').css('cursor', 'pointer');
                            $('.cart_buttons button.btn-purchased span.bs5-text-nowrap').css('margin', '0 15.4px').text('Buy License');
                            $('.cart_buttons button.btn-purchased').on('click', function(){
                                daz.cart.addLicenseOnly(daz.cart.purchased, sku);
                            });
                        }
                        else {
                            $('.cart_buttons button.btn-purchased span.bs5-text-nowrap').css('margin', '').text('Already Owned');
                            $('.cart_buttons button.btn-purchased').unbind().css('cursor', 'not-allowed');
                        }
                    });
                }
            }
        }
        $('.compatible.required li').each(function() {
            let id = $(this).data('requireid');
            for (let i = 0; i < products.length; i++) {
                if (products[i] == id) {
                    $(this).addClass('owned');
                }
            }
        });
    },
    licensed: function(callData, data, isCache) {
        let licensed = data.licensed;
        for (let type in licensed) {
            for (let i = 0; i < licensed[type].length; i += 1) {
                if (licensed[type][i] == this.productId) {
                    // old layout
                    $('input[data-license-type="'+type+'"]').prop('disabled', true).prop('checked', true).attr('title', 'Purchased');

                    //new layout
                    $('.add-license-container input[id^="add-'+type+'-license-for-"]').prop('disabled', true).prop('checked', true).attr('title', 'Already Owned');
                    $('.add-license-container label[for^="add-'+type+'-license-for-"]').text(type + 'License Purchased');
                }
			}
        }
    },
    inCart: function(callData, data, isCache) {
        let products = data.products;
        for (let i = 0; i < products.length; i++) {
            if (products[i] == this.productId) {
                daz.cart.in_cart = this.productId;
                $('.product-shop button[class|="btn"]').css('display','none');
                $('.product-shop button.btn-in-cart').css('display','');
                $('.primary_info button[class|="btn"]').css('display','none');
                $('.primary_info button.btn-in-cart').css('display','');
                $('.cart_buttons button[class|="btn"]').css('display','none');
                $('.cart_buttons button.btn-in-cart').css('display','');
            }
        }
        let licensed = data.licenses;
		for (let type in licensed) {
			if (licensed.hasOwnProperty(type)) {
				for (let i = 0; i < licensed[type].length; i += 1) {
					if (licensed[type][i] == this.productId) {
						$('input[data-license-type="' + type + '"]').prop('disabled', true).attr('title', 'In Cart');
					}
				}
			}
		}
	},
    populateAddons: function() {
        if (typeof _addon_ids !== "undefined") {
            let displayLimit = 6;
            let productIds = [];
            for (let i = 0; i < _addon_ids.length; i++) {
                if (daz.filter.productIsOwned(_addon_ids[i])) {
                    _addon_ids.splice(i,1);
                    i--;
                }
            }
            _addon_ids = daz.filter.sortProducts(_addon_ids, 'trending');
            
            for (let i = 0; i < _addon_ids.length; i++) {
                if (displayLimit == i) {
                    break;
                }
                let elemId = 'addon_item_'+i;
                let slabData = {id: _addon_ids[i], elemId: elemId};
                let slabElem = Templates.small$Slabholder.render(slabData);
                $('#addon_products').append(slabElem);
                let slab = new DazProductSlab(slabData, Templates.slab, '#'+elemId);
                slab.load();
            }
        }
    },
    loadRecommended: function (elementId) {
        let $dr = $(elementId)
        if (!$dr) {
            return
        }
        $.ajax({
            url: "dazstatic/product/likethis/" + this.productId,
            success: function (response) {
                if (response.ids && response.ids.length > 0) {
                    response.ids = daz.filter.filterProducts(response.ids, {owned: true}, true);
                    let isCarousel = true;

                    const $slabholder = $dr.find('.daz-carousel');
                    if (!$slabholder.length) {
                        isCarousel = false;
                        $slabholder = $dr.find('.daz-slab');
                    }
                    
                    $slabholder.attr('data-products', response.ids.join(','))
                    if (response.attr) {
                        $slabholder.attr('data-attributiontoken', response.attr);
                    }

                    if (isCarousel) {
                        CarouselBase.initCarousels($dr);
                    } else {
                        DazProductSlab.init($dr)
                    }
                    $dr.show()
                } else {
                    $dr.remove()
                }
            },
            error: function () {
                $dr.remove()
            }
        })
    },
    init: function(id, url) {
        this.productId = id;
        $(window).on('beforeDazApi', function() {
            daz.api.addCall('Catalog/owned', {callbackClass: DazProductPage, callbackFunc: DazProductPage.owned});
            daz.api.addCall('License/owned', {callbackClass: DazProductPage, callbackFunc: DazProductPage.licensed});
            daz.api.addCall('Cart/contents', {callbackClass: DazProductPage, callbackFunc: DazProductPage.inCart});
        });
        daz.filter.onLoad(this, this.populateAddons);
        if (url) {
            daz.utm.GetPage();
            daz.utm.Send("product_view", url);
        }
        
        this.loadRecommended('#download-recommend');
    }
};
