DazCart.prototype = {};

function DazCart(api) {
    this.typeList = {
        'addedProducts': 1,
        'requiredProducts': 1,
        'addOnProducts': 1,
        'recommendedProducts': 1,
        'suggestedProducts': 1,
    };
    this.api = api;
    this.licenses = [];
    this.reset();
    this.currency = "USD";
    this.enterprise = null;

    this.api.addCall("User/info", {callbackClass: this, callbackFunc: this.loadCurrency, onlyOnce: false});
}

DazCart.prototype.loadCurrency = function (callData, data, isCache) {
    this.currency = data.currency ? data.currency : this.currency;
    let enterprise = this.currency === 'DAZ'
    if (this.enterprise !== null && this.enterprise !== enterprise) {
        document.location.reload(false);
        return;
    }
    $('body').toggleClass('is-enterprise', enterprise);
    this.enterprise = enterprise;
}

DazCart.prototype.reset = function () {
    this.ajaxData = null;
    this.hasAjax = false;
    this.hasFilter = false;
    this.lastAddedProduct = {};
    this.addedProducts = [];
    this.requiredProducts = [];
    this.addOnProducts = [];
    this.recommendedProducts = [];
    this.suggestedProducts = [];
    this.artistProducts = [];
    this.sisterProducts = [];
    this.genreProducts = [];
    this.productData = {};
    this.fullProductList = {};
    this.licenses = [];
    this.loadedRecommended = [];
    this.useLightning = true;
    this.currency = "USD";
    if (this.modalToggler) {
        this.modalToggler.toggleElem.unbind('daztoggle-show');
        delete this.modalToggler;
    }
};

DazCart.prototype.eventLoggingAdd = function (itemID, sourceElement) {
    let event = {
        'category': 'Shop Actions',
        'action': 'Add to Cart',
        'label': '',
    }
    if (sourceElement == undefined) {
        sourceElement = 'Unknown element';
    }
    if ($('#add_to_cart_modal').css('display') == 'block') {
        sourceElement += ' upsell modal'
    }
    let sourcePage = window.location.pathname;
    if (!sourcePage) {
        sourcePage = 'Unknown page';
    }
    event.label = sourceElement + ' on ' + sourcePage;
    // daz.helper.googleEvent(event.category, event.action, event.label);
    this.add(itemID);
};

DazCart.prototype.add = function (productId) {
    const attr = daz.api.sessionStorageGet(`attr_${productId}`);
    this.hasAjax = false;
    let ajaxArgs = {
        context: this,
        success: this.ajaxLoad,
        error: this.ajaxFail,
        data: {
            'licenses': this.licenses,
            'attr': attr,
        },
        type: 'POST',
    };

    if (arguments.length > 1) {
        $.extend(ajaxArgs.data, arguments[1]);
    }

    this.loadedRecommended = [];
    this.modal = false;
    let that = this;
    this.pc = {};

    $('#adding-to-cart-animation').fadeIn('fast');
    $.ajax('/dazstatic/cart/add/product/' + productId, ajaxArgs).done(function (addResp) {
        if (!addResp.success) {
            that.ajaxFail(addResp);
            return;
        }
        $.ajax("/dazstatic/product/added/" + productId).done(function (response) {

            that.pc = response.cart;
            that.pc.showPlatclubBanner = that.pc.showPlatclubBanner && that.pc.platclubSavings > 0;
            if (that.pc.showPlatclubBanner) {
                that.pc.platclubProductID = 16874;
                that.pc.platclubProduct = 'Annual'
                if (that.pc.platclubSavings < 7000 && that.pc.platclubSavings > 0) {
                    that.pc.platclubProductID = 16875;
                    that.pc.platclubProduct = 'Quarterly';
                }
            }
            let trackData = {
                ...that.cart,
                ...that.lastAddedProduct
            }
            daz.help.event('add_cart', trackData)
            if (response.type === "deal") {
                that.loadedRecommended = null;
            } else {
                that.loadedRecommended = daz.filter.filterProducts(response.ids, {owned: true}, true);
            }

            that.loadModalData();
            that.loadRecommends(response.attr);

            // GTM stuff
            if (daz?.help) {
                const addedProd = that.cart.products[0]
                const prodPrice = addedProd.salePrice || addedProd.basePrice;

                const gtmPayload = {
                    event: "add_to_cart",
                    ecommerce: {
                        currency: "USD",
                        value: prodPrice,
                        items: [
                            {
                                item_id: `${addedProd.id}`,
                                item_name: addedProd.name,
                                discount: 0,
                                item_brand: addedProd.dazOriginal === true ? 'Daz Original' : '',
                                price: prodPrice,
                                quantity: 1
                            }
                        ]
                    },
                    items: [
                        {
                            id: `${addedProd.id}`,
                            google_business_vertical: "retail",
                            price: prodPrice,
                        }
                    ],
                    value: that.cart.totalAmount,
                    discount: that.cart.savingAmount,
                }

                // populate categories (up to 5 for ga)
                if (addedProd.categoriesData) {
                    for (let i = 0; i < addedProd.categoriesData.length; i++) {
                        if (i === 5) break;

                        gtmPayload.ecommerce.items[0][`item_category${i === 0 ? '' : i + 1}`] = addedProd.categoriesData[i].category
                    }
                }

                // populate discount
                if (addedProd.onSale) {
                    gtmPayload.ecommerce.items[0].discount = parseFloat((((addedProd.basePrice * 100) - (addedProd.salePrice * 100)) / 100).toFixed(2));
                }

                daz.help.gtmTrigger(gtmPayload)
            }
        });
    });
    daz.filter.onLoad(this, this.filterLoad);

    return false;
};

DazCart.prototype.remove = function (cartProductID) {
    this.hasAjax = false;
    let ajaxArgs = {
        context: this,
        success: this.ajaxRemove,
        data: {
            'licenses': this.licenses,
        },
        type: 'POST',
    };

    if (arguments.length > 1) {
        $.extend(ajaxArgs.data, arguments[1]);
    }
    return $.ajax('/checkout/cart/jdelete/id/' + cartProductID, ajaxArgs);
};

DazCart.prototype.loadDeals = function () {
    let modal = $('#add_to_cart_modal');
    if (!modal[0]) {
        return
    }
    const rec = modal.find('#add-to-cart-deals');
    if (!rec) {
        return;
    }
    CarouselBase.initCarousels(modal.find('#add-to-cart-deals'));
}

DazCart.prototype.loadCarousels = function () {
    let modal = $('#add_to_cart_modal');
    if (!modal[0]) {
        return
    }
    const rec = modal.find('#add-to-cart-carousels');
    if (!rec) {
        return;
    }
    CarouselBase.initCarousels(modal.find('#add-to-cart-carousels'));
}

DazCart.prototype.loadRecommends = function (attr) {
    let modal = $('#add_to_cart_modal');
    if (!modal[0]) {
        return
    }
    const rec = modal.find('#add-to-cart-recommendations');
    if (!rec) {
        return;
    }
    // added null check as this.loadedRecommended was coming back as null and could not read length
    if ((this.loadedRecommended !== null && this.loadedRecommended.length !== 0) && this.modal) {
        const atcrec = modal.find('#atc_recommend');

        atcrec.attr('data-products', this.loadedRecommended.join(','));
        if (attr) {
            atcrec.attr('data-attributiontoken', attr);
        }
        CarouselBase.initCarousels(rec);
        rec.show();
    } else {
        rec.hide();
    }
}
DazCart.prototype.loadModalData = function () {
    if (this.cart && this.cart.hasSub) {

    } else if (this.pc && this.pc.showPlatclubBanner) {
        $('#platinum-club-banner').html(Templates.addToCart_platclub.render(this, {
            slabholder: Templates.slabholder,
            horizontalSlabHolder: Templates.horizontalSlabHolder,
        }));
        $('#platinum-club-banner').slideDown(250);
    }
}

DazCart.prototype.license = function (productId) {
    this.hasAjax = false;
    let ajaxArgs = {
        context: this,
        success: this.ajaxLoad,
        data: {
            'licenses': this.licenses,
        },
        type: 'GET',
    };

    if (arguments.length > 1) {
        $.extend(ajaxArgs.data, arguments[1]);
    }

    $.ajax('/dazstatic/cart/license/product/' + productId, ajaxArgs);
    daz.filter.onLoad(this, this.filterLoad);
    return false;
};

DazCart.prototype.ajaxRemove = function (data) {
    this.ajaxData = data;

    if (!this.ajaxData.success) {
        this.ajaxFail(this.ajaxData);
        return;
    }

    this.hasAjax = true;
    this.cartActionHeader = "Removed from Cart";

    if (this.hasFilter) {
        this.productRemoved(this.ajaxData);
    }
};

DazCart.prototype.ajaxLoad = function (data) {
    this.ajaxData = data;

    if (!this.ajaxData.success) {
        this.ajaxFail(this.ajaxData);
        return;
    }

    this.hasAjax = true;
    this.cartActionHeader = "Added to Cart";

    if (this.hasFilter) {
        this.productAdded(this.ajaxData);
    }
};

DazCart.prototype.ajaxFail = function (data) {
    $('#adding-to-cart-animation').hide();
    if (data.redirectUrl) {
        if (data.redirectUrl == '_reload') {
            document.location.reload(true);
        } else {
            window.location = data.redirectUrl;
        }
    } else {
        daz.api.displayErrors(['Could not add requested item to cart']);
    }
};

DazCart.prototype.filterLoad = function () {
    this.hasFilter = true;

    if (this.hasAjax) {
        this.productAdded(this.ajaxData);
    }
};

DazCart.prototype.productRemoved = function (data) {
    data = $.extend({}, this.lastAddedData, data);
    data.addedProduct = null;
    this.updateBackingData(data.id, true);
    this.pc = data.pc;
    this.productAdded(data);
}

DazCart.prototype.productAdded = function (data) {
    this.fullProductList = {};
    this.lastAddedData = data;
    this.lastAddedProduct = data.addedProduct;
    let products = [];
    if (data.cart && Array.isArray(data.cart.products)) {
        products = data.cart.products.reverse();
    }

    this.mergeProductList('addedProducts', products, true, true);
    for (let type in this.typeList) {
        if (type == 'addedProducts' || !Array.isArray(data[type])) {
            continue;
        }
        this.mergeProductList(type, data[type], false, false);
    }

    // Generate data from filters
    if (data.addedProduct && data.addedProduct.id > 0) {
        if (data.addedProduct.genre && data.addedProduct.genre.length > 0) {
            let productIds = daz.filter.filterProducts(true, {
                genre: data.addedProduct.genre,
                owned: true,
                inCart: false,
            });
            productIds = daz.filter.sortProducts(productIds, 'popular', 'DESC');
            productIds.splice(10);
            let productList = productIds.map(function (elem) {
                return {id: elem};
            });
            this.mergeProductList('genreProducts', productList, false, false);
        }

        if (data.addedProduct.artist && data.addedProduct.artist.length > 0) {
            let productIds = daz.filter.filterProducts(true, {
                vendor: data.addedProduct.artist,
                owned: true,
                inCart: false,
            });
            productIds = daz.filter.sortProducts(productIds, 'popular', 'DESC');
            productIds.splice(10);
            let productList = productIds.map(function (elem) {
                return {id: elem};
            });
            this.mergeProductList('artistProducts', productList, false, false);
        }

        this.updateBackingData(data.addedProduct.id);
    }

    let modal = $('#add_to_cart_modal')
    if (!modal[0]) {
        $('body').append('<div id="add_to_cart_modal" class="modal"></div>');

        modal = $('#add_to_cart_modal');
        modal.on('daztoggle-hide', $.proxy(this.reset, this));
    }

    for (let n = 0; n < data.addedLicenses.length; n += 1) {
        this.mergeProductList('addedProducts', [data.addedLicenses[n]], true, true);
        this.updateBackingData(data.addedLicenses[n].id);
    }

    this.cart = data.cart;
    this.tokensOn = data.tokensOn;
    this.tokensAvailable = data.tokensAvailable;

    if (this.cart.count === 0) {
        modal.trigger('daztoggle-hide');
        modal.remove();
        return;
    }

    $('#adding-to-cart-animation').hide();

    modal.html(Templates.addToCart.render(this, {
        slabholder: Templates.slab,
        horizontalSlabHolder: Templates.horizontalSlabHolder,
    }));
    let self = this;

    modal.find('div.hz-slab').each(function (idx, elem) {
        let cartProductId = $(elem).attr('data-cartproduct');
        let slab = new DazProductSlab(self.productData[cartProductId], Templates.horizontalSlab, '#' + elem.id);
        slab.load();
    });

    modal.find('li.item').each(function (idx, elem) {
        let cartProductId = $(elem).attr('data-cartproduct');
        let slab = new DazProductSlab(self.productData[cartProductId], Templates.slab, '#' + elem.id);
        slab.load();
    });
    modal.find('#add-to-cart-recommendations').hide();
    modal.find('#add-to-cart-deals').hide();

    this.modalToggler = new DazToggler(null, {toggleElem: modal, modal: true, modalFull: false, noElem: true});
    this.modal = true;

    if (daz.api.isLoggedIn()) {
        let $e = document.getElementById('add-to-cart-deals');
        $e.style.display = '';
        daz.deals.initCarousel($e, 'mine', false);
    }

    this.loadModalData();
    this.loadDeals();
    this.loadCarousels();
    if (this.addedProducts.length) {
        modal.trigger('daztoggle-show');
    }
};

DazCart.prototype.updateBackingData = function (productId, remove) {
    remove = !!remove;
    let slabElems = $('[data-productid=' + productId + ']');

    if (!remove) {
        slabElems.find('button.btn-cart').hide();
        slabElems.find('button.btn-in-cart').show();
    } else {
        slabElems.find('button.btn-cart').show();
        slabElems.find('button.btn-in-cart').hide();
    }

    this.cartContents = daz.api.storageGet('Cart/contents');
    let alreadyThere = false;
    if (this.cartContents.products) {
        for (let i = 0; i < this.cartContents.products.length; i++) {
            if (this.cartContents.products[i] == productId) {
                if (remove) {
                    this.cartContents.products.splice(i, 1);
                }
                alreadyThere = true;
                break;
            }
        }
    } else {
        this.cartContents = {products: [], cartCount: 0};
    }
    if ((!alreadyThere && !remove) || (alreadyThere && remove)) {
        this.cartContents['_hash'] = 'made_in_js';
        if (!remove) {
            this.cartContents.products[this.cartContents.products.length] = productId;
        }
        this.cartContents.cartCount = this.cartContents.products.length;

        daz.api.storagePut('Cart/contents', this.cartContents);
        daz.api.runFromData('Cart/contents', this.cartContents);
    }
};

DazCart.prototype.mergeProductList = function (listName, products, showOwned, allowDuplicates) {
    products.forEach((element, i, array) => {
        let alreadyThere = false;
        if (!products[i]) {
            return;
        }

        products[i].elemId = 'cart_' + listName + '-' + products[i].id + '_' + i;
        // If we're not just added, let them add to cart, owned/already in cart
        // will already be filtered out
        if (listName == 'addedProducts') {
            products[i].canOrder = false;
        }

        // Make the product data easy to hand through to the slab
        // Changed to use cartProduct ID instead of product ID as the key to allow
        // certain products (like licenses) to be added multiple times to the cart.
        this.productData[products[i].cpid] = products[i];

        for (let ii = 0; ii < this[listName].length; ii++) {
            if (products[i].id == this[listName][ii].id) {
                // Product already in this list, skip it
                alreadyThere = true;
                break;
            }
        }

        if (!alreadyThere) {
            this[listName].unshift(products[i]);
        }
    });

    if (!showOwned) {
        if (!this.cartContents) {
            this.cartContents = {products: [], cartCount: 0};
        }

        for (let i = 0; i < this[listName].length; i++) {
            let foundDupe = false;
            if (daz.filter.productIsOwned(this[listName][i].id)) {
                foundDupe = true;
            }
            for (let ii = 0; ii < this.cartContents.products.length; ii++) {
                if (this[listName][i].id == this.cartContents.products[ii]) {
                    foundDupe = true;
                }
            }
            for (let ii = 0; ii < this.addedProducts.length; ii++) {
                if (this[listName][i].id == this.addedProducts[ii].id) {
                    foundDupe = true;
                }
            }

            if (foundDupe) {
                // They own this product, remove it
                this[listName].splice(i, 1);
                i--;
            }
        }
    }

    for (let i = 0; i < this[listName].length; i++) {
        if (this.fullProductList[this[listName][i].id]) {
            this.fullProductList[this[listName][i].id] = true;
            if (!allowDuplicates) {
                this[listName].splice(i, 1);
                i--;
            }
        } else {
            this.fullProductList[this[listName][i].id] = true;
        }
    }

    if (this[listName].length > 0) {
        this[listName + 'Exists'] = true;
        this[listName + '_csv'] = this[listName].map(function (elem) {
            return elem.id;
        }).join(',');
    } else {
        this[listName + 'Exists'] = false;
        this[listName + '_csv'] = '';
    }
};

DazCart.prototype.addLicenseType = function (type) {
    this.licenses.push(type);
};

DazCart.prototype.removeLicenseType = function (type) {
    let idx = this.licenses.indexOf(type);
    if (idx > -1) {
        this.licenses.splice(idx, 1);
    }
};

DazCart.prototype.getRecommendations = function (data) {
    if (data.genre && data.genre.length > 0) {
        let productIds = Array.from(new Set(daz.filter.filterProducts(true, {
            genre: data.genre,
            owned: true,
            inCart: false,
        })));
        productIds = daz.filter.sortProducts(productIds, 'popular', 'DESC');
        productIds.splice(10);
        return productIds;
    }
    return [];
}

DazCart.prototype.addLicenseOnly = function (productId, sku = "DAZINT") {
    $('#adding-to-cart-animation').fadeIn('fast')
    this.addLicenseType(sku)
    this.license(productId)
}

$(function () {

    // View Product
    $(document).on('click', '.cartable-item a', (e) => {
        const $el = $(e.target);
        const label = $el.closest('[data-eventlabel]')?.data('eventlabel');
        daz.help.event('Link to Product', {
            transport: 'beacon',
            eventAction: 'Link to Product',
            eventCategory: 'Shop Actions',
            eventLabel: (label ? label : 'Generic slab link')
        })
        const id = $el.closest('[data-productid]')?.data('productid');
        if (!id) {
            return;
        }
        const attr = $el.closest('[data-attributiontoken]')?.data('attributiontoken');
        if (!attr) {
            return
        }
        daz.api.sessionStoragePut(`attr_${id}`, attr);
    });
    // Add to Cart
    $(document).on('click', '.cartable-item .slab-to-cart .btn-cart', (e) => {
        const $el = $(e.target);
        const id = $el.closest('[data-productid]')?.data('productid');
        if (!id) {
            return;
        }
        const source = $el.data('source');
        let attr = $el.closest('[data-attributiontoken]')?.data('attributiontoken');
        if (!attr) {
            attr = daz.api.sessionStorageGet(`attr_${id}`);
        }
        if (attr) {
            daz.api.sessionStoragePut(`attr_${id}`, attr);
        }
        daz.cart.eventLoggingAdd(id, source);
    });
    // Go to cart
    $(document).on('click', '.cartable-item .slab-to-cart .btn-in-cart', (e) => {
        daz.helper.setLocation('/checkout/cart')
    });
    // Go to library
    $(document).on('click', '.cartable-item .slab-to-cart .btn-purchased', (e) => {
        const id = $(e.target).closest('[data-productid]')?.data('productid');
        if (!id) {
            return;
        }
        daz.helper.setLocation(`/downloader/customer/files#prod_${id}`)
    });
})
