/******* JQuery Count Down Timer *******/

// Call Example
// $('#countdown').countdown( options );

(function ($) {
    'use strict';
    $.fn.countdown = function (options) {
        return $.fn.countdown.begin(this, $.extend({
            year: 2023,                 // INT Target Year
            month: 12,                  // INT Target Month
            day: 25,                    // INT Target Year
            hour: 0,                    // INT Target Hour
            minute: 0,                  // INT Target Minute
            second: 0,                  // INT Target Second
            timezone: -7,               // INT UTC Offset for Timezone
            labels: true,               // BOOL Add Labers (Days, Hours, etc) to the output
            useSpritesheet: false,      // BOOL Use a spritesheet for the numbers
            useSingularize: true,      // BOOL Singularize the labels
            spriteWidth: null,          // INT Sprite Dimensions
            spriteHeight: null,         // INT Sprite Dimensions
            spritesheet: null,          // Spritesheet URL
            collapse: false,            // Remove Days when 0, hours when 0, minutes when 0
            multiCount: null,           // ARRAY of OBJECTS Counter has multiple targets eg:
            // [
            //     { year: 2023, month: 1, day: 27, hour: 3, minute: 00, second: 00 },
            //     { year: 2023, month: 1, day: 27, hour: 6, minute: 00, second: 00 },
            // }
            onFinish: function () { 
                // Callback function
            }
        }, options));
    };

    $.fn.countdown.begin = function (parent, settings) {
        let timespan, start, end;
        start = $.fn.countdown.convertTimezone(settings.timezone);

        if (settings.multiCount) {
            settings.multiCount.every(countTarget => {

                end = new Date(countTarget.year, countTarget.month - 1, countTarget.day, countTarget.hour, countTarget.minute, countTarget.second);

                if (start.getTime() < end.getTime()) {
                    // Count to this one
                    return false
                }
                return true;
            });
        } else {
            end = new Date(settings.year, settings.month - 1, settings.day, settings.hour, settings.minute, settings.second);
        }
        timespan = $.fn.countdown.getTimeRemaining(start, end, settings);

        if (!settings.init) {
            $.each(timespan, function (k, v) {
                let container, wrapper, time, label, twoDigit;
                twoDigit = v < 10 ? '0' + v : v.toLocaleString();
                container = $('<div/>').attr('id', k);
                wrapper = $('<div/>').addClass('group-wrapper h-100');
                time = $('<div/>').addClass('time h-100').text(twoDigit);
                if (settings.useSpritesheet) {
                    time.innerHTML = $.fn.countdown.getSprites(twoDigit, settings, parent);
                }
                if (settings.labels) {
                    label = $('<span/>').addClass('label').text((v === 1 ? $.fn.countdown.singularize(settings, k) : k));
                    container.append(wrapper.append(time).append(label));
                } else {
                    container.append(wrapper.append(time));
                }
                parent.append(container.addClass('digit-group'));
            });
            settings.init = true;
        } else {
            $.each(timespan, function (k, v) {
                let twoDigit = v < 10 ? '0' + v : v.toLocaleString();

                if (settings.useSpritesheet) {
                    twoDigit = $.fn.countdown.getSprites(twoDigit, settings, parent);
                }

                $('.time', '#' + k).html(twoDigit);
                $('.label', '#' + k).html((v === 1 ? $.fn.countdown.singularize(k) : k));
            });
        }

        if (settings.target_reached) {
            settings.onFinish();
        } else {
            setTimeout(function () {
                $.fn.countdown.begin(parent, settings);
            }, 1000);
        }
    };

    $.fn.countdown.getSprites = function (twoDigit, settings, parent) {
        const digits = twoDigit.toString().split('');
        const realDigits = digits.map(Number);

        const groupWidth = parent.width() / document.querySelectorAll('.digit-group').length
        let ratio = settings.spriteWidth / settings.spriteHeight;
        let h = $('#days').height();
        let w = $('#days').height() * ratio;

        $('.time').width(w * 2.5 + 'px')

        let html = '';

        realDigits.forEach(num => {
            html += `<img data-num="${num}" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="${num}"  width="${w}" height="${h}" style="background:url(${settings.spritesheet}); background-size:auto ${h}px;  background-position:-${w * num}px;">`
        });

        html += `<img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt=":"  width="${w / 2}" height="${h}" style="background:url(${settings.spritesheet}); background-size:auto ${h}px;  background-position:-${(w * 10) + (w / 4)}px;">`

        return html;
    }

    $.fn.countdown.singularize = function (settings, str) {
        if (settings.useSingularize) {
            return str.substr(0, str.length - 1);
        }
        return str;
    };

    $.fn.countdown.convertTimezone = function (timezone) {
        let now, local_time, local_offset, utc;
        now = new Date();
        local_time = now.getTime();
        local_offset = now.getTimezoneOffset() * 60000;
        utc = local_time + local_offset;
        return new Date(utc + (3600000 * timezone));
    };

    $.fn.countdown.getTimeRemaining = function (start, end, settings) {
        let timeleft, remaining;
        remaining = {};
        timeleft = (end.getTime() - start.getTime());
        timeleft = (timeleft < 0 ? 0 : timeleft);

        if (timeleft === 0) {
            settings.target_reached = true;
        }

        remaining.days = Math.floor(timeleft / (24 * 60 * 60 * 1000));
        remaining.hours = Math.floor((timeleft / (24 * 60 * 60 * 1000) - remaining.days) * 24);
        remaining.minutes = Math.floor(((timeleft / (24 * 60 * 60 * 1000) - remaining.days) * 24 - remaining.hours) * 60);
        remaining.seconds = Math.floor(timeleft / 1000 % 60);
        if (settings.collapse && remaining.days === 0) {
            delete remaining['days'];
            if (remaining.hours === 0) {
                delete(remaining['hours']);
                if (remaining.minutes === 0) {
                    delete(remaining['minutes']);
                }
            }
        }
        return remaining;
    };
}(jQuery));
