function DazVendor() {
    daz.filter.onLoad(this, this.updateVendorCounts);
}


/**
 * Get the size of the given object
 * much like Array.length
 *
 * @param object
 * @return int object size
 */
DazVendor.prototype.objSize = function (obj) {
    "use strict";

    var size = 0,
        key;

    for (key in obj) {
        if (obj.hasOwnProperty(key)) {
            size += 1;
        }
    }

    return size;
};


/**
 * Update the vendor counts on the vendor index page
 * removing vendor entries as needed
 *
 * @param void
 * @return void
 */
DazVendor.prototype.updateVendorCounts = function () {
    "use strict";

    var i, $li, vendorName,
        $ = jQuery;

    var vendors = [];
    for (vendorName in daz.filter.filters.vendor) {
        vendors.push(vendorName)
    }

    vendors.sort(function (a, b) {
        var numA = !isNaN(+a.charAt(0));
        var numB = !isNaN(+b.charAt(0));

        if (numA && numB) return +a.charAt(0) - +b.charAt(0);
        if (numA) return 1;
        if (numB) return -1;

        var wordA = /^[a-zA-Z]/.test(a);
        var wordB = /^[a-zA-Z]/.test(b);
        if (wordA && wordB) {
            if (a.toLowerCase() > b.toLowerCase()) return 1;
            if (b.toLowerCase() > a.toLowerCase()) return -1;
            return 0;
        }

        if (wordA) return -1;
        if (wordB) return 1;
        return 1;
    })

    var headers = {};
    vendors.forEach(function (vendor) {
        if (/[^a-zA-Z]/.test(vendor.charAt(0))) {
            headers["#"] = "hash"
        } else {
            headers[vendor.charAt(0).toUpperCase()] = vendor.charAt(0).toLowerCase();
        }
    })

    var html = "";
    for (var header in headers) {
        html += '<a href="#vendor_' + headers[header] + '">' + header + '</a>'
    }
    $('.quicklinks').append(html);

    var startChar;

    var html = "";
    vendors.forEach(function (vendorName, i) {
        var firstChar = vendorName.charAt(0).toLowerCase();
        var nextChar;
        if (/[^a-zA-Z]/.test(firstChar)) {
            firstChar = "#"
        }
        if (i < vendors.length - 1) {
            nextChar = vendors[i + 1].charAt(0).toLowerCase()
            if (/[^a-zA-Z]/.test(nextChar)) nextChar = "#";
        }
        if (startChar != firstChar) {
            startChar = firstChar;
            var id = startChar.toLowerCase() === "#" ? "hash" : startChar.toLowerCase();
            html += '<li class="vendor_group">';
            html += "<h3 id=\"vendor_" + id + "\">" + startChar.toUpperCase() + "</h3>"
            html += "<ul class=\"vendor_list\">"
        }
        var url = vendorName.replace(/[^a-zA-Z\d -]/g, '').replace(/ /g, '-').toLowerCase();
        daz.filter.unpackFilters('vendor', vendorName);
        html += "<li class=\"truncate-text\" data-vendor-name=\"" + vendorName + "\"><a href=\"/" + url + "\">" + vendorName + "&nbsp;<span class=\"cat_count\">(" + this.objSize(daz.filter.filters.vendor[vendorName]) + ")</span></a></li>"

        if (i == vendors.length - 1 || nextChar !== startChar) { // done with all/letter

            html += "</ul>"
        }
    }.bind(this))

    $("#vendors").append(html)

    if ($('#vendorFilter').length) {
        var $li, $list = $('ul.vendor_list').find('li[data-vendor-name]'),
            len = $list.length,
            input = $('#vendorFilter'),
            groups = $('li.vendor_group');

        input.bind("propertychange keyup input paste", function (event) {
            if (input.data('oldVal') != input.val()) {
                input.data('oldVal', input.val());
                if ($(this).val() == '') {
                    $list.show();
                    groups.show();
                } else {
                    for (var i = 0; i < len; i++) {
                        $li = $($list[i]);
                        if (~$li.children('a').text().toLowerCase().indexOf(input.val().toLowerCase())) {
                            $li.show();
                        } else {
                            $li.hide();
                        }
                    }
                    for (var i = 0; i < groups.length; i++) {
                        if ($(groups[i]).children('ul.vendor_list').children(':visible').length == 0) {
                            $(groups[i]).hide();
                        } else {
                            $(groups[i]).show();
                        }
                    }
                }
            }
        });
        $('#vendorHeader button').click(function () {
            input.val('').trigger('propertychange');
        });

        input.attr('disabled', false).focus();
        $("vendorReset").attr('disabled', false);
        $('#vendorLoading').hide();
    }
};

