DazAddress = function ($container) {
    this.Container = $container;
    this.Fields = {};
    this.ChangeTimer = null;
    this.ChangeFields = {};
    this.Addresses = {};
    this.init();
};

const DazAddress__validFields = ['id', 'firstname', 'lastname', 'company', 'address1', 'address2', 'default', 'city', 'region', 'regionid', 'regiontext', 'country', 'phone', 'postalcode', 'taxid'];

if (typeof(DazAddress__countries) === "undefined") {
    let DazAddress__countries = {};
}

DazAddress.prototype.init = function() {
    let $fields = this.Container.find('[data-address]');
    let that = this;
    $fields.each(function(i, e) {
        let $e = $(e);
        let field = $e.data('address');
        if (!DazAddress__validFields.indexOf(field) === -1) {
            return;
        }

        // Set initial value for value change detection
        if (field === 'default') {
            $e.data('value', e.checked);
        } else {
            $e.data('value', $e.val());
        }

        // Detect value changes
        $e.on('change input blur', function(evt) {
            let old = $(this).data('value');
            let newV = null;
            if (field === 'save' || field === 'default') {
                newV = this.checked;
            } else {
                newV = $(this).val();
            }
            if (old === newV) { // Value wasn't changed don't fire event.
                return;
            }
            $(this).data('value', newV);
            if (that.ChangeTimer !== null) {
                clearTimeout(that.ChangeTimer);
                that.ChangeTimer = null;
            }
            that.ChangeFields[field] = old;
            that.ChangeTimer = setTimeout(function() {
                let cf = that.ChangeFields;
                that.ChangeFields = {};
                that.Container.trigger('addressChange', [cf, that.get()])
            }, 100)
        });
        that.Fields[field] = e;
    })
};

DazAddress.prototype.hasField = function(field) {
    return this.Fields.hasOwnProperty(field);
}

DazAddress.prototype.populate = function($container) {
    let $elements = $container.find('[data-address-text]');
    let addr = this.get();

    $elements.each(function(i, e) {
        let $e = $(e);
        let field = $e.data('address-text');
        if (!DazAddress__validFields.indexOf(field) === -1) {
            return;
        }
        if (field === 'name') {
            $e.text($.trim(addr['firstname'] + ' ' + addr['lastname']));
        } else {
            $e.text(addr[field]);
        }
        if (field === 'phone' || field === 'company') {
            $e.toggle(!!addr[field]);
            if (!!addr[field]) {
                $e.html($e.text() + "<Br>");
            }
        }
        if (field === 'taxid') {
            $('#address-tax-id').toggle(!!addr[field]);
        }
        if (field === 'country') {
            countryText = "";
            $e.text(DazAddress__countryText[addr[field]]);
        }
    })
}

DazAddress.prototype.validate = function(values) {
    let verrors = [];
    for (let i in this.Fields) {
        let $field = $(this.Fields[i]);
        if ((typeof $field.data('required') !== 'undefined') && !values[i]) {
            verrors.push(i);
        }
    }
    return verrors;
}

DazAddress.prototype.confirm = function() {
    let addr = this.get();
    addr.region = DazAddress__countries[addr.country] ? addr.regionid : addr.regiontext;
    let verrors = this.validate(addr);
    if (verrors.length > 0) {
        this.Container.trigger('addressError', [verrors, this])
        return;
    }
    delete(addr["regionid"]);
    delete(addr["regiontext"]);
    delete(addr["id"]);

    return addr;
}

DazAddress.prototype.watch = function() {
    if (this.hasField('regionid') && this.hasField('regiontext') && this.hasField('country')) {
        let regionTextBox = $(this.Fields['regiontext']);
        let regionDropDown = $(this.Fields['regionid']);
        let countryBox = $(this.Fields['country']);
        countryBox.on('change', function () {
            let country = countryBox.val();
            if (!country) {
                country = 'US';
            }
            if (DazAddress__countries[country]) {
                regionTextBox.hide();
                regionDropDown.show();
                let selectHtml = '';
                for (var i in DazAddress__countries[country]) {
                    selectHtml += '<option value="' + DazAddress__countries[country][i].code + '">' + DazAddress__countries[country][i].name + "</option>";
                }
                regionTextBox.val("")
                regionDropDown.html(selectHtml);
                regionDropDown.val(regionDropDown.data('default'));
            } else {
                regionTextBox.show();
                regionDropDown.hide();

            }
        });
        countryBox.trigger('change');
    }
};

DazAddress.prototype.get = function() {
    let address = {};
    for (let f in this.Fields) {
        if (f === 'save' || f === 'default') {
            address[f] = this.Fields[f].checked;
            continue;
        }
        address[f] = $(this.Fields[f]).val();
    }
    return address;
}

DazAddress.prototype.set = function(address) {
    for (i in address) {
        if (DazAddress__validFields.indexOf(i) === -1 || !this.hasField(i)) {
            continue;
        }
        if (i === 'save' || i === 'default') {
            this.Fields[i].checked = !!address[i];
            continue;
        }
        $(this.Fields[i]).val(address[i]);
        $(this.Fields[i]).trigger('change');
    }
}
