function DazGallery() {
    this.Likes = [];
}

DazGallery.prototype.run = function(Notifier, data, isCache) {
    this.Likes = data;
    this.addLikedClasses()
}


DazGallery.prototype.addLikedClasses = function() {
    if (this.Likes.length === 0) {
        return;
    }
    for (let i in this.Likes) {
        this.toggleClass(this.Likes[i], true);
    }
}

DazGallery.prototype.toggleClass = function(pageID, toggle) {
    let selector = "[page-id=" + pageID + "]";
    $(selector).toggleClass('liked', toggle);
}

DazGallery.prototype.addLike = function(pageID) {
    if (this.Likes.indexOf(pageID) === -1) {
        this.Likes.push(pageID);
        this.toggleClass(pageID, true);
    }
}

DazGallery.prototype.removeLike = function(pageID) {
    let idx = this.Likes.indexOf(pageID);
    if (idx !== -1) {
        delete this.Likes[idx];
        this.toggleClass(pageID, false);
    }
}
